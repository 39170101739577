// This file is purposely seperate because it imports both react and react-dom
// library. It is more like a helpers file but just do not want to force helpers
// file depend on react-dom.
import React, { PureComponent, useState } from "react";
import ReactDOM from "react-dom";
import update from 'immutability-helper';
import { push as _push } from 'connected-react-router';
import { I, L } from './config';
import {
	emptyObject
	, BTN_SAVE_AS_EML
	, C3ERR_SAME_ID
	, PLF_PREVIEW
	, SAVE_AS_EML_STR
	, SEARCH_ERRANDS
	, SEARCH_NEW_ERRANDS
	, SEARCH_MY_ERRANDS
	, SEARCH_CLOSE_ERRANDS
} from "./constants";
import { expireCookie, getCookie, hasPrefix, uuidv4 } from "../helpers";
import { V5 } from '../path';
import { PostForm } from "../../reactcomponents/Form";
import { popupWithMaxDim } from "../../reactcomponents/PopupPage";

export function openPreviewWindowWithPost(url, params) {
	return new Promise((resolve, reject) => {
		const res = window.open("", '_blank');
		if (!res) {
			reject({err: "open window return " + res});
		} else {
			const elem = document.createElement('div');
			res.document.body.appendChild(elem);
			res.addEventListener('beforeunload', e => {
				console.log("window open before unload");
				ReactDOM.unmountComponentAtNode(elem);
			});
			res.addEventListener('load', e => {
				console.log("window open load");
			});
			resolve(ReactDOM.render(
				<PostForm params={params} url={url} />
				, elem
			));
		}
	});
}

const tokenCookieDetectionInterval = 1000
	, tokenCookieDetectionAttempts = 120
	, noTokenCookie = "can't find token cookie after attempts "
		+ tokenCookieDetectionAttempts + " times with interval "
		+ tokenCookieDetectionInterval + " miliseconds per attempt."
	, invisible = {display: "none"}
	, maxSize = {width: "100%", height: "100%"}
	;
class PostLinkBase extends PureComponent {
	constructor(props) {
		super(props);
		this.setRef = this.setRef.bind(this);
	}
	tokenCookieDetection(tokenName, tokenValue) {
		// implementing logic of this suggestion: https://stackoverflow.com/a/4168965/1778714
		let _cancelled = false
			, cookieDetectTimer
			, rejecter
			, previousDoc = this.rootRef.contentDocument
			;
		return [
			new Promise((resolve, reject) => {
				let attempts = tokenCookieDetectionAttempts;
				rejecter = reject;
				cookieDetectTimer = window.setInterval(() => {
					const doc = this.rootRef.contentDocument
						, returnToken = getCookie(doc, tokenName)
						, foundValidToken = returnToken == tokenValue
						;
					if (foundValidToken || attempts == 0) {
						window.clearInterval(cookieDetectTimer);
						expireCookie(doc, tokenName);
						if (foundValidToken) {
							resolve();
						} else {
							reject({reason: noTokenCookie});
						}
						return;
					}
					attempts--;
				}, tokenCookieDetectionInterval);
			})
			, reason => {
				if (!_cancelled) {
					if (cookieDetectTimer) {
						window.clearInterval(cookieDetectTimer);
					}
					_cancelled = true;
					rejecter({isCancelled: true, reason});
				}
			}
		];
	}
	cancelPreviousToken(reason) {
		if (this.cancel) {
			this.cancel(reason);
			this.cancel = null;
		}
	}
	listenEvents(rootRef) {
		$(window).on(
			"message.iframe"
			, ({ originalEvent }) => {
				if (originalEvent && originalEvent.data) {
					const err = originalEvent.data.error;
					if (err) {
						this.cancelPreviousToken(err);
					}
				}
			}
		);
	}
	setRef(ref) {
		this.rootRef = ref;
		this.listenEvents(ref);
	}
	createNativeMountNode() {
		const elem = document.createElement('div');
		this.elem = elem;
		this.rootRef.contentDocument.body.appendChild(elem);
	}
	cleanIframeBody() {
		const body = this.rootRef.contentDocument.body;
		while (body.lastChild) {
			body.removeChild(body.lastChild);
		}
	}
	clearPreviousMountNode() {
		if (!this.elem) {
			return;
		}
		this.cancelPreviousToken("next request start before previous action stop");
		ReactDOM.unmountComponentAtNode(this.elem);
		this.cleanIframeBody();
		this.elem = null;
	}
	iframePostForm() {
		this.clearPreviousMountNode();
		this.createNativeMountNode();
		const { token, src } = this.props;
		if (!src) {
			return;
		}
		const { elem } = this
			, { format, data } = src
			, { url } = data
			;
		let params = data.params;
		this.props.onBeforeStart(format);
		if (token) {
			const { field, name } = token
				, value = uuidv4()
				, [ promise, cancel ] = this.tokenCookieDetection(name, value)
				;
			params = update(params, {[field]: {$set: value}});
			this.cancel = cancel;
			promise.then(() => this.props.onEnd())
				.catch(err => this.props.onError(err));
		}
		ReactDOM.render(
			<PostForm
				loading={format === PLF_PREVIEW}
				params={params}
				url={url}
			/>
			, elem
		);
	}
	componentDidMount() {
		this.iframePostForm();
	}
	componentDidUpdate(prevProps) {
		if (prevProps.src !== this.props.src) {
			this.iframePostForm();
		}
	}
	componentWillUnmount() {
		$(window).off("message.iframe");
		this.clearPreviousMountNode();
	}
	render() {
		const { src } = this.props;
		let style;
		if (src && src.format === PLF_PREVIEW) {
			style = maxSize;
		} else {
			style = invisible;
		}
		return <iframe ref={this.setRef} style={style} frameBorder="0" />;
	}
}

const downloadToken = {
	field: "download_token"
	, name: "downloadToken"
};

const PostDownload = props => <PostLinkBase token={downloadToken} {...props} />;

const previewButtons = [
	{
		id: BTN_SAVE_AS_EML
		, text: SAVE_AS_EML_STR
	}
];

const Popup = popupWithMaxDim(100);

// TODO: refactor to use createWithPopupWrapper
export const PreviewWithPost = ({ onClick, onClose, show, ...props }) => (
	<Popup
		// TODO: allow save as eml inside preview frame but this requires
		// seperate iframe container.
		// buttons={previewButtons}
		onClick={onClick}
		onClose={onClose}
		show={show}
	>
		<PostLinkBase {...props} />
	</Popup>
);

export const PostIframeLink = props => {
	if (props.src && props.src.format === PLF_PREVIEW) {
		return <PreviewWithPost {...props} />;
	}
	return <PostDownload {...props} />;
};

export const IsContextSearch = (context) =>{
	switch (context) {
		case SEARCH_ERRANDS:
		case SEARCH_NEW_ERRANDS:
		case SEARCH_MY_ERRANDS:
		case SEARCH_CLOSE_ERRANDS:
			return true;
	}
	return false;
}

// like react-router-redux push but more suitable for c3 environment and return
// promise to know if it is V5 page or not.
export const push = (url, ...args) => dispatch => {
	let pathname;
	const urlType = typeof url;
	if (urlType === "string") {
		pathname = url;
	} else if (urlType === "object") {
		pathname = url.pathname;
	}
	if (!pathname || !hasPrefix(pathname, V5)) {
		window.location.href = url;
		return Promise.reject(url);
	}
	dispatch(_push(url, ...args));
	return Promise.resolve(url);
};

export const pushV5 = (context, params) => push(V5, {v5: {context, params}});

// TODO: check where this error come from. It is used by open errand which look
// rather weird and messy.
const customErrorCheck = err => typeof err.err !== "undefined"
	&& typeof err.err.message !== "undefined"
	&& err.err.message.length > 0;

function isSameIDError(err) {
	if (err === C3ERR_SAME_ID) {
		return true;
	} else if (err.sameID) {
		return true;
	}
	return false;
}

function isAbortAJAX(err) {
	if (err.status === 0 && err.statusText === "abort") {
		return true;
	}
	return false;
}

function jsonErrorToString(err, stringify) {
	const messageTypeOf = typeof err.message;
	if (messageTypeOf === "string") {
		return L(err.message);
	} else if (typeof err.error !== "undefined") {
		return L(err.error);
	} else if (messageTypeOf !== "undefined") {
		return L(err.message);
	} else if (stringify) {
		return stringify;
	}
	return err + "";
}

const undefinedError = I("Error is undefined");

export const ERRTYPE_UNDEFINED = 0
	, ERRTYPE_STRING = 1
	, ERRTYPE_FALSY = 2
	, ERRTYPE_OPEN_ERRAND = 3
	, ERRTYPE_RESPONSEJSON = 4
	, ERRTYPE_SAME_ID = 5
	, ERRTYPE_ABORTED_AJAX = 6
	, ERRTYPE_THE_REST = 7 // this MUST be always last item
	;
const arrayErrorHandlers = [
		{ // ERRTYPE_UNDEFINED
			def: false
			, check: err => typeof err === "undefined"
			, msg: () => "undefined error input argument"
		}
		, { // ERRTYPE_FALSY
			def: false
			, check: err => !err
			, msg: err => `error '${err}' is falsy value`
		}
		, { // ERRTYPE_STRING
			def: true
			, check: err => typeof err === "string"
			, msg: err => err
		}
		, { // ERRTYPE_OPEN_ERRAND
			def: true
			, check: err => customErrorCheck(err)
			, msg: err => err.err.message
		}
		, { // ERRTYPE_RESPONSEJSON
			def: true
			, check: err => typeof err.responseJSON !== "undefined"
			, msg: err => jsonErrorToString(err.responseJSON, err.responseText)
		}
		, { // ERRTYPE_SAME_ID
			def: true
			, check: err => isSameIDError(err)
			, msg: ({ sameID }) => `Aborted operation on same ID ${sameID}.`
		}
		, { // ERRTYPE_ABORTED_AJAX
			def: false
			, check: err => isAbortAJAX(err)
			, msg: () => "AJAX request is aborted"
		}
		, { // ERRTYPE_THE_REST
			def: true
			, check: () => true
			, msg: err => jsonErrorToString(err, undefinedError)
		}
	]
	;
function isErrorCheck(modifier, index, def) {
	if (modifier && typeof modifier[index] !== "undefined") {
		return !!modifier[index];
	}
	return def;
}

export function analyzeError(err, custom) {
	const lastIndex = arrayErrorHandlers.length - 1;
	for (let i=0; i<lastIndex; i++) {
		const { def, check, msg } = arrayErrorHandlers[i];
		if (check(err)) {
			return [isErrorCheck(custom, i, def), msg(err)];
		}
	}
	const { def, check, msg } = arrayErrorHandlers[lastIndex];
	return [isErrorCheck(custom, lastIndex, def), msg(err)];
}

export function getMinutesBetweenDates(startDate, endDate) {
    var diff = endDate.getTime() - startDate.getTime();
    return (diff / 60000);
}

export function ClipboardCopy({ copyText }) {
	const [isCopied, setIsCopied] = useState(false);
	async function copyTextToClipboard(text) {
		if ('clipboard' in navigator) {
			return await navigator.clipboard.writeText(text);
		} else {
			return document.execCommand('copy', true, text);
		}
	}
	const handleCopyClick = () => {
		copyTextToClipboard(copyText)
		.then(() => {
			setIsCopied(true);
			setTimeout(() => {
				setIsCopied(false);
			}, 1500);
		})
		.catch((err) => {
			console.log(err);
		});
	}

	return (<div>
			<i className="icon-copy" title={I('Copy answer')} onClick={handleCopyClick}></i>
			<span>{isCopied ? I('Copied!') : ''}</span>
	</div>);
}

export function copyTextToClipboard(text, isFormatted = false) {
	const tempElement = document.createElement('div');
	if (isFormatted) {
		tempElement.innerHTML = text;
	} else {
		tempElement.textContent = text;
	}
	document.body.appendChild(tempElement);

	const range = document.createRange();
	range.selectNodeContents(tempElement);
	const selection = window.getSelection();
	selection.removeAllRanges();
	selection.addRange(range);

	const copySuccess = () => {
		console.log('Text copied to clipboard');
		document.body.removeChild(tempElement);
	};

	const copyFail = (err) => {
		console.error('Error copying text:', err);
		document.body.removeChild(tempElement);
	};

	if (navigator.clipboard && navigator.clipboard.writeText) {
		navigator.clipboard.writeText(selection.toString())
			.then(copySuccess)
			.catch(copyFail);
	} else {
		try {
			document.execCommand('copy');
			copySuccess();
		} catch (err) {
			copyFail(err);
		}
	}
}


// Generate Integrity Hash for script using SHA-384
export async function generateIntegrityHash(url, algorithm = 'SHA-384') {
	const response = await fetch(url);

	if (!response.ok) {
		throw new Error(`Failed to load ${url} (HTTP status: ${response.status})`);
	}

	const blob = await response.blob();
	const data = await new Response(blob).arrayBuffer();
	const hashBuffer = await crypto.subtle.digest({ name: algorithm }, data);

	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const base64Hash = btoa(String.fromCharCode.apply(null, hashArray));
	return "sha384-"+ base64Hash;
}

// Create Integrity Attribute
export async function createIntegrityAttr(src) {
	try {
		const hash = await generateIntegrityHash(src);
		return hash;
	} catch (error) {
		console.error(error);
		return ''; // Return an empty string or handle the error as needed
	}
}

// Element detection if element is at bottom of parent
export const isElementAtBottom = (elem, pa) => {
	if(pa) {
		const element = elem.current;
		const parent = pa.current;
		// Check if the element and its parent exist
		if (element && parent) {
			const elementRect = element.getBoundingClientRect();
			const parentRect = parent.getBoundingClientRect();
			const distanceToBottom = parentRect.bottom - elementRect.bottom;
			// Adjust the threshold as needed
			const threshold = 10; // Adjust this value based on your requirement
			return distanceToBottom <= threshold;
		} else {
			return false;
		}
	}
	return false;
};

export const isEmptyString = str => /^\s*$/.test(str);