import React from 'react';
import createReactClass from 'create-react-class';
import addonUpdate from 'react-addons-update';
//dev component
import ErrandHelper from './ErrandHelper';

var VPButton = createReactClass({
	displayName: "VPButton",
	render: function() {
		var style = {
			display: (ErrandHelper.isMobile() ? "none" : "block"),
			right: (this.props.isExpanded ? 451 : 62 ) + 'px',
			top: '15px'
		};
		if(this.props.isExpanded){
			style.position = 'fixed';
		}
		
		return (
			<div id={"VisitorPathButton"} className={"btn btn-dafault floating-sidebar-button active"} style={style} onClick={this.props.onClick}>
				<span className="fa fa-street-view" style={{color:'rgb(244, 226, 22)'}}></span>
			</div>
		)
	}
});

var VisitorPath = createReactClass({
	displayName: "VisitorPath",
	getInitialState: function() {
		return {
			isExpanded: false
		};
	},
	handleExpand: function(event) {
		event.preventDefault();
		this.expandOrCollapseSidebar(!this.state.isExpanded);
	},
	expandOrCollapseSidebar: function(newIsExpanded){
		this.setState({isExpanded: newIsExpanded});
		this.doExpandSideBar(newIsExpanded);
	},
	doExpandSideBar: function(show){
		var sidebarStyle = 'right:-451px';
		if (show) {
			sidebarStyle = 'right:-450px';
		}
		$('#VisitoPath.floating-sidebar-content').addClass(sidebarStyle).toggle('fast');
	},
	render: function(){
		var clientPaths = []
		, withClient = !!this.props.chatSession.client;
		if(withClient && this.props.chatSession.clientPaths && this.props.chatSession.clientPaths.paths && this.props.chatSession.clientPaths.paths.length>0){
			this.props.chatSession.clientPaths.paths.forEach(function(cp,i){
				var parser = document.createElement('a');
				parser.href = cp.path;
				if(i==0){
					clientPaths.push(
						<li key={'cp-host'}>
							<a style={{width: '100%',cursor: 'default'}}>
								<i className="fa fa-street-view"></i>
								{"  "+parser.hostname}
							</a>
						</li>
					);
				}
				clientPaths.push(
						<li
							key={'cp-'+cp.id}
							onMouseOver={function(){
								var img = '<div class="cp-preview-frame-wrap"><iframe class="cp-preview-frame" id="cp-preview-frame" src="'+cp.path+'"></iframe></div>';
							    $('#cp-preview-'+cp.id).popover({title: cp.path, content: img, html:true});
							    $('#cp-preview-'+cp.id).popover('show');
							}}
							onMouseLeave={function(){
							    $('#cp-preview-'+cp.id).popover('hide');
							}}>
							<a
								id={'cp-preview-'+cp.id}
								href={cp.path}
								target="_blank"
								style={{marginLeft:'15px',width: '80%',whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',paddingBottom: '1px',paddingTop: '1px'}}
								data-toggle="popover"
								data-container="body"
								data-placement="left">
								<i className="badge" style={{left: '-10px',position: 'relative'}}>{cp.lapsed}</i>
								{"  "+parser.pathname}
							</a>
						</li>
				);
			});
			var timePieces = function(value,unit,unitLabel,cols){
				if(value && value > 0){
					var offset = (12-(cols*4))/2;
					var offsetClass = "";
					if(unit==cols && offset > 0){
						offsetClass = " col-xs-offset-"+offset;
					}
					return (
						<span className={"col-xs-4 "+offsetClass} style={{paddingLeft: '0',paddingRight: '0'}}>
							<span style={{fontSize: '17px'}}>{value}</span>
							<span style={{display: 'block'}}>{unitLabel}</span>
						</span>
					);
				}
			};
			var timeOnSite = this.props.chatSession.clientPaths.totalTime;
			var cols = 1;
			if(timeOnSite.hour && timeOnSite.hour>0){
				cols++;
			}
			if(timeOnSite.min && timeOnSite.min>0){
				cols++;
			}
			var sidebarStyle = {
				minHeight: '300px',
				top: '10px'
			};
			return (
				<div style={{display:'inline-block'}}>
					<div id="VisitoPath" style={sidebarStyle} className="floating-sidebar-content">
						<div className="panel panel-primary">
							<div className="panel-heading">{I("Visitor path")}</div>
							<div className="list-group-item list-group-item-info" style={{textAlign: 'center', paddingTop:'0px', paddingBottom:'0px'}}>
								<div>
									<div className="col-xs-12">
										{timePieces(timeOnSite.hour,3,I("hour"),cols)}
										{timePieces(timeOnSite.min,2,I("min"),cols)}
										{timePieces(timeOnSite.sec,1,I("sec"),cols)}
									</div>
									<br/>
									<span style={{textTransform: 'uppercase',fontSize: '10px'}}>{I("Total time on site")}</span>
								</div>
							</div>
							<ul className="pager" style={{marginBottom: '5px',textAlign: 'start'}}>
								{clientPaths}
							</ul>
						</div>
					</div>
					<VPButton
						viewportHeight={window.innerHeight - 170}
						isExpanded={this.state.isExpanded}
						onClick={this.handleExpand}
						context="chat"
					/>
				</div>
			);
		} else {
			return <div></div>			
		}
	}
});
export default VisitorPath;