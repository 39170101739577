import React from 'react';
import createReactClass from 'create-react-class';
//dev
import DropDownItem from './DropDownItem';

var TagItem = createReactClass({
	getDefaultProps: function() {
		return {
			inputClass: '',
			disabled: false,
			id: ''
		}
	},
	handleRemove: function(e){
		this.props.onRemove(this.props.item[this.props.fields.id]);
	},
	render: function(){
		if(this.props.disabled){
			return (<span id={this.props.id+"_"+this.props.item[this.props.fields.id]} className={'tag label label-info '+this.props.inputClass}>{this.props.item[this.props.fields.name]}</span>);
		}else{
			return (<span id={this.props.id+"_"+this.props.item[this.props.fields.id]} className={(this.props.item.custom?'tag label label-warning ':'tag label label-info ')+this.props.inputClass}>{this.props.item[this.props.fields.name]}<span data-role="remove" onClick={this.handleRemove}></span></span>);
		}
	}
});
var TaggedPicker = createReactClass({
	displayName:"TaggedPicker",
	getDefaultProps: function() {
		return {
			tags: [{id:"0",name:"Tag-0"},{id:"1",name:"Tag-1"},{id:"2",name:"Tag-2"}],
			fields: {id:"id",name:"name"},
			inputTagClass: '',
			idPrefix: '',
			disabled: false,
			allowAddCustomItem: false,
			inputOnly: false,
			optional: false,
			allowPasteInput: false,
			validateCustomTagBeforeAdding: function(){return true;}/* by default no validation would be done, parent should specify if need validation */
		}
	},
	getInitialState: function(){
		return {
			selectedItems:[],
			filter:"",
			selectedTag:[]
		};
	},
	componentWillReceiveProps: function(nextProps) {
		if(nextProps.selectedItems != this.state.selectedItems){
			this.setState({selectedItems:nextProps.selectedItems});
		}
	},
	componentWillMount: function(){
		if(this.props.selectedItems && this.props.selectedItems.length > 0){
			if(this.props.maxItem && this.props.selectedItems.length > this.props.maxItem){
				this.props.selectedItems.map(function(item,i){
					if(i < this.props.maxItem) {
						this.state.selectedItems.push(item);
					}
				},this);
				this.setState(this.state);
			} else {
				this.setState({selectedItems: this.state.selectedItems.concat(this.props.selectedItems)});
			}
		}
	},
	addToSelectedTags: function(newTag){
		if(this.props.maxItem && this.state.selectedItems.length >= this.props.maxItem) return;
		this.state.selectedItems.push(this.getTagById(newTag));
		this.state.filter="";
		this.setState(this.state);
		if(this.props.onChange){
			this.props.onChange(this.state.selectedItems);
		}
		document.getElementById("taggedPicker_edit_"+this.props.id).value = "";
	},
	getItemsLen: function(){
		var itemsLen = this.props.tags ? this.props.tags.length : 0;
		if(this.props.nestedGroup && this.props.nestedGroup!=""){
			itemsLen = 0;
			this.props.tags.map(function(item,i){
				if(item[this.props.nestedGroup]){
					itemsLen += item[this.props.nestedGroup].length;
				}
			},this);
		}
		return itemsLen;
	},
	getTagById: function(id){
		var itemsLen = this.getItemsLen();
		if(itemsLen > 0) {
			if(this.props.nestedGroup && this.props.nestedGroup!="" && this.props.nestedGroup != null){
				this.props.tags.map(function(item,i){
					item[this.props.nestedGroup].map(function(nestedItem,i){
						if(nestedItem[this.props.fields.id].toString() == id ){
							this.state.selectedTag = nestedItem;
							this.setState(this.state);
						}
					},this);
				},this);
				return this.state.selectedTag;
			} else {
				for(var i=0; i<this.props.tags.length; i++) {
					if(id == this.props.tags[i][this.props.fields.id]){
						return this.props.tags[i];
					}
				}
			}
		}
	},
	findSelectedIndex: function(id){
		var found = -1;
		this.state.selectedItems.map(function(item, i){
			if(item[this.props.fields.id]==id){
				found = i;
			}
		},this);
		return found;
	},
	isSelected: function(id){
		return this.findSelectedIndex(id) < 0 ? false : true;
	},
	stripTrailingComma: function (str) {
		if(str.substr(-1) === ',') {
			return str.substr(0, str.length - 1);
		}
		return str;
	},
	tagIt: function(tag){
		if(tag == "") {
			if(this.props.optional && this.props.validateCustomTagBeforeAdding) {
				this.props.validateCustomTagBeforeAdding(tag, this.props.id, this.props.optional, this.props.allowPasteInput);
				return;
			}else {
				return;
			}
		}
		if(this.props.maxItem && this.state.selectedItems.length >= this.props.maxItem) return;
		tag = this.stripTrailingComma(tag);
		if(this.props.validateCustomTagBeforeAdding(tag, this.props.id, this.props.optional, this.props.allowPasteInput)){
			if(this.props.allowPasteInput) {
				var splittedTagsArr = tag.split(',');
				if(splittedTagsArr.length > 0) {
					for(var i=0;i<splittedTagsArr.length;i++) {
						this.state.selectedItems.push(
							{id: splittedTagsArr[i], value: splittedTagsArr[i], custom: true}
						);
					}
				}
			}else {
				var newCustomTag = {};
				newCustomTag[this.props.fields.id]=tag;
				newCustomTag[this.props.fields.name]=tag;
				newCustomTag['custom'] = true;
				this.state.selectedItems.push(newCustomTag);
			}
			this.setState(this.state);
			if(this.props.onChange){
				this.props.onChange(this.state.selectedItems);
			}
			this.refs["taggedPicker_edit_"+this.props.id].value="";
			$("#taggedPicker_edit_"+this.props.id).val("");
		}else{
			document.getElementById("taggedPicker_edit_"+this.props.id).style.color = "rgb(255, 0, 77)";
		}
	},
	handleTagging:function(e){
		if((e.keyCode == 13 || e.keyCode == 188) && this.props.allowAddCustomItem){
			this.tagIt(e.target.value);
		}
		else {
			document.getElementById("taggedPicker_edit_"+this.props.id).style.color = "rgb(0, 0, 0)";
		}
	},
	handleChangeFilter: function(e){
		if(e.target.value == ","){
			this.refs["taggedPicker_edit_"+this.props.id].value="";
		}
		this.state.filter=e.target.value;
		this.setState(this.state);
	},
	onToggle: function(e){
		setTimeout(function() {
			var id = $('#dropdownSelect'+this.props.id);
			if(id.hasClass('open')) {
				id.removeClass('open');
			}else{
				id.addClass('open');
			}
		}.bind(this));
	},
	handleLoseFocus: function(e) {
		this.tagIt(e.target.value);
		if(this.props.onBlur){
			this.props.onBlur(this.state.selectedItems);
		}
	},
	handleRemove: function(id){
		this.state.selectedItems.splice(this.findSelectedIndex(id),1);
		this.setState(this.state);
		if(this.props.onChange){
			this.props.onChange(this.state.selectedItems);
		}
	},
	handleSelectTag: function(e){
		this.addToSelectedTags(e);
		this.onToggle();
	},
	handleAutoComplete: function(e){
	},
	handleSelectGroup: function(itemid,isSelected){
		if(this.props.tags.length > 0) {
			var tmp=this.state.selectedTags && this.state.selectedTags !="" ? this.state.selectedTags.split(",") : [];
			if(this.props.nestedGroup && this.props.nestedGroup!=""){
				this.props.tags.map(function(item,i){
					if(item[this.props.fields.id]==itemid){
						if(item[this.props.nestedGroup]){
							item[this.props.nestedGroup].map(function(nestedItem,i){
								if(!this.isSelected(nestedItem[this.props.fields.id])){
									tmp.push(nestedItem[this.props.fields.id]);
									this.addToSelectedTags(nestedItem[this.props.fields.id]);
								}
							},this);
						}
					}
				},this);
				this.setState({selectedTags:tmp.join(",")});
				if(this.props.onChange){
					this.props.onChange(tmp.join(","));
				}
			}
		}
	},
	renderSelectedItems: function(){
		if(this.props.tagClass){
			var tagClass = this.props.tagClass;
		}else{
			var tagClass = '';
		}
		if(this.state.selectedItems.length > 0){
			return this.state.selectedItems.map(function(item,i){
				return (<TagItem key={i} id={this.props.id} item={item} fields={this.props.fields} onRemove={this.handleRemove} inputClass={this.props.inputTagClass} disabled={this.props.disabled} />);
			},this);
		}
	},
	renderNestedGroups: function() {
		var list = [];
		this.props.tags.map(function(item,i){
			list.push(<li role="separator" key={i + "separator"} className="divider"></li>);
			list.push(<DropDownItem key={item.id} item={item} nestedClass="nestedTitle" activeWhen={this.props.activeWhen} fields={this.props.fields} onClick={this.handleSelectGroup} />);
			list.push(<li role="separator" key={i+"divider"} className="divider"></li>);
			if(item[this.props.nestedGroup]){
				item[this.props.nestedGroup].map(function(nestedItem,i){
					if(!this.isSelected(nestedItem[this.props.fields.id])){
						if(nestedItem[this.props.fields.name].toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1 ){
							list.push(<DropDownItem key={nestedItem.id} item={nestedItem} activeWhen={this.props.activeWhen} fields={this.props.fields} onClick={this.handleSelectTag} multiSelect={this.props.multiSelect} singleSelect={this.props.singleSelect} idPrefix={this.props.idPrefix} />);
						}
					}
				},this);
			} else {
				list.push(<li className="dropdown-header" >{I("No Items")}</li>);
			}
		},this);
		return list;
	},
	renderTagItems: function(){
		if(!this.props.maxItem || (this.props.maxItem && this.state.selectedItems.length < this.props.maxItem)){
			if(this.props.nestedGroup == "tags"){
				if(this.props.tags && this.props.tags.length){
					if(this.props.nestedGroup && this.props.nestedGroup!=""){
						return this.renderNestedGroups();
					}
				}
			}
			else {
				if(this.props.tags.length > 0){
					var list = [];
					this.props.tags.map(function(item,i){
						if(!this.isSelected(item[this.props.fields.id])){
							if(item[this.props.fields.name].toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1 ){
								list.push(<DropDownItem key={i} item={item} fields={this.props.fields} onClick={this.handleSelectTag}/>);
							}
						}
					},this);
					return list;
				}
			}
		}
	},
	renderEditor: function(placeholder,inputWidth){
		if(!this.props.maxItem || (this.props.maxItem && this.state.selectedItems.length < this.props.maxItem)){
			return (
				<input
					id={"taggedPicker_edit_"+this.props.id}
					type="text"
					ref={"taggedPicker_edit_"+this.props.id}
					placeholder={placeholder}
					onChange={this.handleChangeFilter}
					onKeyUp={this.handleTagging}
					onBlur={this.handleLoseFocus}
					style={{width:inputWidth}}
					onClick={this.onToggle}/>
				);
		}
	},
	render: function(){
		var style={width:'3em !important'};
		var styleDropDownMenu = {
			height: 'auto',
			maxHeight: '400px',
			overflowX: 'hidden'
		};
		var className = 'caret';
		var tagClass = '';
		var mainClass = 'bootstrap-tagsinput';
		var placeholder = '';
		var inputWidth = 'auto';
		var dropdown = true;

		if(this.props.optionClass){
			className = this.props.optionClass;
		}else{
			className = "";
		}
		if(this.props.tagClass){
			tagClass = this.props.tagClass;
			mainClass = 'bootstrap-tagsinput borderless'
		}
		if(this.props.disabled){
			var tagStyle = {
				backgroundColor: '#DDDDDD'
			}
			dropdown = false;
		}
		if(this.props.placeholder){
			placeholder = this.props.placeholder;
		}
		if(this.props.inputWidth){
			inputWidth = this.props.inputWidth;
		}
		var dd = "";
		if(!this.props.inputOnly) {
			var ddc = this.renderTagItems();
			if(ddc && ddc.length > 0) {
				dd = (
					<ul className={"dropdown-menu "+tagClass} aria-labelledby="dropdownMenu1" style={styleDropDownMenu}>
						{ddc}
					</ul>
				);
			}
		}
		return (
			<div className={"dropdown"} id={"dropdownSelect"+this.props.id}>
				<div className={mainClass} style={tagStyle}>
					{this.renderSelectedItems()}
					{this.renderEditor(placeholder,inputWidth)}
				</div>
				{dd}
			</div>
		);
	}
});
export default TaggedPicker;
