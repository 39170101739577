import React from 'react';
import createReactClass from 'create-react-class';
//dev component
import ErrandHelper from './ErrandHelper';
import ErrandThreadHistory from './ErrandThreadHistory';
var OtherContactsAcquireErrandsListItem = createReactClass({
	displayName: "OtherContactsAcquireErrandsListItem",
	render: function() {
		var message = I('Checking to see if this errand has been taken...');
		var bgColor = '#FF9';
		if( !this.props.errand.working ) {
			if (this.props.errand.success) {
				message = I('You have taken ownership of this errand.');
				bgColor = '#9F9';
			} else {
				message = I('It has not been possible to acquire the errand.');
				bgColor = '#F99';
			}
		}
		var style = {
			backgroundColor: bgColor,
			marginBottom: 8,
			padding: 4
		};
		return (<li style={style}><strong>#{this.props.errand.displayId}:</strong> {message}</li>)
	}
});

var OtherContactsAcquireErrandsList = createReactClass({
	displayName: "OtherContactsAcquireErrandsList",
	render: function() {
		var items = this.props.errands.map(function(errand, index) {
			var key = 'AcquireErrand-' + errand.id + '-' + index;
			return (
				<OtherContactsAcquireErrandsListItem key={key} errand={errand} />
			);
		}.bind(this));

		return (<div className="otherContactsAcquireErrandsList">
			<ol>
				{items}
			</ol>
		</div>)
	}
});

var OtherContactsListItem = createReactClass({
	displayName: "OtherContactsListItem",
	render: function() {
		var tdStyle = {
			backgroundColor: this.props.background
		};

		var id = 'other-contacts-agent-' + this.props.errand.id;

		var iconStyle = { verticalAlign: 'bottom' };
		var iconTitle = "";
		var iconColor = "#669966";
		if(typeof this.props.errand.style !== 'undefined' && Object.keys(this.props.errand.style).length > 0){
				if( this.props.errand.style.themeWarning != 'undefined' &&  this.props.errand.style.themeWarning == "System Warning Alert Theme"){
					iconColor= "#F2AA52";
					iconTitle = I("Errand has warning");
				}else if( this.props.errand.style.themeWarning != 'undefined' &&  this.props.errand.style.themeWarning == "System Warning Theme"){
					iconColor = '#EA4866';
					iconTitle = I("Errand has expired");
				}
		}
		var handleOtherContactsHistoryThreadModal = function(){
			var url = webserverRoot + 'errand/history';
			$.post(url, {errand: this.props.errand.id})
			.done(function(data){
				ErrandHelper.generateHistoryThreadPopup({content: data});
			});
		}.bind(this);

		window.getIframeHeight = function() {
			return this.innerHeight;
		}
		var handleClick = function() {
			var gourl = urlForApplicationAction('errands/popups/history/-/thread/' + this.props.errand.threadId + '/errand/' + this.props.errand.id + (ActiveUserID && ActiveUserAuthToken ? '/auth/' + ActiveUserID + '/' + ActiveUserAuthToken: ''));
			var jqiFrame = $('<iframe onload="getIframeHeight()" name="jqiframe" border="0" scrolling="yes" id="jqiframe" src="'+ gourl +'"></iframe>');
			$( "#dialogErrandHistoryPopup" ).html("");
			$( "#dialogErrandHistoryPopup" ).attr('title',I('Print Preview'));
			$( "#dialogErrandHistoryPopup" ).append(jqiFrame).dialog({
				width: 750,
				height: getIframeHeight(),
				dialogClass: "jqiFrame",
				modal: true
			});
		}.bind(this);

		var checkbox = '';
		if (this.props.errand.showCheckbox) {
			var checkboxId = 'other-contacts-checkbox-' + this.props.errand.id;
			checkbox = <input type="checkbox" id={checkboxId} data-errand={this.props.errand.id} />
		}

		var escapeDQuote = function (txt) {
			if (typeof(txt) == "string") {
				return txt.replace(/"/g, "\\x22");
			}
			return txt;
		};

		var getSubject = function() {
			// Subject is escaped in the Go backend
			return { __html: replacePM(this.props.errand.data.subject.substr(0, 20)) + '...' };
		}.bind(this);
		var trBackground = {};
		trBackground.backgroundColor = '#FFF';
		var classificationTypes = <td style={tdStyle}></td>;

		if(this.props.errand.data.closed){
			trBackground.backgroundColor = '#EFEFEF';
			classificationTypes = <td style={{color: 'green'}}>✓</td>;
		}
		if(this.props.errand.data.deleted){
			trBackground.backgroundColor = 'grey';
			classificationTypes = <td style={{color: 'red'}}>✕</td>;
		}
		return (<tr style={trBackground} id={"Errand_" + this.props.errand.data.displayId}>
			<td style={tdStyle} onClick={handleOtherContactsHistoryThreadModal}><span className="divErrandStatusBar" title={iconTitle} style={{backgroundColor: iconColor}}></span></td>
			<td style={tdStyle}>{checkbox}</td>
			{classificationTypes}
			<td style={tdStyle} onClick={handleOtherContactsHistoryThreadModal} title={escapeDQuote(this.props.errand.data.date)}>{this.props.errand.data.date}</td>
			<td style={tdStyle} onClick={handleOtherContactsHistoryThreadModal} title={escapeDQuote(this.props.errand.data.serviceName)}>{this.props.errand.data.serviceName}</td>
			<td style={tdStyle} onClick={handleOtherContactsHistoryThreadModal} title={escapeDQuote(this.props.errand.data.displayId)}>{this.props.errand.data.displayId}</td>
			<td style={tdStyle} onClick={handleOtherContactsHistoryThreadModal} id={id} title={escapeDQuote(this.props.errand.data.agent)}>{this.props.errand.data.agent}</td>
			<td style={tdStyle} onClick={handleOtherContactsHistoryThreadModal} dangerouslySetInnerHTML={getSubject()}></td>
		</tr>)
	}
});

var OtherContactsList = createReactClass({
	displayName: "OtherContactsList",
	render: function() {
		var index = 0;
		var items = this.props.errands.map(function(errand, index) {
			var key = 'OtherContacts-' + errand.id + '-' + index;
			index++;
			return (
				<OtherContactsListItem key={key} errand={errand} />
			)
		});

		var commonThStyle = {};
		var styleEmptyTh   = { width:  '10px' };
		var styleThDate    = { width:  '90px' };
		var styleThChannel = { width:  '60px' };
		/* 5em can fit 7-digit errand id which should be good enough */
		var styleThHash    = { width:  '5em' };
		var styleThAgent   = { width:  '50px' };
		var subjectStyle   = { width: '150px' };

		var more = '';
		if (this.props.errands.length > 0 && this.props.errands.length % 25 == 0) {
			more = <div className="moreStyle" onClick={this.props.onMoreClick}>More &#x25BF;</div>
		}
		var height = window.innerHeight/2 - 140;
		return (<div className="otherContactsList" style={{ height: height, overflow: 'auto'}}>
			<table className="tableOtherContactsList">
				<thead>
					<tr>
						<th style={styleEmptyTh}></th>
						<th style={styleEmptyTh}></th>
						<th style={styleEmptyTh}></th>
						<th style={styleThDate}>{I('Arrived')}</th>
						<th style={styleThChannel}>{I('Channel')}</th>
						<th style={styleThHash}>#</th>
						<th style={styleThAgent}>{I('Agent')}</th>
						<th style={subjectStyle}>{I('Subject')}</th>
					</tr>
				</thead>
				<tbody>{items}</tbody>
			</table>
			{more}
		</div>)
	}
});

var OtherContactsHeader = createReactClass({
	displayName: "OtherContactsHeader",
	render: function() {
		var displayFrom = this.props.from;
		if(displayFrom.length>30){
			displayFrom = displayFrom.substring(0, 27)+"...";
		}
		return (<div className="otherContactsHeader">
			<span className="otherContactsHeaderTitle">{displayFrom}</span>
			<span>{I('Unanswered')}:</span>
			<span><input type="checkbox" onClick={this.props.onUnansweredChange} defaultChecked /></span>
			<span>{I('Answered')}:</span>
			<span><input type="checkbox" onClick={this.props.onAnsweredChange} defaultChecked /></span>
		</div>)
	}
});

var OtherContacts = createReactClass({
	displayName: "OtherContacts",
	getDefaultProps: function(){
		return {context:"errand"};
	},
	getInitialState: function() {
		var uniqueId = 'OtherContacts-' + (new Date().getTime());
		return {
			uniqueId: uniqueId,
			list: [],
			acquire: {
				errands: [],
				display: 'none'
			},
			//FIXME Mujibur: Default for the prev system is false, I put true to make it work for now : Sue
			fetched: true,
			busy: false,
			activeErrandID: this.props.id || 0,
		};
	},
	componentWillReceiveProps: function(nextProps){
		if( nextProps.id != this.state.activeErrandID ){
			this.setState({activeErrandID: nextProps.id}, function(){
				this.changeAnswer = false;
				this.loadList(true, true, false, 0, false);
			}.bind(this));
		}
		if( nextProps.changeKey != this.props.changeKey){
			this.changeAnswer = true;
			this.reloadList(true, true);
		}
	},
	loadList: function(unanswered, answered, acquirable, offset, append) {
		var url = '/Cention/web/errand/contacts/' + this.state.activeErrandID + '?nocache=' + Math.random();
		var parameters = {
			answered: answered,
			unanswered: unanswered,
			acquirable: acquirable,
			offset: offset,
			limit: 25
		};
		this.setState({busy:true});
		$.getJSON(url, parameters).done(function(data) {
			if (data.list) {
				var state = (append ? this.state : { list: [] });
				for (var i = 0; i < data.list.length; i++ ) {
					var errand = data.list[i];

					if (errand.data.closed == false &&
						errand.id != this.state.activeErrandID &&
						errand.acquired == false &&
						(errand.data.agentId <= 0 || errand.data.agentId == ActiveUserID))
					{
						errand.showCheckbox = true;
					}
					//filtering out errand of the same Id from being
					//displayed
					if(errand.id != this.state.activeErrandID){
						state.list.push(errand);
					}
				}
				if(this.props.onLoad && !this.changeAnswer ){
					if( typeof data.parentId != 'undefined' && data.parentId == this.state.activeErrandID ){
						this.props.onLoad(state.list);
					}
				}
				if( typeof data.parentId != 'undefined' && data.parentId == this.state.activeErrandID ){
					state.fetched = true;
					state.busy = false;
					this.setState(state);
				}
			}
		}.bind(this));
	},
	reloadList: function(unanswered, answered) {
		this.currentOffset = 0;
		this.changeAnswer = true;
		this.loadList(unanswered, answered, false, 0, false);
	},
	onPreShow: function() {
		this.showUnanswered = true;
		this.showAnswered = true;
		this.currentOffset = 0;
		this.replaceState(this.getInitialState());
	},
	onShow: function() {
		this.changeAnswer = true;
		this.reloadList(true, true);
	},
	selectedErrands: function() {
		var errands = [];
		var content = document.getElementById("OtherContactsBar");
		if(content){
			var checkboxes = content.getElementsByTagName('input');
			if(checkboxes){
				for (var i = 0; i < checkboxes.length; i++) {
					if( checkboxes[i].checked ) {
						var errand = checkboxes[i].getAttribute('data-errand');
						if (errand) {
							errands.push(errand);
						}
					}
				}
			}
		}

		return errands;
	},
	updateList: function(ownership) {
		if (ownership && !ownership.error) {
			var checkbox = document.getElementById('other-contacts-checkbox-' + ownership.errand);
			var agent = document.getElementById('other-contacts-agent-' + ownership.errand);
			if (checkbox) {
				checkbox.style.display = 'none';
				checkbox.checked = false;
				document.getElementById("Errand_" + ownership.displayId).style.display = 'none';
			}
			if (agent) {
				agent.innerHTML = ownership.owner.name;
			}
		}
	},
	fetchCurrentContext: function(){
		return localStorage.getItem("currentErrandContext");
	},
	acquireErrand: function(errand, onSuccess, onFail) {
		var parameters = {};
		parameters.errand = errand;
		parameters.errand_was_opened_by_external_system = false;
		parameters.fetch_data_only_if_acquired = true;
		parameters.do_not_fetch_data = true;
		parameters.source = this.fetchCurrentContext();
		var url = webserverRoot + 'errand/acquire';
		$.post( url, parameters, function( result ){
			var ownership = null;
			result.mcam.channels.forEach(function( d ){
				if(typeof d.type != undefined && d.type == "Result" && d.id == "acquireErrand"){
					ownership = JSON.parse(d.content);
					try {
						ownership = JSON.parse(value);
					} catch (e) { }
					if( ownership && !ownership.error && ownership.acquired ) {
						onSuccess(ownership);
					} else {
						onFail(ownership);
					}
				}
			}.bind(this));
		});
	},
	acquireErrands: function(errands) {
		var current = 0;
		var working = false;
		var onSuccess = function(ownership) {
			var state = this.state;
			state.acquire.errands[current].working = false;
			state.acquire.errands[current].success = true;
			state.acquire.errands[current].displayId = ownership.displayId;
			state.fetched = true;
			this.setState(state);
			this.updateList(ownership);
			working = false;
			current++;
			if(this.props.handleAcquire){
				this.props.handleAcquire(ownership);
			}
		}.bind(this);

		var onFail = function(ownership) {
			var state = this.state;
			state.acquire.errands[current].working = false;
			state.acquire.errands[current].success = false;
			state.acquire.errands[current].displayId = ownership.displayId;
			state.fetched = true;
			this.setState(state);
			this.updateList(ownership);
			working = false;
			current++;
			if(this.props.handleAcquire){
				this.props.handleAcquire(ownership);
			}
		}.bind(this);

		var acquireErrand = function() {
			if (working) {
				setTimeout(acquireErrand, 100);
			} else {
				var finished = (current >= errands.length ? true : false);
				if (finished) {
					var hideAcquireErrands = function() {
						var state = this.state;
						state.acquire.errands = [];
						state.acquire.display = 'none';
						state.fetched = true;
						this.setState(state);
					}.bind(this);
					setTimeout(hideAcquireErrands, 2000);
				} else {
					working = true;
					this.acquireErrand(errands[current], onSuccess, onFail);
					setTimeout(acquireErrand, 100);
				}
			}
		}.bind(this);

		acquireErrand();
	},
	handleAcquireSelected: function() {
		if(this.state.busy){
			return;
		}
		var errands = this.selectedErrands();
		if (errands.length > 0) {
			var state = this.state;
			state.acquire.display = 'block';
			for (var i = 0; i < errands.length; i++) {
				var errand = {};
				errand.id = errands[i];
				errand.working = true;
				errand.success = false;
				state.acquire.errands.push(errand);
			}
			state.fetched = true;
			this.setState(state);
			this.acquireErrands(errands);
		}
	},
	handleAcquireAll: function() {
		if(this.state.busy){
			return;
		}
		var parameters = {
			answered: false,
			answered: false,
			unanswered: true,
			acquirable: true,
			offset: 0,
			limit: 9999
		};
		var url = '/Cention/web/errand/contacts/' + this.state.activeErrandID + '?nocache=' + Math.random();
		$.getJSON(url, parameters).done(function(data) {
			if (data.list && data.list.length > 0) {
				var errands = [];
				var state = this.state;
				state.acquire.display = 'block';

				for (var i = 0; i < data.list.length; i++ ) {
					if (data.list[i].id != this.state.activeErrandID && !data.list[i].acquired) {
						var errand = {}
						errand.id = data.list[i].id;
						errand.displayId = data.list[i].displayId;
						errand.working = true;
						errand.success = false;
						state.acquire.errands.push(errand);
						errands.push(errand.id)
					}
				}
				state.fetched = true;
				this.setState(state);
				this.acquireErrands(errands)
			}
		}.bind(this));
	},
	showUnanswered: true,
	showAnswered: true,
	currentOffset: 0,
	changeAnswer: false,
	handleUnansweredChange: function(event) {
		if (this.showAnswered) {
			this.showUnanswered = event.currentTarget.checked;
			this.changeAnswer = true;
			this.reloadList(this.showUnanswered, this.showAnswered);
		} else {
			event.currentTarget.checked = true;
		}
	},
	handleAnsweredChange: function(event) {
		if (this.showUnanswered) {
			this.showAnswered = event.currentTarget.checked;
			this.changeAnswer = true;
			this.reloadList(this.showUnanswered, this.showAnswered);
		} else {
			event.currentTarget.checked = true;
		}
	},
	handleMoreClick: function(event) {
		this.currentOffset += 25;
		this.loadList(this.showUnanswered, this.showAnswered, false, this.currentOffset, true);
	},
	componentDidMount: function() {
		// Check before registerAction because chat don't have these buttons:
		var BtnPrefix = this.props.btnPrefix;
		this.reloadList(true, true);
	},
	render: function() {
		if (this.state.fetched) {
			var style = {
				display: this.state.acquire.display,
				position: 'absolute',
				top: '-10px',
				left: '-8px',
				width : $(this).parent().width() + 16 + 'px',
				height : $(this).parent().height + 18 + 'px',
				backgroundColor: 'rgba(0, 0, 0, 0.5)'
			};
			var otherContactsHeight = {height: "100%"};
			if( this.props.groupErrand ){
				otherContactsHeight = {height: "70%"};
			}
			return (
					<div id="OtherContactsBar" style={otherContactsHeight}>
					{this.renderTopTitle()}
					<div key={this.state.uniqueId} className="innerOtherContactsBar">
						{this.state.busy?<div className="busy-container"><img src="img/loading-attachment.gif" /></div>:""}
						<OtherContactsHeader onUnansweredChange={this.handleUnansweredChange} onAnsweredChange={this.handleAnsweredChange} from={this.props.from}/>
						<OtherContactsList errands={this.state.list} onMoreClick={this.handleMoreClick} />
						<div style={style}>
							<OtherContactsAcquireErrandsList errands={this.state.acquire.errands} />
						</div>
					</div>
					{this.renderActionButtons()}
				</div>
				);
		}
		return (<div></div>);
	},
	renderTopTitle: function(){
		if(this.props.context == "errand"){
			return (<div className="sideTopTitle">{I("Other contacts")}</div>);
		}
	},
	renderActionButtons: function(){
		if(this.props.context == "errand" || this.props.context == "chat"){
			return(
				<div id="AcquireButtons">
					<button id="acquiredSelected" type="button"
						style={{marginRight: '5px', marginLeft: '5px'}}
						className={"btn btn-primary dropdown-toggle action-buttons"+(this.state.busy?" disabled":"")}
						data-toggle="dropdown"
						aria-haspopup="false"
						aria-expanded="false"
						onClick={this.handleAcquireSelected}>
							{I("Acquire selected errands")}
					</button>
					<button id="acquiredAll" type="button"
						className={"btn btn-primary dropdown-toggle action-buttons"+(this.state.busy?" disabled":"")}
						data-toggle="dropdown"
						aria-haspopup="false"
						aria-expanded="false"
						onClick={this.handleAcquireAll}>
							{I("Acquire All Errands")}
					</button>
				</div>
			);
		}
	}
});
export default OtherContacts;
