import React from 'react';
import createReactClass from 'create-react-class';
import createFragment from 'react-addons-create-fragment';
//dev component
import { reEmail } from '../common/helpers';
import DropDown from './DropDown';
import TaggedPicker from './TaggedPicker';
import ErrorBox from './ErrorBox';
import ErrandHelper from '../components/ErrandHelper';
export default createReactClass({
	displayName: "AnswerReplyOptions",
	getInitialState: function(){
		return{
			visibleChangeRecepient: true,
			controlClass: "fa-minus-square-o",
			visibleFacebookLike: true,
			visibleFacebookUnlike: true,
			visibleFacebookHide: true,
			visibleFacebookUnhide: true,
			visibleFacebookDelete: true,
			twitterRetweet: true,
			disabled: false,
			selectedData: [],
			forwardToExternalActivated: false,
			forwardToExternalModeOn: false,
			channelChange: false,
			service: "",
			serviceType: "",
			instagramLike: true,
			forwardToExternalFeature: false,
			turnLightOffWhenReadFeature: false
		}
	},
	isValidEmail: function(emailAddress){
		return reEmail.test(emailAddress);
	},
	isSecureMessageValidUserId: function(emailAddress){
		if(emailAddress.length == 8 && /^[0-9]*$/.test(emailAddress)){
			return true;
		}
		return false;
	},
	checkData: function(tagName, jsonObj){
		var i = null;
		for (var i = 0; jsonObj.length > i; i += 1) {
			if (jsonObj[i].value === tagName) {
				return true;
			}
		}
		return false;
	},
	componentWillMount: function(){
		this.state.forwardToExternalFeature = F("forward-external.any");
		this.state.turnLightOffWhenReadFeature = F("external-experts.turn-light-off-when-read");
	},
	componentWillUpdate: function(nextProps,nextState){},
	getCurrentRecepientBoxState: function(){
		return this.state.visibleChangeRecepient;
	},
	setForwardToExternalMode: function(mode){
		this.state.forwardToExternalModeOn = mode;
	},
	reloadExternalExpertContent: function(){
		$("#externalExpertModal").remove();
		$('.modal-backdrop').remove();
		$('body').removeClass('modal-open');
		//FIXMe : Reload modal content with latest info
	},
	checkFeatureTurnOffWhenRead: function(){
		if(this.state.turnLightOffWhenReadFeature){
			var status = false;
			this.props.onChangeEELight(status);
			this.props.onHandleTurnLightOffWhenRead();
		}
	},
	turnLightOnOffEE: function(status){
		this.props.onChangeEELight(status);
	},
	handleChangeRecepientBox: function(param){
		var styles = "fa-plus-square-o";
		if(!this.state.visibleChangeRecepient){
			styles = "fa-minus-square-o";
			if(param == "activateForwardToExternal") {
				if(this.state.forwardToExternalModeOn){
					this.state.forwardToExternalActivated = true;
				}else{
					this.state.forwardToExternalActivated = false;
				}
			}
		}else{
			if(this.state.forwardToExternalModeOn){
				this.state.forwardToExternalActivated = true;
			}else{
				this.state.forwardToExternalActivated = false;
			}
		}
		this.setState({
			visibleChangeRecepient: !this.state.visibleChangeRecepient,
			controlClass: styles,
			forwardToExternalActivated: this.state.forwardToExternalActivated,
			settingCCValue: this.state.settingCCValue ,
			settingBCCValue: this.state.settingBCCValue ,
			settingForwardToExternalValue: this.state.settingForwardToExternalValue,
			settingReplyToValue: this.state.settingReplyToValue
		});
	},
	handleStatus: function(status, selectedData){
		if(status == "disabled"){
			this.state.disabled = true;
			this.setState({
				selectedData : selectedData.email,
				visibleChangeRecepient : false,
				channelChange: true
			});
			this.handleChangeRecepientBox();
		}
	},
	render: function(){
		var style = {
			display: (!this.props.currentErrand.data.secureUserId ? 'block' : 'none')
		};
		var controlClass;
		if(!this.props.recipientPanelVisibility) {
			controlClass = "fa-plus-square-o";
		} else {
			controlClass = "fa-minus-square-o";
		}
		if(this.props.errandContextType == "Facebook") {
			this.serviceType = 'manual';
		} else if(this.props.errandContextType == "VKontakte") {
			this.serviceType = 'manual';
		} else if(this.props.errandContextType == "Manual") {
			this.serviceType = 'manual';
		}else if(this.props.errandContextType == "Twitter") {
			this.serviceType = 'manual';
		}
		return(
		<div className="renderErrandAdvanceReplyOption" style={style}>
			<div className="reply-question changeRecipient" onClick={this.props.onChangeRecepientBox}>
				<i className={"fa " + controlClass}> </i>
				{this.props.title}
			</div>
			{(this.props.recipientPanelVisibility ?
				<div>
					{this.RecepientsBox()}
				</div>
				: null
			)}
		</div>
		);
	},
	handleServiceChange: function(service, type){
		this.setState({service:service, serviceType: type});
	},
	RecepientsBox: function(){
		var currentErrand = this.props.currentErrand.data;
		var currentAreaData = this.props.currentAreaData;
		var newService = this.props.errandService;
		if(this.props.errandContextType == "Facebook") {
			newService = Workflow.Errand.SERVICE_MANUAL_FACEBOOK;
		} else if(this.props.errandContextType == "VKontakte") {
			newService = Workflow.Errand.SERVICE_MANUAL_VKONTAKTE;
		} else if(this.props.errandContextType == "Twitter") {
			newService = Workflow.Errand.SERVICE_MANUAL_TWITTER;
		}
		switch(newService){
			case Workflow.Errand.SERVICE_EMAIL, Workflow.Errand.SERVICE_MANUAL:
			return(
				<div className="recipientEmailBox" key={"recipientEmailBox"}>
					<div className="reply-row" key={"recipientEmailBoxReplyEmailRow1"}>
						<div>{createFragment({ a: <div />, b: this.emailRecepients1(currentErrand, currentAreaData) })}</div>
					</div>
					<div className="clear"></div>
					<div className="reply-row" key={"recipientEmailBoxReplyEmailRow2"}>
						<div>{createFragment({ a: <div />, b: this.emailRecepients2(currentErrand, currentAreaData) })}</div>
					</div>
					<div className="clear"></div>
				</div>
			);
			case Workflow.Errand.SERVICE_FACEBOOK: case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
				return(
					<div className="facebookBoxContainer" key="facebookBoxContainer">
						<div className="recipientFacebookBox reply-row" key={"recipientEmailBoxReplyFBRow"}>{this.facebookRecepients(currentErrand, currentAreaData, this.serviceType)}</div>
						{this.renderForwardToExternalField(currentErrand, currentAreaData)}
					</div>
					);
			case Workflow.Errand.SERVICE_TWITTER:
			case Workflow.Errand.SERVICE_MANUAL_TWITTER:
			return (
				<div className="twitterBoxContainer" key="twitterBoxContainer">
					<div className="recipientTwitterBox reply-row" key={"recipientEmailBoxReplyTwitterRow1"}>{this.twitterRecepients(currentErrand, currentAreaData, this.serviceType)}</div>
					{this.renderForwardToExternalField(currentErrand, currentAreaData)}
				</div>
			);
			case Workflow.Errand.SERVICE_LINKEDIN:
				return(
					<div className="linkedinBoxContainer" key="linkedinBoxContainer">
						<div className="recipientLinkedInBox reply-row" key={"recipientEmailBoxReplyLinkedinRow"}>{this.linkedinRecepients(currentErrand)}</div>
						{this.renderForwardToExternalField(currentErrand, currentAreaData)}
					</div>
					);
			case Workflow.Errand.SERVICE_INSTAGRAM:
				return(
					<div className="instagramBoxContainer" key="instagramBoxContainer">
						<div className="recipientInstagramBox reply-row" key={"recipientEmailBoxReplyInstagramRow"}>{this.instagramRecepients(currentErrand)}</div>
						{this.renderForwardToExternalField(currentErrand, currentAreaData)}
					</div>
					);
			case Workflow.Errand.SERVICE_VOICE:
			case Workflow.Errand.SERVICE_SMS:
				return(
					<div className="smsBoxContainer" key="smsBoxContainer">
						<div className="recipientMobileBox reply-row" key={"recipientEmailBoxReplyMobileRow"}>{this.smsRecepients(currentErrand, currentAreaData)}</div>
						{this.renderForwardToExternalField(currentErrand, currentAreaData)}
					</div>
					);
			case Workflow.Errand.SERVICE_LINE:
			case Workflow.Errand.SERVICE_MANUAL_LINE:
				if(this.serviceType == "manual") {
					return (
						<div className="recipientEmailBox" key={"recipientEmailBoxReplyManualRow"}>
							<div>
								{this.manualLINERecepients(currentErrand, currentAreaData, this.serviceType)}
							</div>
							{this.renderForwardToExternalField(currentErrand, currentAreaData)}
						</div>
					);
				} else {
					return (
						<div className="recipientEmailBox" key={"recipientEmailBoxReplyFBRow"}>
							<div>{this.lineRecepients(currentErrand)}</div>
							{this.renderForwardToExternalField(currentErrand, currentAreaData)}
						</div>
					);
				}
			case Workflow.Errand.SERVICE_VKONTAKTE:
			case Workflow.Errand.SERVICE_MANUAL_VKONTAKTE:
				if(this.serviceType == "manual") {
					return (
						<div className="facebookBoxContainer" key="manualVKfacebookBoxContainer">
							<div className="recipientFacebookBox reply-row">
								{this.vkontakteManualRecepients(currentErrand, currentAreaData)}
							</div>
							{this.renderForwardToExternalField(currentErrand, currentAreaData)}
						</div>
					);
				} else {
					return (
						<div className="facebookBoxContainer" key="VKfacebookBoxContainer">
							<div className="recipientFacebookBox reply-row">
								{this.vkontakteRecepients(currentErrand)}
							</div>
							{this.renderForwardToExternalField(currentErrand, currentAreaData)}
						</div>
					);
				}
			default:
				return (
					<div className="recipientEmailBox" key={"recipientEmailBoxReplyDefaultRow"}>
						<div className="reply-row" key={"recipientEmailBoxReplyRecepients1"}>
							{createFragment({ a: <div />, b: this.emailRecepients1(currentErrand, currentAreaData) })}
						</div>
						<div className="clear"></div>
						<div className="reply-row" key={"recipientEmailBoxReplyRecepients2"}>
							{createFragment({ a: <div />, b: this.emailRecepients2(currentErrand, currentAreaData) })}
						</div>
						<div className="clear"></div>
					</div>
			);
		}
	},
	extractEmailAddresses: function( answerBody ){
		if(answerBody) {
			var r = answerBody.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
			if( r ) {
				return r;
			}
			return [];
		}
	},
	setupReplyAddresses: function( currentErrand, currentAreaData ) {
		var items = [];
		var seen = {};
		if( currentErrand ) {
			if(currentErrand.fromAddress && !seen[currentErrand.fromAddress]) {
				items.push({id: currentErrand.fromAddress, value: currentErrand.fromAddress});
				seen[currentErrand.fromAddress] = true;
			}
			if( currentErrand.toAddresses && currentErrand.toAddresses.length > 0 ) {
				currentErrand.toAddresses.forEach(function( email, i ) {
					items.push({id: email, value: email });
				});
			}
			if( currentErrand.copyAddresses && currentErrand.copyAddresses.length > 0 ) {
				currentErrand.copyAddresses.forEach(function( email, i ) {
					items.push({id: email, value: email });
				});
			}
			var emailAddressFromBody = this.extractEmailAddresses(currentErrand.body);
			if(emailAddressFromBody) {
				emailAddressFromBody.forEach(function( _email, i ) {
				var email = _email.toLowerCase();
					if( !seen[email] ) {
						items.push({id: email, value: email});
						seen[email] = true;
					}
				});
			}
		}

		if( currentAreaData ) {
			if( currentAreaData.addressbook_addresses && currentAreaData.addressbook_addresses.length > 0 ) {
				currentAreaData.addressbook_addresses.forEach(function( _email, i ) {
					if(typeof _email.name !== 'undefined'){
						items.push({id: _email.id, value: _email.name+
							" ("+_email.id+")"});
					} else {
						items.push({id: _email.id, value: _email.id});
					}
				});
			}
		}

		return items.sort(function( a, b ) {
			return a.value.toLowerCase().localeCompare(b.value.toLowerCase());
		});

	},
	formatCsv2Array: function(csv){
		var list = [];
		csv.split(",").forEach(function(v,i){
			if(v && v != ""){
				list.push({id:v, value: v});
			}
		});
		return list;
	},
	getUniqueAddress: function(addressList){
		var usedObjects = {};
		for (var i=addressList.length - 1;i>=0;i--) {
			var so = JSON.stringify(addressList[i]);
			if (usedObjects[so]) {
				addressList.splice(i, 1);
			} else {
				usedObjects[so] = true;
			}
		}
		return addressList;
	},
	emailRecepients1: function(currentErrand, currentAreaData){
		var itemsAddressBook = [];
		var recipients = [];
		var fromAddress = [];
		var setupReplyToAddress = [];
		if(this.state.selectedData != undefined && this.state.selectedData.length > 0){
			fromAddress = this.formatCsv2Array(currentErrand.fromAddress);
			if(this.state.channelChange){
				var email = this.state.selectedData.toString();
				fromAddress = this.formatCsv2Array(email);
			}
		}else{
			fromAddress = this.formatCsv2Array(currentErrand.fromAddress);
			if(fromAddress == ""){
				fromAddress = this.state.settingReplyToValue;
			}
		}
		var replyAddresses = this.setupReplyAddresses( currentErrand, currentAreaData );
		var uniqueReplyAddr = this.getUniqueAddress(replyAddresses);
		if(this.props.forwardExternalActived){
			var replyToStyle = {"display":"block"};
		}else{
			var replyToStyle = {"display":"none"};
		}
		var toTitle = I('Reply-To');
		if(this.serviceType == "manual") {
			toTitle = I('To');
		}

		var cssClassTitle = 'bold col-xs-2 col-md-2';
		var cssClassContent = 'col-xs-10 col-md-10';

		recipients.push(
			<div className="col-md-6" key={"emailRecepients_CC"}>
				<div className="reply-question" id="replyCCBox">
					<div className={cssClassTitle}>{I('CC')}</div>
					<div className={cssClassContent}>
						<TaggedPicker id="replyAddressCC"
							key="replyAddressCC"
							tags={uniqueReplyAddr} allowAddCustomItem={true}
							selectedItems={this.props.addresses.cc}
							fields={{id:"id",name:"value"}}
							validateCustomTagBeforeAdding={ this.props.verifyCustomTag }
							optional={true}
							allowPasteInput={true}
							onChange={this.props.onReplyAddressCC} />
						<ErrorBox id="replyCcErrorBox" message={this.props.errorMessageManualErrand.errorMessageCc} key="errorBox_EmailRecepients_CC" />
					</div>
					<div className="clear"></div>
				</div>
			</div>
		);
		
		if(!this.props.forwardExternalActived) {
			recipients.push(
				<div className="col-md-6" key={"emailRecepients_ReplyTo"}>
					<div className="reply-question" id="replyToBox">
						<div className={cssClassTitle}>{toTitle}</div>
						<div className={cssClassContent}>
							<TaggedPicker id="replyAddressComboboxReplyTo"
								key="replyAddressComboboxReplyTo"
								fields={{id:"id",name:"value"}}
								allowAddCustomItem={true}
								maxItem={1} tags={uniqueReplyAddr}
								selectedItems={this.props.addresses.to}
								validateCustomTagBeforeAdding={this.props.verifyCustomTag}
								onChange={this.props.onReplyAddress}
								disabled={this.props.lock} />
							<ErrorBox id="replyToErrorBox" message={this.props.errorMessageManualErrand.errorMessageTo} key="errorBox_EmailRecepients_ReplyTo" />
						</div>
						<div className="clear"></div>
					</div>
					<div className='overlayDiv' style={replyToStyle}></div>
				</div>
			);
		}else{
			recipients.push(
				<div className="col-md-6" key={"emailRecepients1FrwdExtMode"}>
					<div className="reply-question" id="replyToBox">
						<div className={cssClassTitle}>{I('Reply-To')}</div>
						<div className={cssClassContent}></div>
						<div className="clear"></div>
					</div>
					<div className='overlayDiv' style={replyToStyle}></div>
				</div>
			);
		}
		return recipients;
	},
	emailRecepients2: function(currentErrand, currentAreaData){
		var itemsAddressBook = [];
		var recipients = [];
		var replyAddresses = this.setupReplyAddresses( currentErrand, currentAreaData );
		var uniqueReplyAddr = this.getUniqueAddress(replyAddresses);
		var customAddress = false;
		//Fixme Mujibur: TODO Need to set seletced Items from fetchExtendedData...

		var cssClassTitle = 'bold col-xs-2 col-md-2';
		var cssClassContent = 'col-xs-10 col-md-10';

		var forwardToExternalVisibility = {display:"none"};
		if(this.props.forwardExternalActived ||
			currentErrand.wasForwardedToExternal) {
			forwardToExternalVisibility = {display:"block"};
		}

		recipients.push(
			<div className="col-md-6" key={"emailRecepients_BCC"}>
				<div className="reply-question" id="replyBCCBox">
					<div className={cssClassTitle}>{I('BCC')}</div>
					<div className={cssClassContent}>
						<TaggedPicker id="replyAddressBCC"
							key="replyAddressBCC"
							tags={uniqueReplyAddr}
							fields={{id:"id",name:"value"}}
							selectedItems={this.props.addresses.bcc}
							allowAddCustomItem={true}
							validateCustomTagBeforeAdding={this.props.verifyCustomTag}
							optional={true}
							allowPasteInput={true}
							onChange={this.props.onReplyAddressBCC} />
						<ErrorBox id="replyBccErrorBox" message={this.props.errorMessageManualErrand.errorMessageBcc} key="errorBox_EmailRecepients_BCC" />
					</div>
					<div className="clear"></div>
				</div>
			</div>
		)
		
		if(this.props.externalAddresses != null || uniqueReplyAddr != null ){
			var forwardToExternalAddress = [];
			if(this.state.forwardToExternalFeature){
				forwardToExternalAddress = uniqueReplyAddr;
				customAddress = true;
			}
			if(this.props.externalAddresses != null){
				this.props.externalAddresses.forEach(function( _email, i ) {
					forwardToExternalAddress.push({id: _email.id, value: _email.name+" ("+_email.id+")"});
				});
			}else{
				if( currentAreaData ) {
					if( currentAreaData.addressbook_addresses && currentAreaData.addressbook_addresses.length > 0 ) {
						currentAreaData.addressbook_addresses.forEach(function( _email, i ) {
							itemsAddressBook.push({id: _email.id, value: _email.name+" ("+_email.id+")"});
						});
					}
				}
				if(currentAreaData.external_addresses && currentAreaData.external_addresses.length > 0 ) {
					uniqueReplyAddr.concat( currentAreaData.external_addresses );
					forwardToExternalAddress.concat(uniqueReplyAddr);
				}else{
					(itemsAddressBook.length > 0 ? forwardToExternalAddress.concat(itemsAddressBook) : forwardToExternalAddress.concat(uniqueReplyAddr));
				}
			}
			if(customAddress){
				recipients.push(
					<div className="col-md-6" id="forwardToExternalPanel"
						style={forwardToExternalVisibility}
						key={"emailRecepientsFrwdToExternalAddressCustom"}>
						<div className="reply-question" id="forwardToExternalBox">
							<div className={cssClassTitle}>{I('Forward to external')}</div>
							<div className={cssClassContent}>
								<TaggedPicker id="replyAddressForwardExternal"
									key="replyAddressForwardExternal"
									fields={{id:"id",name:"value"}}
									tags={forwardToExternalAddress}
									selectedItems={this.props.addresses.fwd}
									allowAddCustomItem={true}
									allowPasteInput={true}
									validateCustomTagBeforeAdding={this.props.verifyCustomTag}
									onChange={this.props.onReplyAddressForwardExternal}
									inputWidth="200px" />
								<ErrorBox id="replyForwardExternalErrorBox" message={this.props.errorMessageManualErrand.errorMessageEe} key="errorBox_ForwardExternal" />
							</div>
							<div className="clear"></div>
						</div>
					</div>
				);
			}else{
				recipients.push(
					<div className="col-md-6" id="forwardToExternalPanel" style={forwardToExternalVisibility} key={"emailRecepientsFrwdToExternal"}>
						<div className="reply-question" id="forwardToExternalBox">
							<div className={cssClassTitle}>{I('Forward to external')}</div>
							<div className={cssClassContent}>
							<DropDown id="replyAddressForwardExternal" selectedItems="" items={forwardToExternalAddress} fields={{id: "id", name: "value"}}
							name={I("External Addresses")}
							textNoItem={I("No External Addresses")}
							textChooseItem={I("Choose External Addresses")}
							textNoItemSelected={I("No External Addresses selected")}
							textSelectedItem={I("Selected External Addresses")}
							multiSelect={true} wantSelectAll={false} wantSelectNone={true} align="right" onChange={this.props.onReplyAddressForwardExternal} class="forwardToExternalDropdown" /></div>
							<div className="clear"></div>
						</div>
					</div>
				);
			}
		}
		return recipients;
	},
	renderForwardToExternalField: function(currentErrand, currentAreaData){
		var itemsAddressBook = [];
		var recipients = [];
		var replyAddresses = this.setupReplyAddresses( currentErrand, currentAreaData );
		var uniqueReplyAddr = this.getUniqueAddress(replyAddresses);
		var customAddress = false;

		var forwardToExternalVisibility = {display:"none"};
		if(this.props.forwardExternalActived){
			forwardToExternalVisibility = {display:"block", borderBottom:  "3px solid #CECECE"};
		}

		if(this.props.externalAddresses != null || uniqueReplyAddr != null ){
			var forwardToExternalAddress = [];
			if(this.state.forwardToExternalFeature){
				forwardToExternalAddress = uniqueReplyAddr;
				customAddress = true;
			}
			if(this.props.externalAddresses != null){
				this.props.externalAddresses.forEach(function( _email, i ) {
					forwardToExternalAddress.push({id: _email.id, value: _email.name+" ("+_email.id+")"});
				});
			}else{
				if( currentAreaData ) {
					if( currentAreaData.addressbook_addresses && currentAreaData.addressbook_addresses.length > 0 ) {
						currentAreaData.addressbook_addresses.forEach(function( _email, i ) {
							itemsAddressBook.push({id: _email.id, value: _email.name+" ("+_email.id+")"});
						});
					}
				}
				if(currentAreaData.external_addresses && currentAreaData.external_addresses.length > 0 ) {
					uniqueReplyAddr.concat( currentAreaData.external_addresses );
					forwardToExternalAddress.concat(uniqueReplyAddr);
				}else{
					(itemsAddressBook.length > 0 ? forwardToExternalAddress.concat(itemsAddressBook) : forwardToExternalAddress.concat(uniqueReplyAddr));
				}
			}
		}

		if(customAddress){
			return (
				<div className="col-md-12" id="forwardToExternalPanel"
					key={"forwardToExternalPanelCustomAddr"}
					style={forwardToExternalVisibility}>
					<div className="reply-question" id="forwardToExternalBox">
						<div className="align-left bold">{I('Forward to external')}</div>
						<div className="align-right">
							<TaggedPicker id="replyAddressForwardExternal"
								key="replyAddressForwardExternal"
								fields={{id:"id",name:"value"}}
								tags={forwardToExternalAddress}
								selectedItems={this.props.addresses.fwd}
								allowAddCustomItem={true}
								allowPasteInput={true}
								validateCustomTagBeforeAdding={this.isValidEmail}
								onChange={this.props.onReplyAddressForwardExternal} />
							<div style={{color: 'red'}} className="help-block replyAddressForwardExternal with-errors-email"></div>
						</div>
						<div className="clear"></div>
					</div>
				</div>
			);
		}else{
			var selectedFwd = this.props.addresses.fwd.join(',');
			return (
				<div className="col-md-12" id="forwardToExternalPanel"
					key={"forwardToExternalPanelNonCustom"}
					style={forwardToExternalVisibility}>
					<div className="reply-question" id="forwardToExternalBox">
						<div className="align-left bold">{I('Forward to external')}</div>
						<div className="align-right">
							<DropDown id="replyAddressForwardExternal"
								selectedItems={selectedFwd}
								items={forwardToExternalAddress}
								fields={{id: "id", name: "value"}}
								name={I("External Addresses")}
								textNoItem={I("No External Addresses")}
								textChooseItem={I("Choose External Addresses")}
								textNoItemSelected={I("No External Addresses selected")}
								textSelectedItem={I("Selected External Addresses")}
								multiSelect={true} wantSelectAll={false}
								wantSelectNone={true}
								align="right"
								onChange={this.props.onReplyAddressForwardExternal}
								class="forwardToExternalDropdown" />
						</div>
						<div className="clear"></div>
					</div>
				</div>
			);
		}
	},
	handleLike: function(){
		this.setState({visibleFacebookLike: !this.state.visibleFacebookLike, visibleFacebookUnlike: !this.state.visibleFacebookUnlike})
		this.props.onFbLike(this);
		/*Its possible to pass AnswerReplyOptions(as `this`) to parent components*/
	},
	handleUnLike: function(){
		this.setState({visibleFacebookLike: !this.state.visibleFacebookLike, visibleFacebookUnlike: !this.state.visibleFacebookUnlike})
		this.props.onFbUnlike(this);
	},
	componentDidMount: function(){
		if(this.props.recipientPanelVisibility == "Visible"){
			this.setState({visibleChangeRecepient: true});
			this.setState({controlClass: "fa-minus-square-o"});
		}else{
			this.setState({visibleChangeRecepient: false});
			this.setState({controlClass: "fa-plus-square-o"});
		}

		var currentErrand = this.props.currentErrand.data;
		if(currentErrand.fbLikes == "" || currentErrand.fbLikes == "unliked"){
			this.setState({visibleFacebookLike: true})
			this.setState({visibleFacebookUnlike: false})
		}else{
			this.setState({visibleFacebookLike: false})
			this.setState({visibleFacebookUnlike: true})
		}
		if( currentErrand.fbAction == "" ){
			this.setState({visibleFacebookHide: true})
			this.setState({visibleFacebookDelete: true})
			this.setState({visibleFacebookUnhide: false})
		}else{
			if(currentErrand.fbAction != "deleted"){
				if(currentErrand.fbAction == "hidden"){
					this.setState({visibleFacebookHide: false})
					this.setState({visibleFacebookDelete: false})
					this.setState({visibleFacebookUnhide: true})
				}else{
					this.setState({visibleFacebookHide: true})
					this.setState({visibleFacebookDelete: false})
					this.setState({visibleFacebookUnhide: false})
				}
			}
			else{
				this.setState({visibleFacebookHide: false, visibleFacebookDelete: false, visibleFacebookUnhide: false, visibleFacebookLike: false, visibleFacebookUnlike: false});
			}
		}
		if( !currentErrand.retweet && !currentErrand.twitterPm ){
			this.setState({twitterRetweet: this.state.twitterRetweet})
		}
	},
	handleHide: function(){
		if( !this.props.wfSettings.mustConfirmDelete){
			if( window.confirm(I('Are you sure you want to hide this post from facebook?')) ){
				this.setState({visibleFacebookHide: !this.state.visibleFacebookHide, visibleFacebookDelete: !this.state.visibleFacebookDelete, visibleFacebookUnhide: !this.state.visibleFacebookUnhide})
			}
		}else{
			this.setState({visibleFacebookHide: !this.state.visibleFacebookHide, visibleFacebookDelete: !this.state.visibleFacebookDelete, visibleFacebookUnhide: !this.state.visibleFacebookUnhide})
		}
		this.props.onFbHide(this);
	},
	handleUnhide: function(){
		if( this.props.wfSettings.mustConfirmDelete){
			if( window.confirm(I('Are you sure you want to unhide this post from facebook?')) )
				this.setState({visibleFacebookHide: !this.state.visibleFacebookHide, visibleFacebookDelete: !this.state.visibleFacebookDelete, visibleFacebookUnhide: !this.state.visibleFacebookUnhide});
		}else
			this.setState({visibleFacebookHide: !this.state.visibleFacebookHide, visibleFacebookDelete: !this.state.visibleFacebookDelete, visibleFacebookUnhide: !this.state.visibleFacebookUnhide})
		this.props.onFbUnhide(this);
	},
	handleDeleteQ: function(serviceType) {
		if(!serviceType) {
			serviceType = "facebook";
		}
		if(this.props.wfSettings.mustConfirmDelete) {
			if(window.confirm(I('Are you sure you want to delete this post from {SERVICE}?').replace('{SERVICE}', serviceType))) {
				this.setState({visibleFacebookHide: false, visibleFacebookDelete: false, visibleFacebookUnhide: false, visibleFacebookLike: false, visibleFacebookUnlike: false});
				this.props.onFbDelete(this);
			} else {
				console.debug("negative in answer delete "+ serviceType + " errand.");
			}
		} else {
			this.setState({visibleFacebookHide: false, visibleFacebookDelete: false, visibleFacebookUnhide: false, visibleFacebookLike: false, visibleFacebookUnlike: false});
			this.props.onFbDelete(this);
		}
	},
	facebookRecepients: function(currentErrand, currentAreaData, serviceType){
		var recipients = [];
		if( serviceType != 'manual'){
			if( currentErrand.service != Workflow.Errand.SERVICE_MANUAL_FACEBOOK )
				recipients.push(<div className="iconAction" key="manualFBKey1"><a className="btn btn-block btn-social btn-fb-history" onClick={this.props.onFbProfile}><i className="fa fa-user"></i>{I("Profile")}</a></div>);
			if(currentErrand.fbPms != true){
				if( this.state.visibleFacebookDelete )
					recipients.push(<div className="iconAction" key="manualFBKey_visibleFacebookDelete"><a className="btn btn-block btn-social btn-fb-history" onClick={this.props.onFbHistory}><i className="fa fa-history"></i>{I("History")}</a></div>);
				if(this.state.visibleFacebookLike)
					recipients.push(<div className="iconAction" key="manualFBKey_visibleFacebookLike"><a className="btn btn-block btn-social btn-fb-history" onClick={this.handleLike}><i className="fa fa-thumbs-o-up"></i>{I("Like")}</a></div>);
				if(this.state.visibleFacebookUnlike)
					recipients.push(<div className="iconAction" key="manualFBKey_visibleFacebookUnlike"><a className="btn btn-block btn-social btn-fb-history" onClick={this.handleUnLike}><i className="fa fa-thumbs-o-up"></i>{I("Unlike")}</a></div>);
				if( this.state.visibleFacebookHide )
					recipients.push(<div className="iconAction" key="manualFBKey_visibleFacebookHide"><a className="btn btn-block btn-social btn-fb-history" onClick={this.handleHide}><i className="fa fa-eye-slash"></i>{I("Hide")}</a></div>);
				if( this.state.visibleFacebookUnhide )
					recipients.push(<div className="iconAction" key="manualFBKey_visibleFacebookUnhide"><a className="btn btn-block btn-social btn-fb-history" onClick={this.handleUnhide}><i className="fa fa-eye-slash"></i>{I("Unhide")}</a></div>);
				if( this.state.visibleFacebookDelete )
					recipients.push(<div className="iconAction" key="manualFBKey_visibleFacebookDelete_2"><a className="btn btn-block btn-social btn-fb-history" onClick={this.handleDeleteQ.bind(null, 'facebook')}><i className="fa fa-trash-o"></i>{I("Delete")}</a></div>);
				if( currentErrand.sendPm ){
					recipients.push(<span key="spanFBManualPM">
						<div className="iconAction" key="manualFBKey_sendPm">
							<a id="SendPMButton"
								className="btn btn-block btn-social btn-fb-history"
								onClick={this.props.onFbSendPM}>
								<i className="fa fa-edit"></i>{I("Send PM")}
							</a>
						</div>
						<span id="FacebookSendPM"></span>
					</span>);
				}
				if(this.state.visibleFacebookDelete && currentErrand.service != Workflow.Errand.SERVICE_MANUAL_FACEBOOK) {
					recipients.push(<span key="spanFBDel">
						<div className="iconAction" key="manualFBKey_visibleFacebookDelete_3">
							<a className="btn btn-block btn-social btn-fb-history"
								id="RatingButton" onClick={this.props.onFbRating}>
								<i className="fa fa-star-o"></i>{I("Rating")}
							</a>
						</div>
						<span id="FacebookRatings"></span>
					</span>);
				}
				if( currentErrand.closed ){
					if(!currentErrand.answerDeleted){
						if( currentErrand.hasAnswer ){
							if( currentErrand.service != Workflow.Errand.SERVICE_MANUAL_FACEBOOK )
								if( this.state.visibleFacebookDelete )
									recipients.push(<div className="iconAction" key="manualFBKey_visibleFacebookDelete_4"><a className="btn btn-block btn-social btn-fb-history" onClick={this.props.onFbDeleteAnswer}><i className="fa fa-trash-o"></i>{I("Delete Answer")}</a></div>);
						}
						if( this.state.visibleFacebookDelete )
							recipients.push(<span><div className="iconAction" key="manualFBKey_visibleFacebookDelete_5"><a className="btn btn-block btn-social btn-fb-history" onClick={this.props.onFbUpdateAnswer}><i className="fa fa-edit"></i>{I("Update answer")}</a></div><span id="FacebookUpdateAnswer" ></span></span>);
					}
				}
			}
			return recipients;
		}else{
			var facebookAccountPages = [];
			if( currentAreaData.fbAccounts != undefined && currentAreaData.fbAccounts.length > 0){
				currentAreaData.fbAccounts.forEach(function(fa){
					facebookAccountPages.push({id: fa.value, value: fa.name});
				});
				recipients.push(<span key="fbAccPage_1"><span className="align-left bold" style={{paddingRight: '10px'}}>{I('Facebook page:')}</span><div className="align-left"><DropDown id="facebookAccounts" items={facebookAccountPages}
				selectedItems={this.props.selectedSocialMedia != 0 ? this.props.selectedSocialMedia : ""}
				name={I("Facebook Account...")}
				textNoItem={I("No Facebook Account")}
				textChooseItem={I("Choose Facebook account")}
				textNoItemSelected={I("No Facebook Account selected")}
				textSelectedItem={I("Selected Facebook Account")}
				fields={{id: "id", name: "value"}} multiSelect={false} wantSelectAll={false} wantSelectNone={true} onChange={this.props.onSocialMediaChange} /></div><div className="clear"></div></span>);
			}else{
				recipients.push(<span key="fbAccPage_2">{I("No facebook page found.")}</span>);
			}
			return recipients;
		}
	},
	handleRetweet: function(){
		this.setState({twitterRetweet: !this.state.twitterRetweet})
		this.props.onTwitterRetweet(this);
	},
	twitterRecepients: function(currentErrand, currentAreaData, serviceType){
		var recipients = [];
		if(serviceType != 'manual'){
			if( currentErrand.service != Workflow.Errand.SERVICE_MANUAL_TWITTER ){
				recipients.push(<div className="iconAction" key="manualFBKey1"><a className="btn btn-block btn-social btn-tw-retweet" onClick={this.props.onFbProfile}><i className="fa fa-user"></i>{I("Profile")}</a></div>);
			}
			if( !currentErrand.twitterPm ){
				if( this.state.visibleFacebookDelete ){
					recipients.push(<div className="iconAction" key="manualTWKey_visibleFacebookDelete"><a className="btn btn-block btn-social btn-tw-retweet" onClick={this.props.onFbHistory}><i className="fa fa-history"></i>{I("History")}</a></div>);
				}
				if( this.state.twitterRetweet ){
					recipients.push(<div className="iconAction" key="twitterRecepientsRetweet"><a className="btn btn-block btn-social btn-tw-retweet" onClick={this.handleRetweet}><i className="fa fa-retweet"></i>{I("Retweet")}</a></div>);
				}
				else{
					//Cant get back un-retweet so does care handleRetweet
					recipients.push(<div className="iconAction" key="twitterRecepients"><a className="btn btn-block btn-social btn-tw-retweet red" ><i className="fa fa-retweet" style={{color: "red"}}></i>{I("Retweet")}</a></div>);
				}
				if( this.state.visibleFacebookLike )
					recipients.push(<div className="iconAction" key="twitterLikeRecepients"><a className="btn btn-block btn-social btn-tw-retweet" onClick={this.handleLike}><i className="fa fa-heart"></i>{I("Like")}</a></div>);
				if( this.state.visibleFacebookUnlike )
					recipients.push(<div className="iconAction" key="twitterUnlikeRecepients"><a className="btn btn-block btn-social btn-tw-retweet red" onClick={this.handleUnLike}><i className="fa fa-heart-o" style={{color: "red"}}></i>{I("Unlike")}</a></div>);
				if( this.state.visibleFacebookDelete ){
					var adminAcc = this.AdminAccount(currentErrand.fromName, currentAreaData.twAccounts);
					if( adminAcc ){
						recipients.push(<div className="iconAction" key="manualTwtKey_visibleInstaDelete_2"><a className="btn btn-block btn-social btn-tw-retweet" onClick={this.handleDeleteQ.bind(null, 'twitter')}><i className="fa fa-trash-o"></i>{I("Delete")}</a></div>);
					}
				}
				if( currentErrand.service != Workflow.Errand.SERVICE_MANUAL_TWITTER ){
					recipients.push(<span key="spanTWManualPM">
								<div className="iconAction" key="manualTWKey_sendPm">
									<a id="SendPMButton"
										className="btn btn-block btn-social btn-tw-retweet"
										onClick={this.props.onFbSendPM}>
										<i className="fa fa-envelope"></i>{I("Direct message")}
									</a>
								</div>
								<span id="twitterSendPM"></span>
							</span>);
				}
			}
			if( currentErrand.closed ){
				if(!currentErrand.answerDeleted){
					if( currentErrand.hasAnswer ){
						if( currentErrand.service != Workflow.Errand.SERVICE_MANUAL_TWITTER )
							if( this.state.visibleFacebookDelete )
								recipients.push(<div className="iconAction" key="manualTwtKey_visibleFacebookDelete_4"><a className="btn btn-block btn-social btn-tw-retweet" onClick={this.props.onFbDeleteAnswer}><i className="fa fa-trash-o"></i>{I("Delete Answer")}</a></div>);
					}
				}
			}
			if(currentErrand.twitterPm){
				if( this.state.visibleFacebookDelete ){
					recipients.push(<div className="iconAction" key="manualTwtKey_visibleInstaDelete_2"><a className="btn btn-block btn-social btn-tw-retweet" onClick={this.handleDeleteQ.bind(null, 'twitter')}><i className="fa fa-trash-o"></i>{I("Delete")}</a></div>);
				}
			}
		}else{
			var accountPages = [];
			if( currentAreaData.twAccounts != undefined && currentAreaData.twAccounts.length > 0){
				currentAreaData.twAccounts.forEach(function(fa){
					accountPages.push({id: fa.value, value: fa.name});
				});
				recipients.push(<span key="twtAccPage_1"><span className="align-left bold" style={{paddingLeft: '20px', paddingRight: '5px'}}>{I('Twitter account:')}</span><div className="align-left"><DropDown id="twtAccounts" selectedItems="" items={accountPages}
				selectedItems={this.props.selectedSocialMedia != 0 ? this.props.selectedSocialMedia : ""}
				name={I("Twitter account...")}
				textNoItem={I("No Twitter account")}
				textChooseItem={I("Choose Twitter account")}
				textNoItemSelected={I("No Twitter account selected")}
				textSelectedItem={I("Selected Twitter account")}
				fields={{id: "id", name: "value"}} multiSelect={false} wantSelectAll={false} wantSelectNone={true} onChange={this.props.onSocialMediaChange} /></div><div className="clear" style={{marginBottom: '5px'}}></div></span>);
			}
		}
		return recipients;
	},
	AdminAccount: function(from, twAcc){
		var canDelete = false;
		if( from == "")
			return canDelete;
		if( !Array.isArray(twAcc) )
			return canDelete;
		for(var i = 0; i < twAcc.length; i++){
			if(twAcc[i].name == from){
				canDelete = true;
				break;
			}
		}
		return canDelete;
	},
	linkedinRecepients: function(){
		var recipients = [];
		/*Mujibur: For linkedin we still didnt have any button to implement yet
		recipients.push(<div className="iconAction"><a className="btn btn-block btn-social btn-ln-connect" onClick={this.props.onLinkedinConnect}><i className="fa fa-linkedin"></i>{I("Connect")}</a></div>);*/
		return recipients;
	},
	instagramRecepients: function(){
		var recipients = [];
		if( this.state.visibleFacebookLike )
			recipients.push(<div className="iconAction" key="instagramLikeRecepients"><a className="btn btn-block btn-social btn-insta" onClick={this.handleLike}><i className="fa fa-heart"></i>{I("Like")}</a></div>);
		if( this.state.visibleFacebookUnlike )
			recipients.push(<div className="iconAction" key="instagramUnlikeRecepients"><a className="btn btn-block btn-social btn-insta red" onClick={this.handleUnLike}><i className="fa fa-heart-o" style={{color: "red"}}></i>{I("Unlike")}</a></div>);
		if( this.state.visibleFacebookDelete ){
			recipients.push(<div className="iconAction" key="manualFBKey_visibleInstaDelete_2"><a className="btn btn-block btn-social btn-fb-history" onClick={this.handleDeleteQ.bind(null, 'instagram')}><i className="fa fa-trash-o"></i>{I("Delete")}</a></div>);
		}
		return recipients;
	},
	smsRecepients: function(currentErrand, currentAreaData){
		var recipients = [];
		if(this.props.forwardExternalActived){
			var replyToStyle = {"display":"block"};
		}else{
			var replyToStyle = {"display":"none"};
		}
		recipients.push(<div className="col-md-6 smsRecepients" key="smsRecepients">
			<div className="reply-question" id="replyToBox">
				<div className="align-left bold">{I('Phone No.')}:</div>
				<div className="align-left">
					<TaggedPicker id="replyAddressComboboxSms"
						key="replyAddressComboboxSms"
						fields={{id:"id",name:"value"}}
						selectedItems={this.props.addresses.to}
						tags={[]} maxItem={1} disabled={this.props.lock}
						validateCustomTagBeforeAdding={this.props.verifyCustomTag}
						allowAddCustomItem={true}
						onChange={this.props.onSMSReplyTo} />
					<ErrorBox id="replySmsErrorBox" message={this.props.errorMessageManualErrand.errorMessageSms} key="errorBox_SmsRecepients" />
				</div>
				<div className="clear"></div>
			</div>
			<div className='overlayDiv' style={replyToStyle}></div>
		</div>);
		return recipients;
	},
	isValidPhoneNo: function(phoneNo){
		var phn = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
		return phn.test(phoneNo);
	},
	clearSocialMedia: function() {this.props.onSocialMediaChange("");},
	lineRecepients: function(currentErrand) {
		var recipients = [];
		var fromAddresses = this.formatCsv2Array(currentErrand.fromAddress);
		recipients.push(<div className="reply-question" id="replyToBox" key="lineRecepients">
			<div className="align-left bold">{I("Reply-To")+" :"}</div>
			<div className="align-right">
				<TaggedPicker id="replyAddressCombobox"
					key="replyAddressCombobox"
					fields={{id:"id",name:"value"}}
					allowAddCustomItem={true}
					maxItem={1}
					selectedItems={this.props.addresses.to}
					tags={fromAddresses}
					validateCustomTagBeforeAdding={this.isValidLINE}
					onChange={this.props.onReplyAddress} />
			</div><div className="clear"></div>
		</div>);
		return recipients;
	},
	manualLINERecepients: function(currentErrand, currentAreaData, serviceType) {
		var recipients = [];
		if(serviceType == "manual") {
			var lineAccounts = [];
			if(currentAreaData.lineAccounts != undefined && currentAreaData.lineAccounts.length > 0) {
				$.each(currentAreaData.lineAccounts, function(index, la) {
					var name = la.name;
					if(name == "") {
						// avoid empty name LINE channel
						// (when fail to retrieve channel info from LINE channel)
						name = la.value;
					}
					lineAccounts.push({id: la.value, value: name});
				});
				this.props.onSocialMediaChange(""); // force the default LINE selected as empty
				// LINE registered with Cention
				recipients.push(<div key="manualLineRecepients1">
					<div className="reply-question" id="replyCCBox">
						<div className="align-left bold">{I('LINE BOT account')+" :"}</div>
						<div className="align-right">
							<DropDown id="lineAccounts"
								selectedItems=""
								items={lineAccounts}
								name={I("LINE Account...")}
								textNoItem={I("No LINE Account")}
								textChooseItem={I("Choose LINE account")}
								textNoItemSelected={I("No LINE Account selected")}
								textSelectedItem={I("Selected LINE Account")}
								fields={{id: "id", name: "value"}}
								multiSelect={false}
								wantSelectAll={false}
								wantSelectNone={true}
								onSelectNone={this.clearSocialMedia}
								onChange={this.props.onSocialMediaChange}
							/>
						</div><div className="clear"></div>
					</div>
				</div>);
				// target receipient LINE account (MID)
				recipients.push(<div key="manualLineRecepients2">
					<div className="reply-question" id="replyToBox">
						<div className="align-left bold">{I("To")+" :"}</div>
						<div className="align-right manualline-tag">
							<TaggedPicker id="replyAddressCombobox"
								key="replyAddressCombobox"
								fields={{id:"id",name:"value"}}
								allowAddCustomItem={true}
								maxItem={1}
								selectedItems={this.props.addresses.to}
								tags={[]}
								validateCustomTagBeforeAdding={this.isValidLINE}
								onChange={this.props.onReplyAddress}
							/>
						</div><div className="clear"></div>
					</div>
				</div>);
			} else {
				recipients.push(<div key="manualLineRecepients3"><div className="reply-question" id="replyCCBox">
					<div className="align-left bold">{I("No LINE account found.")}</div>
					<div className="align-right"></div><div className="clear"></div>
				</div></div>);
			}
		}
		return recipients;
	},
	isValidLINE: function(line) {
		var re = /^centionline-[a-zA-Z0-9]+$/;
		return re.test(line);
	},
	vkDeleteQuestion: function() {
		this.handleDeleteQ("VKontakte");
	},
	vkontakteRecepients: function(currentErrand) {
		var recipients = [];
		var notManualErrand = (currentErrand.service != Workflow.Errand.SERVICE_MANUAL_VKONTAKTE);
		if(!notManualErrand && !this.state.visibleFacebookDelete) {
			var msg = "VK wall post DELETED";
			return <h4 style={{paddingLeft: "30px", margin: "0px"}}>{msg}</h4>;
		}
		if(notManualErrand) {
			recipients.push(
				<div key="profile" className="iconAction">
					<a className="btn btn-block btn-social btn-fb-history" onClick={this.props.onFbProfile}>
						<i className="fa fa-user"></i>{I("Profile")}</a>
				</div>
			);
		}
		if(currentErrand.fbPms != true) {
			recipients.push(
				<div key="history" className="iconAction">
					<a className="btn btn-block btn-social btn-fb-history" onClick={this.props.onFbHistory}>
						<i className="fa fa-history"></i>{I("History")}</a>
				</div>
			);
			if(this.state.visibleFacebookLike)
				recipients.push(
					<div key="like" className="iconAction">
						<a className="btn btn-block btn-social btn-fb-history" onClick={this.handleLike}>
							<i className="fa fa-thumbs-o-up"></i>{I("Like")}
						</a>
					</div>
				);
			if(this.state.visibleFacebookUnlike)
				recipients.push(
					<div key="unlike" className="iconAction">
						<a className="btn btn-block btn-social btn-fb-history" onClick={this.handleUnLike}>
							<i className="fa fa-thumbs-o-up"></i>{I("Unlike")}
						</a>
					</div>
				);
			if(this.state.visibleFacebookDelete)
				recipients.push(
					<div key="delete" className="iconAction">
						<a className="btn btn-block btn-social btn-fb-history" onClick={this.vkDeleteQuestion}>
							<i className="fa fa-trash-o"></i>{I("Delete")}
						</a>
					</div>
				);
			if(currentErrand.sendPm) {
				recipients.push(
					<span key="pm"><div className="iconAction">
						<a className="btn btn-block btn-social btn-fb-history" onClick={this.props.onFbSendPM}>
							<i className="fa fa-edit"></i>{I("Send PM")}</a></div>
						<span id="VKontakteSendPM"></span>
					</span>
				);
			}
			// recipients.push(
			// 	<span><div className="iconAction">
			// 		<a className="btn btn-block btn-social btn-fb-history" onClick={this.props.onFbRating}>
			// 			<i className="fa fa-star-o"></i>{I("Rating")}</a></div>
			// 		<span id="FacebookRatings"></span>
			// 	</span>
			// );
			if(currentErrand.closed) {
				if(!currentErrand.answerDeleted) {
					if(notManualErrand && currentErrand.hasAnswer) {
						recipients.push(
							<div key="delAnswer" className="iconAction">
								<a className="btn btn-block btn-social btn-fb-history" onClick={this.props.onFbDeleteAnswer}>
								<i className="fa fa-trash-o"></i>{I("Delete Answer")}</a>
							</div>
						);
					}
					recipients.push(
						<span key="updateAnswer"><div className="iconAction">
							<a className="btn btn-block btn-social btn-fb-history" onClick={this.props.onFbUpdateAnswer}>
							<i className="fa fa-edit"></i>{I("Update answer")}</a></div>
							<span id="VKontakteUpdateAnswer" ></span>
						</span>
					);
				}
			}
		}
		return recipients;
	},
	vkontakteManualRecepients: function(currentErrand, currentAreaData) {
		var recipients = [];
		var vkAccountPages = [];
		if(currentAreaData.vkAccounts != undefined && currentAreaData.vkAccounts.length > 0) {
			$.each(currentAreaData.vkAccounts, function(index, vka) {
				vkAccountPages.push({id: vka.value, value: vka.name});
			});
			recipients.push(<span key="vkontakteRecepientsList">
				<span className="align-left bold" style={{paddingRight: '10px'}}>{I('VKontakte page:')}</span>
				<div className="align-left">
					<DropDown id="vkontakteAccounts"
						selectedItems=""
						items={vkAccountPages}
						name={I("VKontakte Account...")}
						textNoItem={I("No VKontakte Account")}
						textChooseItem={I("Choose VKontakte account")}
						textNoItemSelected={I("No VKontakte Account selected")}
						textSelectedItem={I("Selected VKontakte Account")}
						fields={{id: "id", name: "value"}}
						multiSelect={false}
						wantSelectAll={false}
						wantSelectNone={true}
						onSelectNone={this.clearSocialMedia}
						onChange={this.props.onSocialMediaChange}
					/>
				</div>
				<div className="clear"></div>
			</span>);
		} else {
			recipients.push(<span key="vkontakteRecepientsList">{I("No VKontakte page found.")}</span>);
		}
		return recipients;
	}
});
