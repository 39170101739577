import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
//dev component
import LibraryTree from './LibraryTree';
import DropDown from './DropDown';

var LibrarySearchQuestionPreview = createReactClass({
	getDefaultProps: function() {
		return {
			show: false,
			static: false,
			subject: '',
			answer: '',
			position: {
				top: 0,
				left: 0
			}
		};
	},
	render: function() {
		var style = {
			position: 'absolute',
			background: '#fff',
			overflow: 'hidden',
			zIndex: 1002,
			borderBottom: '1px solid #efefef',
			width: '450px',
			top: 'auto',
			left: '-15px',
			marginTop: (25 + this.props.position.top) + 'px'
		};
		if (!this.props.show) {
			style.display = 'none';
		}
		var upArrowStyle = {
			width: 0,
			height: 0,
			borderLeft: '5px solid transparent',
			borderRight: '5px solid transparent',
			borderBottom: '5px solid #0779bf',
			fontSize: 0,
			lineHeight: 0,
			marginLeft: '130px'
		};
		var headerStyle = {
			borderTop: '1px solid ##2e6da4',
			borderLeft: '1px solid ##2e6da4',
			borderRight: '1px solid ##2e6da4',
			background: '#0779bf',
			color: '#fff',
			padding: '4px 8px'
		};
		var titleStyle = {
			borderLeft: '1px solid #efefef',
			borderRight: '1px solid #efefef',
			background: '#f8f8f8',
			padding: '4px 8px',
			fontWeight: 'bold'
		};
		var contentStyle = {
			borderLeft: '1px solid #efefef',
			borderRight: '1px solid #efefef',
			padding: '4px 8px'
		};
		var closeStyle = {
			float: 'right',
			fontFamily: 'Arial',
			fontWeight: 'bold',
			color: '#fff',
			cursor: 'pointer'
		};
		if (!this.props.static) {
			closeStyle.display = 'none';
		}
		var getQuestion = function() {
				return {
				__html: this.props.question
				};
		}.bind(this);
		var getAnswer = function() {
			return {
				__html: this.props.answer
			};
		}.bind(this);
		return (
			<div style={style}>
				<div style={upArrowStyle}></div>
				<div style={headerStyle}>
					{this.props.subject}
					<span style={closeStyle} onClick={this.props.onClosePreview}>x</span>
				</div>
				<div style={titleStyle}>{I('Question')}</div>
				<div style={contentStyle} dangerouslySetInnerHTML={getQuestion()}></div>
				<div style={titleStyle}>{I('Answer')}</div>
				<div style={contentStyle} dangerouslySetInnerHTML={getAnswer()}></div>
			</div>
		);
	},
});

var LibrarySearch = createReactClass({
	getInitialState: function() {
		return {
			showDropDown: false,
			libraryList: [],
			selectedLibrary: 0,
			searchValue: '',
			searching: false,
			showPreview: false,
			staticPreview: false,
			previewSubject: '',
			previewQuestion: '',
			previewAnswer: ''
		}
	},
	getDefaultProps: function() {
		return {
			defaultLibrary: 0
		};
	},
	componentWillMount: function() {
		this.loadLibraryList();
	},
	componentWillReceiveProps: function(nextProps) {
		if (nextProps.defaultLibrary > 0 && nextProps.defaultLibrary != this.props.defaultLibrary) {
			this.setState({
				selectedLibrary: nextProps.defaultLibrary
			});
		}
	},
	loadLibraryList: function() {
		var url = webRoot + 'errand/library/list';
		var parameters = {
			external: false
		};
		$.get(url, parameters).then(function(data) {
			this.setState({
				libraryList: data.list,
				selectedLibrary: (this.props.defaultLibrary > 0
					? this.props.defaultLibrary
					: (data.list.length > 0
						? data.list[0].id
						: 0)
					)
			});
		}.bind(this));
	},
	handleDocumentClick: function(event) {
		var component = ReactDOM.findDOMNode(this.refs.LibrarySearchDropDown);
		if ((event.target == component || $(component).has(event.target).length) == false) {
			this.hideDropDown();
		}
	},
	showDropDown: function() {
		$(document).bind('click', this.handleDocumentClick);
		var state = this.state;
		state.showDropDown = true;
		this.setState(state);
	},
	hideDropDown: function() {
		$(document).unbind('click', this.handleDocumentClick);
		this.setState({
			showDropDown: false,
			showPreview: false,
			staticPreview: false
		});
	},
	toggleDropDown: function() {
		if (this.state.showDropDown) {
			this.hideDropDown();
		} else {
			this.showDropDown();
		}
	},
	handleChangeLibrary: function(id) {
		var state = this.state;
		state.selectedLibrary = parseInt(id, 10);
		this.setState(state);
	},
	searchTimeoutId: 0,
	searchTimeoutDelay: 300,
	handleSearchChange: function(event) {
		this.setState({
			searchValue: event.target.value,
			forceTreeReload: false
		});
		if (this.searchTimeoutId) {
			window.clearTimeout(this.searchTimeoutId);
			this.searchTimeoutId = 0;
		}
		if (event.target.value.length >= 3) {
			this.setState({
				searching: true
			});
			this.searchTimeoutId = window.setTimeout(function() {
				this.setState({
					forceTreeReload: true
				});
			}.bind(this), this.searchTimeoutDelay);
		} else if (event.target.value.length == 0) {
			this.setState({
				forceTreeReload: true,
				searching: false
			});
		}
	},
	handleTreeLoadEnd: function() {
		this.setState({
			forceTreeReload: false,
			searching: false
		});
	},
	handleShowPreview: function(event, data) {
		if (!this.state.staticPreview) {
			this.setState({
				showPreview: true,
				previewSubject: (data.subject ? data.subject : data.name),
				previewQuestion: data.question,
				previewAnswer: data.answer,
				previewPosition: $(event.target).position()
			});
		}
	},
	handleHidePreview: function() {
		if (!this.state.staticPreview) {
			this.setState({
				'showPreview': false
			});
		}
	},
	handleClosePreview: function() {
		this.setState({
			showPreview: false,
			staticPreview: false,
			showDropDown: true
		});
	},
	handleSubjectClick: function() {
		this.setState({
			'staticPreview': true
		});
	},
	handleInsert: function(data) {
		if (this.props.onInsert) {
			this.props.onInsert(data)
		}
		this.setState({
			showDropDown: false
		});
	},
	render: function() {
		var chooseStyle = {
			cursor: 'pointer',
			marginRight: '3px'
		};
		var dropDownStyle = {
			top: 'auto',
			display: 'none',
			width: '575px',
			height: '375px',
			overflow: 'auto'
		};
		if (this.state.showDropDown) {
			dropDownStyle.display = 'block';
		}
		return (
			<div className="filterSearchBox">
				<div ref="LibrarySearchDropDown" className="bootstrap-tagsinput">
					<span className="fa fa-search"></span>
					<input type="text" style={{"maxWidth":"50px"}} value={this.state.searchValue} onFocus={this.showDropDown} onChange={this.handleSearchChange} />
					<span style={chooseStyle} onClick={this.toggleDropDown}>
						{I('Choose')}
						<span className="caret"></span>
					</span>
					<div className="dropdown-menu" style={dropDownStyle}>
						<div className="col-md-4">
							<span className="bold">{I('Knowledge base')}</span>
						</div>
						<div className="col-md-8">
							<DropDown
								id="LibrarySearchSelectLibrary"
								name={I("Knowledge base")}
								textNoItem={I("No Knowledge Base")}
								textChooseItem={I("Choose Knowledge base")}
								textNoItemSelected={I("No Knowledge base selected")}
								textSelectedItem={I("Selected Knowledge base")}
								selectedItems={this.state.selectedLibrary.toString()}
								items={this.state.libraryList}
								align="left"
								wantDeselect={false}
								fields={{id: 'id', name: 'name'}}
								onChange={this.handleChangeLibrary} />
						</div>
						<div className="clear"></div>
						<LibraryTree
							library={this.state.selectedLibrary}
							search={this.state.searchValue}
							searchSource="answer"
							forceReload={this.state.forceTreeReload}
							enablePreview={true}
							onShowPreview={this.handleShowPreview}
							onHidePreview={this.handleHidePreview}
							showAppend={true}
							showInsert={true}
							handleInsert={this.handleInsert}
							handleAppend={this.props.onAppend}
							onSubjectClick={this.handleSubjectClick} />
					</div>
				</div>
				<LibrarySearchQuestionPreview
					show={this.state.showPreview}
					static={this.state.staticPreview}
					subject={this.state.previewSubject}
					question={this.state.previewQuestion}
					answer={this.state.previewAnswer}
					position={this.state.previewPosition}
					onClosePreview={this.handleClosePreview} />
			</div>
		);
	}
});
export default LibrarySearch;
