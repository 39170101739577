import React from 'react';
import createReactClass from 'create-react-class';
import addonUpdate from 'react-addons-update';
//dev component
import OtherContacts from './OtherContacts';
import ErrandHelper from './ErrandHelper';
import WorkflowActions from '../flux/actions/workflowActions';
import WorkflowStore from '../flux/stores/workflowStore';

var AcquiredErrand = createReactClass({
	displayName: "AcquiredErrand",
	getDefaultProps: function(){
		return {
			selectable: true
		};
	},
	render: function() {
		var liStyle = {};

		if( this.props.active ) {
			liStyle.backgroundColor = '#ffa';
		}

		var getAttachments = function() {
			return { __html: ErrandHelper.sanitizeHtml(this.props.errand.data.attachments)};
		}.bind(this);

		var getSubject = function() {
			// Subject is escaped in the Go backend
			return { __html: ErrandHelper.sanitizeHtml(replacePM(this.props.errand.data.subject)) };
		}.bind(this);

		var getBody = function() {
			var errandBody = "";
			if(typeof this.props.errand.data.body !== 'undefined') {
				errandBody = this.props.errand.data.body.replace(/<[^>]*>?/g, ' ');
			}
			return { __html: ErrandHelper.sanitizeHtml(errandBody) };
		}.bind(this);
		return (<li key={this.props.errand.data.id} data-id={ this.props.errand.data.id } style={liStyle} onClick={this.props.onClick.bind(null,this.props.errand)}>
			<table className="tableAcquiredErrandHeader">
				<tbody>
					<tr>
						<td><span>ID: </span>{this.props.errand.data.displayId}</td>
						<td><span>{I("Channel")}: </span>{this.props.errand.data.serviceName}</td>
						<td className="textAlignRight"><span>{I("Area")}: </span>{this.props.errand.data.areaName}</td>
					</tr>
				</tbody>
			</table>
			<table className="tableAcquiredErrandBody">
				<tbody>
					<tr>
						<td className="checkboxOption">
							{this.renderSelector()}
							<div dangerouslySetInnerHTML={getAttachments()}></div>
						</td>
						<td>
							<div className="contentHeader">
								<div className="contentHeaderLeft" dangerouslySetInnerHTML={getSubject()}></div>
								<div className="contentHeaderRight">{this.props.errand.data.date}</div>
								<div className="clear"></div>
							</div>
							<div className="contentBody">
								<div className="fromStyle">{this.props.errand.data.from}</div>
								<div className="bodyStyle" dangerouslySetInnerHTML={getBody()}></div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</li>)
	},
	renderSelector: function(){
		if(this.props.selectable){
			return (<input type="checkbox" id={this.props.errand.data.id} disabled={this.props.active && this.props.selected} onClick={this.props.onSelect.bind(null,this.props.errand)} checked={this.props.selected} onChange={function(){}} />);
		}
	}
});

var AcquiredErrandsButton = createReactClass({
	displayName: "AcquiredErrandsButton",
	render: function() {
		var fontColor = "#white";
		var style = {
			display: (ErrandHelper.isMobile() ? "none" : "block"),
			right: (this.props.isExpanded ? 451 : (this.props.context == "chat" ? 19 : 0))
		};
		var status = "deactivate";
		var context = "";
		if(this.props.status){
			if(this.props.context !== "chat"){
				if(this.props.status.unanswered + this.props.status.answered > 1){
					if(this.props.status.unanswered > 1){
						status = "active";
						fontColor = "#F4E216";
					}else if(this.props.status.answered > 1){
						status = "default";
						fontColor = "#FFFFFF";
					} else {
						status = "deactivate";
						fontColor = "white";
					}
				}
			}else{
				context = "chat";
				if(this.props.status.unanswered + this.props.status.answered >= 1){
					if( this.props.status.unanswered > 0){
						status = "active";
						fontColor = "#F4E216";
					}else if(this.props.status.answered > 0){
						status = "default";
						fontColor = "#FFFFFF";
					} else {
						status = "deactivate";
						fontColor = "white";
					}
				}
			}
		}
		var spanStyle = {
			color: fontColor
		};

		if(this.props.context == "chat"){
			style.top = '15px';
			style.backgroundPosition = '5px 4px';
		}
		if(this.props.disabledButton){
			status = "deactivate";
			fontColor = "white";
		}
		return (
			<div id={"AcquiredErrandsButton" + context} className={"otherContactIcon " + status} style={style} onClick={this.props.onClick}>
				<span className="otherContactNumber" style={spanStyle}>{this.props.errands}</span>
			</div>
		)
	}
});

var ErrandThread = createReactClass({
	displayName: "ErrandThread",
	getDefaultProps: function(){
		context: "errand"// or "chat"
	},
	getInitialState: function() {
		return {
			loadedErrands:[],
			isExpanded: false,
			activeErrand: this.props.errand.id,
			disabledAcquiredButton: false
		};
	},
	componentWillMount: function() {
		this.addErrand(this.props.errand);
		this.loadErrands(this.props.relatedErrands);
	},
	componentDidMount: function() {
		if (Array.isArray(this.props.relatedErrands)){
			if((this.props.contextName == "New Errands") &&
				(this.props.relatedErrands.length > 0)){
				this.expandOrCollapseSidebar(true);
			} else if(this.props.relatedErrands.length > 1) {
				this.expandOrCollapseSidebar(true);
			}
		}
	},
	componentWillUpdate: function(nP,nS) {
		if(this.props.context == "chat"){
			if(nP.clientContactDetails && this.props.clientContactDetails){
				if(nP.clientContactDetails.acquired.length != this.props.clientContactDetails.acquired.length){
					//some actions are performed on the acquired errands outside of the knowldge of chat,
					//i.e put back to inbox in my errand page
					//so reload other contacts in chat, to be in sync
					//this.refs.OtherContacts.loadList(true, true, false, 0, false);
					//@Shah: If you want to review then do it! Basically this is creating some more unwanted
					//result.Plz review#CCC-1350
				}
			}
		}
	},
	componentWillReceiveProps: function(nextProps) {
		//if context is not chat then also need to re-render
		//the reason is after clicking ticker errand its need to update errand thread as well
		if(nextProps.errand.id != this.props.errand.id){
			var tmp = nextProps.errand;
			tmp.selected = true;
			if(this.props.context == "chat"){//changing tab
				this.loadErrands(nextProps.relatedErrands);
				this.setState({loadedErrands:[tmp],activeErrand:tmp.id});
			}else{
				if(typeof nextProps.relatedErrands !== 'undefined' && nextProps.relatedErrands.length > 1){
					var newLoadedErrands = addonUpdate(this.state.loadedErrands,{
						$push: [tmp]
					});
					this.setState({loadedErrands: newLoadedErrands}, function(){
						this.props.onClickErrandToLoad(tmp, this.state.loadedErrands);
					}.bind(this));
				}
				else{
					this.setState({loadedErrands:[tmp], activeErrand:tmp.id}, function(){
						this.props.onClickErrandToLoad(tmp, []);
					}.bind(this));
				}
			}
		}
	},
	loadErrands: function(relatedErrands){
		if (relatedErrands && relatedErrands.length > 0) {
			for (var i = 0; i < relatedErrands.length; i++) {
				if(relatedErrands[i] != this.props.errand.id){
					this.loadErrand(relatedErrands[i]);
				}
			}
		}
	},
	loadErrand: function(id) {
		$.post(webserverRoot + 'errand/basic/' + id)
		.done(function(errand) {
			this.addErrand(errand);
		}.bind(this));
	},
	addErrand: function(errand){
		errand.selected = true;
		var newLoadedErrands = addonUpdate(this.state.loadedErrands,{
			$push: [errand]
		});
		if(this.props.groupErrandsByThread && this.props.onSelectErrand){
			this.props.onSelectErrand(this.getSelectedErrands(newLoadedErrands));
		}
		this.setState({loadedErrands: newLoadedErrands});
	},
	doExpandSideBar: function(show){
		var sidebarStyle = 'right:-451px';
		if (show) {
			sidebarStyle = 'right:-450px';
		}
		$('#ErrandThreadAndOtherContacts').addClass(sidebarStyle).toggle('fast');
	},
	getSelectedErrands: function(errands){
		var selfErrand = this.props.errand;
		return errands.filter(function(item){
			return selfErrand.id != item.id && item.selected == true;
		});
	},
	handleAcquire: function(ownership) {
		if (ownership && !ownership.error && ownership.acquired) {
			this.loadErrand(ownership.errand);
			if(this.props.onAcquired){
				this.props.onAcquired(ownership.errand);
			}
		}
	},
	handleExpand: function(event) {
		event.preventDefault();
		this.expandOrCollapseSidebar(!this.state.isExpanded);
	},
	expandOrCollapseSidebar: function(newIsExpanded){
		this.setState({isExpanded: newIsExpanded});
		this.doExpandSideBar(newIsExpanded);
	},
	handleItemClick: function(errand,event){
		event.stopPropagation();
		var context = ErrandHelper.ERRAND_SRC_CONTEXT_MY;
		var index = this.state.loadedErrands.indexOf(errand);
		if(index > -1){
			var newLoadedErrands = addonUpdate(this.state.loadedErrands,{
					$splice:[[index, 1]]
			});
			this.setState({activeErrand: errand.id, loadedErrands: newLoadedErrands});
			this.addErrand(errand);
		}
		if(this.props.onClickErrandToLoad) {
			this.props.onClickErrandToLoad(errand, this.state.loadedErrands, context);
		}
	},
	handleItemSelect: function(errand,event){
		event.stopPropagation();
		var index = this.state.loadedErrands.indexOf(errand);
		if(index >= 0){
			var newLoadedErrands = addonUpdate(this.state.loadedErrands,{
				$apply: function(errands){
					errands[index].selected = !errand.selected;
					return errands;
				}
			});
			this.setState({loadedErrands: newLoadedErrands});
			if(this.props.groupErrandsByThread && this.props.onSelectErrand){
				this.props.onSelectErrand(this.getSelectedErrands(newLoadedErrands));
			}
		}
	},
	uniqueLoadedErrand: function(errands){
		return errands.filter(function(item, pos) {
						return errands.indexOf(item) == pos;
		}.bind(this));
	},
	render: function(){
		var sidebarStyle = {
			minHeight: '300px'
		};
		if(this.props.context == "chat"){
			sidebarStyle.top = '10px';
		}
		var uniqueErrands = this.uniqueLoadedErrand(this.state.loadedErrands);
		return (
			<div>
				<div id="ErrandThreadAndOtherContacts" style={sidebarStyle}>
					{this.renderAcquiredErrandList()}
					{this.renderOtherContact()}
				</div>
				<AcquiredErrandsButton
					viewportHeight={window.innerHeight - 170}
					isExpanded={this.state.isExpanded}
					onClick={this.handleExpand}
					errands={uniqueErrands.length}
					status={this.props.clientContactDetails}
					context={this.props.context}
					disabledButton={this.state.disabledAcquiredButton}
				/>
			</div>
		);
	},
	renderOtherContact: function(){
		return (
			<OtherContacts
				ref="OtherContacts"
				handleAcquire={this.handleAcquire}
				id={this.state.activeErrand}
				from={this.props.errand.data.fromAddress}
				groupErrand={this.props.groupErrandsByThread}
				onLoad={this.handleOnload}
				changeKey={this.props.changeKey}
			/>
		);
	},
	handleOnload: function(list){
		if( list.length == 0) {
			this.setState({disabledAcquiredButton: true})
		}else{
			this.setState({disabledAcquiredButton: false})
		}
	},
	renderAcquiredErrandList: function(){
		if( this.props.groupErrandsByThread ){
			var uniqueErrands = this.uniqueLoadedErrand(this.state.loadedErrands);
			return (
				<div id="AcquireErrandsBar" style={{height:window.innerHeight/2 - 120}}>
					<div className="sideTopTitle">{I("Acquired errands")}</div>
					<ol className="AcquireErrandsBarOrderList" style={{height: window.innerHeight/2 - 150, overflow: 'auto'}}>
						{
							uniqueErrands.map(function(errand) {
								return (
									<AcquiredErrand
										key={errand.id}
										errand={errand}
										active={this.state.activeErrand == errand.id}
										selected={errand.selected}
										onClick={this.handleItemClick}
										onSelect={this.handleItemSelect}
									/>
								);
							}.bind(this))
						}
					</ol>
				</div>
			);
		}
	},
});
export default ErrandThread;
