import React from 'react';
import createReactClass from 'create-react-class';
import SquareCheckbox from '../../app/reactcomponents/SquareCheckbox'
import classNames from 'classnames';
import { TextInputRow } from '../reactcomponents/Form';


var TagItem = createReactClass({
	getDefaultProps: function () {
		return {
		}
	},
	handleRemove: function (e) {
		this.props.onRemove(this.props.item);
	},
	render: function () {
		return (<span id={this.props.id + "_" + this.props.item.id} className={"tag label " + this.props.inputClass}>{this.props.item.value}<span data-role="remove" onClick={this.handleRemove}></span></span>);
	}
});

var TagGroup = createReactClass({
	getDefaultProps: function () {
		return {
			separator: " / ",
			onRemove: function () { }
		};
	},
	handleRemove: function () {
		this.props.onRemove(this.props.group);
	},
	render: function () {
		var className = "tag label " + this.props.inputClass;
		var items = this.props.group.map(function (item, i) {
			var out = item.value;
			if (i < this.props.group.length - 1) {
				out += this.props.separator;
			}
			return out;
		}.bind(this));
		return (
			<span className={className}>
				{items}
				<span data-role="remove" onClick={this.handleRemove}></span>
			</span>
		);
	}
});

var GenerateDropdown = createReactClass({
	displayName: "GenerateDropdown",
	getInitialState: function () {
		return {

		};
	},
	componentDidMount: function () {
		$('#drilldown_' + this.props.id).drilldown({
			wrapper_class: this.props.customCssClass ? this.props.customCssClass + ' drilldownDropdown' : 'drilldownDropdown',
			menu_class: 'drilldownDropdown-menu',
			submenu_class: 'nav ',
			parent_class: 'dd-parent',
			parent_class_link: 'dd-parent-a',
			active_class: 'active',
			header_class_list: 'breadcrumb',
			header_class: 'breadcrumbwrapper',
			class_selected: 'selected',
			event_type: 'click',
			hover_delay: 300,
			speed: 'fast',
			save_state: false,
			show_count: true,
			count_class: 'dd-count',
			icon_class: 'fa fa-chevron-right pull-right dd-icon',
			link_type: 'breadcrumb', //breadcrumb , link, backlink
			reset_text: '<span class="fa fa-folder-open"></span>', // All
			default_text: I('Select Category'),
			show_end_nodes: false, // drill to final empty nodes
			hide_empty: false, // hide empty menu when menu_height is set, header no effected
			menu_height: this.props.height,
			show_header: false,
			header_tag: 'div',// h3
			header_tag_class: 'list-group-item active' // hidden list-group-item active
		});
	},
	disableDefaultAction: function (tag1, tag2, tag3, e) {
		e.preventDefault();
		this.props.addSelectedItem(tag1, tag2, tag3);
	},
	render: function () {

		var f = "";
		if (this.props.tags != null) {
			f = this.props.tags.map(function (item, i) {
				if (item.level == 0 || item.level == 1) {
					if (item.secondLevelTags != null && item.secondLevelTags.length > 0) {
						var s = "";
						s = item.secondLevelTags.map(function (item2, j) {
							var t = "";
							if (item2.thirdLevelTags != null && item2.thirdLevelTags.length > 0) {
								t = item2.thirdLevelTags.map(function (item3, k) {
									return (<li key={k}><a href="#" onClick={this.disableDefaultAction.bind(this, item, item2, item3)}>{item3.value}</a></li>);
								}.bind(this));
								return (
									<li key={j}>
										<a href="#" onClick={this.disableDefaultAction.bind(this, item, item2, null)}>{item2.value}</a>
										<ul key={j}>
											{t}
										</ul>
									</li>
								);
							} else {
								return (<li key={j}><a href="#" onClick={this.disableDefaultAction.bind(this, item, item2, null)}>{item2.value}</a></li>);
							}
						}.bind(this));
						return (
							<li key={i}>
								<a href="#" onClick={this.disableDefaultAction.bind(this, item, null, null)}>{item.value}</a>
								<ul key={i}>
									{s}
								</ul>
							</li>
						);
					} else {
						return (<li key={i}><a href="#" onClick={this.disableDefaultAction.bind(this, item, null, null)}>{item.value}</a></li>);
					}
				}
			}.bind(this));
		}
		return (
			<ul id={"drilldown_" + this.props.id} style={{ 'overflowY': 'auto' }}>
				{f}
			</ul>
		);
	}
});

export const GenerateDropdown2 = createReactClass({
	displayName: "GenerateDropdown2",
	getInitialState: function () {
		return {
			currentItem: null,
			parentItems: [],
			searchText: '',
			currentSearchItem: null,
			useSearch: false
		};
	},
	handleItemClick: function (item, deleteCallback, parent, event) {
		event.preventDefault()
		event.stopPropagation()
		const children = item.children;
		if(this.state.parentItems.length > 0){
			parent = this.state.parentItems.slice();
		}
		if (children && children.length > 0) {
			var parentTempItems = [];
			for (var i = 0; i < parent.length; i++){
				if (item.id != parent[i].id){
					parentTempItems.push(parent[i]);
				} else{
					break;
				}
			}
			const parentItems = parentTempItems !== undefined ? parentTempItems : parent;
			if (this.state.currentItem) {
				parentItems.push(this.state.currentItem)
			}
			this.setState({ currentItem: item, parentItems })
		} else {
			const tags = parent;
			if (this.state.currentItem) {
				tags.push(this.state.currentItem)
			}
			tags.push(item)
			let newTags = [];
			if(!this.state.useSearch) {
				newTags = tags;
			} else {
				//Tags from search result, their parents are untracked,
				//so here's a way to get the set of it's parent properly
				const pTags = this.getTagAncestors(item.id, tags);
				tags.map((t, i) => {
					if(pTags.includes(t.id)) {
						newTags.push(t);
					}
				})
			}
			this.props.addSelectedItem(...newTags)
		}
		if (deleteCallback) {
			$.each(this.props.selectedTags, function (i, v) {
				if (v.includes(item.id)) {
					// this.props.onDeleteTag(i,v);
					deleteCallback(i);
				}
			});
		}
		// clear the search input
		this.setState({searchText: ""});
	},
	getTagAncestors: function(target, children, ancestors = []) {
		for (let node of children) {
			if (node.id === target) {
				return ancestors.concat(node.id);
			}
			let found = false;
			if(node.children) {
				found = this.getTagAncestors(target, node.children, ancestors.concat(node.id));
			}
			if (found) {
				return found;
			}
		}
		return undefined;
	},
	handleSearchTextChange: function (event) {
		this.setState({ searchText: event.target.value});
		if(event.target.value == "") {
			this.setState({ useSearch: false });
		} else {
			this.setState({ useSearch: true });
		}
	},
	handleBreadcrumbClick: function (item, event) {
		event.preventDefault();
		event.stopPropagation();
		if (!item) {
			this.setState({
				currentItem: null,
				parentItems: []
			});
		} else {
			var parentItems = [];
			for (var i = 0; i < this.state.parentItems.length; i++) {
				if (this.state.parentItems[i].id != item.id) {
					parentItems.push(this.state.parentItems[i]);
				} else {
					break
				}
			}
			this.setState({
				currentItem: item,
				parentItems: parentItems
			});
		}
	},
	renderItem: function (item, itemIndex, selectedItems, performSearch, searchText, parentSearch) {
		var arrow = "";
		var counter = "";
		var children = item.children;
		var childrenObj = {};
		let checked = false;
		let disabled = false;
		let deleteTag = this.props.onDeleteTag;
		let itemValue = "";
		var parentObject = [];
		if(performSearch){
			itemValue = item.value.replace(RegExp(searchText, 'ig'), function (match) {
				return "<b>" + match +"</b>"});
				
		} else {
			itemValue = item.value;
		}
		if(parentSearch){
			parentObject = parentSearch;
		}
		if (selectedItems) {
			for (var i = 0; i < selectedItems.length; i++) {
				if (item.id === selectedItems[i]) {
					checked = true;
					if (children && children.length > 0) {
						checked = "partial";
					}
				}
			}
		}
		if (children && children.length > 0) {
			arrow = (<span className="fa fa-chevron-right pull-right dd-icon"></span>);
			counter = (<span className="dd-count">({children.length})</span>);
			disabled = true;
			deleteTag = false;
		}
		return (
			<li key={item.id} data-qa-id={"drilldown_dd_" + item.value + "_" + item.id} className={itemIndex == 0 ? "selectedTag" : ""}
				onClick={(e) => this.handleItemClick(item, deleteTag, parentObject, e)}
			>
				<div className="dropdown-checkbox rounded">
					<SquareCheckbox
						className={classNames(
							'small',
							{ 'icon-minus-circle': checked === 'partial' },
							{ 'disabled': disabled }
						)}
						data-qa-id={"data-qa-id-tag" + item.id}
						checked={checked}
					/>
				</div>
				<a href="#"><div dangerouslySetInnerHTML={{__html:itemValue}} style={{display: "inline-flex"}}></div>{arrow} {counter}</a>
			</li>
		);
	},
	renderItems: function (currentItem, items, selectedItem, performSearch, searchText, parentSearch) {
		var renderedItems = [];
		var itemsLength = (items ? items.length : 0);
		if(performSearch){
			for (var i = 0; i < itemsLength; i++) {
				var search = new RegExp(searchText,"i");
				var result = search.test(items[i].value);
				var temp = parentSearch;
				if (result){
						renderedItems.push(this.renderItem(items[i], i, selectedItem, performSearch, searchText, parentSearch));
				}	
				if (items[i].children && items[i].children.length > 0){	
					if (temp){
						temp.push(items[i]);
						renderedItems.push(this.renderItems(currentItem, items[i].children, selectedItem, performSearch, searchText, temp));
					} else{
						var sliceobjs = [];
						sliceobjs.push(items[i]);
						renderedItems.push(this.renderItems(currentItem, items[i].children, selectedItem, performSearch, searchText, sliceobjs));
					}
				}
			}
		} else {
			for (var i = 0; i < itemsLength; i++) {
				renderedItems.push(this.renderItem(items[i], i, selectedItem));
			}
		}	
		return renderedItems;
	},
	renderBreadcrumb: function () {
		var items = [];
		let currentItem = this.state.currentItem;
		if (currentItem) {
			items.push(
				<li className="first" key="firstTag">
					<a href="#" onClick={this.handleBreadcrumbClick.bind(this, null)}>
						<span className="icon-tag"></span>
					</a>
					<span>{'>'}</span>
				</li>
			);
			let masterTag = [], relevantParents = [];
			if(this.state.parentItems.length > 0) {
				masterTag.push(this.state.parentItems[0]);
			}
			if(masterTag.length > 0) {
				relevantParents = this.getTagAncestors(currentItem.id, masterTag);
			}
			for (var i = 0; i < this.state.parentItems.length; i++) {
				var item = this.state.parentItems[i];
				var children = item.children;
				if(relevantParents.includes(item.id)) {
					if(children && children.length > 0) {
						items.push(
							<li
								className={i === 0 ? "second" : "sub-crumbs"} key={"listTag_" + i}
							title={item.value + ` (${children.length})`}
							onClick={this.handleBreadcrumbClick.bind(this, item)}>
							<a className="tag-label" href="#">
								{item.value} ({children.length})
							</a>
							<span>{'>'}</span>
						</li>
					);
					}
				}
			}
			var currentItemChildren = currentItem.children;
			var count = (currentItemChildren && currentItemChildren.length > 0
				? (<span>({currentItemChildren.length})</span>)
				: "");
			items.push(
				<li className="active" key="activeTag">
					<span title={currentItem.value} className="tag-label">{currentItem.value}&nbsp;</span>
					{count}
				</li>
			);
		} else {
			items.push(
				<li className="initial active" key="categoryTag"><i className="icon-tag"></i>{I('Tags')}</li>
			);
		}
		return (
			<div className="breadcrumbwrapper">
				<ul className="breadcrumb">
					{items}
				</ul>
			</div>
		);
	},
	componentDidUpdate(prevProps) {
		if ((prevProps.clearCurrentItem != this.props.clearCurrentItem) && this.props.clearCurrentItem) {
			this.setState({ currentItem: null });
		}
	},
	render: function () {
		var list = [];
		var performSearch = false;
		var items = [];
		if (this.state.currentItem) {
			list = this.state.currentItem.children;
		} else {
			list = this.props.tags;
		}
		const { selectedTags } = this.props;
		let output = [];
		let searchText = this.state.searchText.trim();
		for (let j = 0; j < selectedTags.length; j++) {
			for (let k = 0; k < selectedTags[j].length; k++) {
				let c = selectedTags[j][k];
				if (output.indexOf(c) === -1) {
					output.push(c);
				}
			}
		}
		if (searchText !== "" || this.state.useSearch){
			performSearch = true;
		}
		items = this.renderItems(this.state.currentItem, list, output, performSearch, searchText);
		var breadcrumb = this.renderBreadcrumb();
		var style = {
			maxHeight: document.body.clientHeight < 500 ? document.body.clientHeight - 200 : this.props.height,
			overflowY: 'auto',
			marginTop: this.props.marginTop ? this.props.marginTop : '0px'
		};
		return (
			<div className="drilldownDropdown" style={style}>
				{breadcrumb}
				<TextInputRow
					id="QA_tagDropdown_search_input"
					name="tagSearchBox"
					className="form-control tagSearchBox input-sm"
					icon="icon-search"
					placeholder={I("Search tag...")}
					value={this.state.searchText}
					onChange={this.handleSearchTextChange}
				/>
				<ul className="drilldownDropdown-menu nav" id="drillDownItems">
					{items}
				</ul>
			</div>
		);
	}
});

export default createReactClass({
	displayName: "DrilldownDropdown",
	getInitialState: function () {
		return {
		};
	},
	getDefaultProps: function () {
		return {
			selectedItems: [],
			groupItems: false
		};
	},
	componentWillMount: function () {
		if (F('hotkeys')) document.addEventListener("keydown", this.props.onKeyDown, false);
	},
	componentWillUnmount: function () {
		if (F('hotkeys')) document.removeEventListener("keydown", this.props.onKeyDown, false);
	},
	handleAddToSelectedItems: function (tag1, tag2, tag3) {
		var tags = [tag1, tag2, tag3];
		if (this.props.groupItems) {
			var items = [];
			for (var i = 0; i < tags.length; i++) {
				if (tags[i]) {
					items.push(tags[i]);
				}
			}
			if (!this.isSelected(items)) {
				this.props.selectedItems.push(items);
			}
		} else {
			tags.map(function (t, i) {
				if (t != null && !this.isSelected(t)) {
					this.props.selectedItems.push(t);
				}
			}.bind(this));
		}
		if (this.props.updateSelectedItem) {
			this.props.updateSelectedItem(this.props.selectedItems);
		}
	},
	findSelectedIndex: function (item) {
		if (Array.isArray(item)) {
			for (var i = 0; i < this.props.selectedItems.length; i++) {
				var group = this.props.selectedItems[i];
				if (Array.isArray(group) && this.areGroupsEqual(group, item)) {
					return i;
				}
			}
		} else {
			for (var i = 0; i < this.props.selectedItems.length; i++) {
				if (this.props.selectedItems[i].id == item.id) {
					return i;
				}
			}
		}
		return -1;
	},
	areGroupsEqual: function (a1, a2) {
		if (a1.length != a2.length) {
			return false
		}
		for (var i = 0; i < a1.length; i++) {
			if (a1[i].id != a2[i].id) {
				return false;
			}
		}
		return true;
	},
	isSelected: function (item) {
		return this.findSelectedIndex(item) < 0 ? false : true;
	},
	handleRemove: function (item) {
		this.props.selectedItems.splice(this.findSelectedIndex(item), 1);
		if (this.props.updateSelectedItem) {
			this.props.updateSelectedItem(this.props.selectedItems);
		}
	},
	renderItem: function (key, item) {
		return (<TagItem
			key={key}
			id={this.props.id}
			item={item}
			onRemove={this.handleRemove}
			inputClass={this.props.inputTagClass}
		/>);
	},
	renderGroup: function (key, group) {
		return (<TagGroup
			key={key}
			group={group}
			onRemove={this.handleRemove}
			inputClass={this.props.inputTagClass}
		/>);
	},
	renderSelectedItems: function () {
		if (this.props.selectedItems.length > 0) {
			return this.props.selectedItems.map(function (item, i) {
				if (Array.isArray(item)) {
					return this.renderGroup(i, item);
				}
				return this.renderItem(i, item);
			}, this);
		}
	},
	handleToggle: function (e) {
		if (this.props.updateDropDownToggle) {
			this.props.updateDropDownToggle(!this.props.initiallyToggleDropdown);
		}
	},
	renderToggle: function () {
		return (
			<input
				readOnly
				id={"tagToggle_" + this.props.id}
				onClick={this.handleToggle}
			/>
		);
	},
	render: function () {
		if (process.env.NODE_ENV !== 'production') {
			console.log('(DrilldownDropdown) this.props.height:', this.props.height);
		}
		return (
			<div id={"dropdown_" + this.props.id} className="errand-tag">
				<div className="bootstrap-tagsinput borderless">
					<span className="icon-tag"></span>
					{this.renderSelectedItems()}
					{this.renderToggle()}
				</div>
				{this.props.initiallyToggleDropdown ?
					<GenerateDropdown2
						id={this.props.id}
						customCssClass={this.props.customCssClass}
						tags={this.props.tags}
						addSelectedItem={this.handleAddToSelectedItems}
						height={this.props.height}
					/> : ""
				}
			</div>
		);
	}
});
