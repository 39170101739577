import React from 'react';
import createReactClass from 'create-react-class';

var ImageButton = createReactClass({
	handleOnClick: function( e ){
		e.preventDefault();
		this.props.onClick(this);
	},
	render: function(){
		return(
			<p className={"option " + this.props.pClass} id={this.props.Id} onClick={this.handleOnClick}>
				{this.props.title}
			</p>
		)
	}
});
export default ImageButton;