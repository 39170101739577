import React from 'react';
import createReactClass from 'create-react-class';
//dev component
import DropDownItem from './DropDownItem';

var TagItem = createReactClass({
	handleRemove: function(e){
		this.props.onRemove(this.props.item[this.props.fields.id]);
	},
	render: function(){
		return (<span className="tag label label-info">{this.props.item[this.props.fields.name]}<span data-role="remove" onClick={this.handleRemove}></span></span>);
	}
});
export default createReactClass({
	displayName:"AutoCompleter",
	getDefaultProps: function() {
		return {
			items: [],
			fields: {id:"id",name:"subject"}
		}
	},
	getInitialState: function(){
		return {selectedItems:[],filter:"",items:[],loading:false};
	},
	componentDidMount: function(){
		this.state.items = this.props.items;
		this.setState(this.state);
	},
	handleKeyDown:function(e){
		if(e.keyCode == 13 && e.target.value != ""){
			//this.addToSelectedTags(e.target.value);
		}
	},
	handleKeyUp: function(e){
		if(e.target.value.length >= 3){
			if(this.props.onAutoComplete){
				this.props.onAutoComplete(e.target.value)
				.then(function(resp){
					this.state.loading = false;
					this.state.items = resp.result;
					this.setState(this.state);
				}.bind(this));
				this.state.loading = true;
				this.setState(this.state);
			}
		}
		if(!$('#comboAutoComplete').parent().hasClass('open')){
			$('#comboAutoComplete').parent().toggleClass('open');
		}
	},
	handleChangeFilter: function(e){
		this.state.filter=e.target.value;
		this.setState(this.state);
	},
	handleRemove: function(id){
		this.state.selectedItems.splice(this.findSelectedIndex(id),1);
		this.setState(this.state);
		this.props.onChange(this.state.selectedItems);
	},
	handleSelectTag: function(e){
		//this.addToSelectedTags(e);
		console.log('handleSelectTag',e);
	},
	handleAutoComplete: function(e){
	},
	renderItems: function(){
		if(this.state.loading){
			return (<li><a id={"0"}>{I("Loading...")}</a></li>);
		}
		if(this.state.items.length <= 0){
			return (<li><a id={"0"}>{I("No Valid Completions")}</a></li>);				
		}
		if(this.state.items.length > 0){
			return this.state.items.map(function(item,i){
				return (<DropDownItem key={i} item={item} itemActions={this.props.onRenderItemActions(item,this.props.fields)} fields={this.props.fields}/>);
			},this);
		}
	},		
	render: function(){
		var style={width:'3em !important'};
		var styleDropDownMenu = {
			height: 'auto',
			maxHeight: '400px',
			overflowX: 'hidden'
		};
		return(
				<div className="dropdown">
					<div className="bootstrap-tagsinput" data-toggle="dropdown">
						<input type="text" placeholder="" onChange={this.handleChangeFilter} onKeyUp={this.handleKeyUp}/>
					<span className="fa fa-search" onClick={this.handleAutoComplete}></span>
					</div>
					<ul id="comboAutoComplete" className="dropdown-menu  dropdown-menu-right" aria-labelledby="dropdownMenu1" style={styleDropDownMenu}>
					{ this.renderItems() }
					</ul>
				</div>
		);
	}
});