import React from 'react';
import createReactClass from 'create-react-class';

var TranslationResultBox = createReactClass({
	getInitialState: function(){
		return{
            translatedText: "",
            defaultFromLang: '',
			defaultFromLangText: '',
			defaultToLang: 'en',
			defaultToLangText: 'English',
			translationContext: "errand",
		}
    },
    fetchTranslation: function( tc ){
		var translatioServer = window.location.protocol + "//" +window.location.hostname + "/Cention/";
        var translateUrl = translatioServer + "translate";
        var ts = {
					texts: tc,
					from: this.state.defaultFromLang,
					to: this.state.defaultToLang
				};
		return $.ajax(translateUrl, {
				type: "POST",
				data: JSON.stringify(ts),
				contentType: "application/json",
			});
	},
	componentDidMount: function(){
        this.setState({defaultFromLang: this.props.fromLang, defaultToLang: this.props.toLang}, function(){
            this.translation(this.props.targetContents);
        }.bind(this));
    },
    translation: function(tc){
        this.fetchTranslation(tc)
        .then(function(result){
            let rs = JSON.parse(result);
			if (rs.error) {
                alert(rs.error);
                return;
			} else {
                this.setState({translatedText: rs.texts.join('<br>')});
            }
        }.bind(this))
        .fail(function(){
            alert(I("We're sorry, the translation service is not responding."));
        })
    },
	componentWillReceiveProps: function(nextProps){
        if(this.props.fromLang != nextProps.fromLang || this.props.toLang != nextProps.toLang){
			this.setState({defaultFromLang: nextProps.fromLang, defaultToLang: nextProps.toLang}, function(){
                this.translation(nextProps.targetContents);
            }.bind(this));
        }
    },
	render: function(){
		var spinnerUrl = null;
		var ts = null;
		if(this.props.translatedText === "")
			spinnerUrl = <img src={uriForServerImageResource('loading_animation_liferay.gif')} />;
		if(this.props.translatedText !== "") {
			ts = <span dangerouslySetInnerHTML={{__html:this.state.translatedText}} />
			window.Translation.translatedQuestion = this.state.translatedText;
		}
		return(<div id="Translation" style={{minHeight: '50px', boder: '1px solid #f2f2f2'}}>
				<span id="spinnerTranslate" >{spinnerUrl}</span>
				<div id="Translation_IncomingLanguage" style={{height: "15px"}}><label>{I('Translating')}: {this.props.fromLangText} {I('to')} {this.props.toLangText}</label></div>
				<div id="Translation_IncomingTranslated" style={{height: '100%', width: '99%', overflow: 'auto'}}>{ts}</div>
			</div>
		);
	}
});
export default TranslationResultBox;