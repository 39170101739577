import io from 'socket.io-client';
import SockWrap from 'sockwrap';
import { REDUX_SOCKET } from '../redux/constants/constants';
import { hasPrefix } from '../common/helpers';

let redux;

if (hasPrefix(window.location.pathname, '/ng/v5')) {
	console.log('dbg: socket with redux');
	import('../redux/store/configureStore')
	.then(store => {
		redux = {store, actionPrefix: REDUX_SOCKET};
	})
	.catch(err => {
		console.log("dbg: error loading configure store module:", err);
	});
}

function log() {
	// Liberally borrowed from socket.io
	return 'object' == typeof console
		&& 'function' == typeof console.log
		&& Function.prototype.apply.call(console.log, console, arguments);
};

var baseURL = window.location.pathname.split('/').slice(0,-2).join('/');

var self = new Object();
self.socket = null;
self.log = log;
self.STATE_WAIT_DURATION = 60; // seconds
self.reconnectTimeoutId = null;

/*
 * This is for forcing use of long polling when websocket is deemed to
 * be unstable.
 *
 * lastPong is updated whenever we receive a pong from the server which
 * is by default every 25 seconds. If lastPong is stale then we go into
 * forced long polling mode, and in this mode lastPong would no longer
 * be updated because it has served its purpose.
 */
self.io = {
	forcelongpolling: false,
	pongTimeout: 60*1000,
	lastPong: 0,
	watchDogEnabled: false
};

self.shouldConnect = function() {
	return true;
};

self.onConnect = function() {}; // no op

self.onConnected = function() {}; // no op

self.bindEvents = function(socket) {
	socket.io.off('connect'); // clear all previously binded event
	socket.on('connect', function() {
		self.connected = true;
	});
	socket.io.off('disconnect'); // clear all previously binded event
	socket.on('disconnect', function() {
		self.connected = false;
	});
};

self.checkReconnect = function() {
	if(self.connected) {
		return;
	}
	if(!self.shouldConnect()) {
		return;
	}
	log('main: attempting to reconnect');
	// TODO forcelongpolling if there are many reconnection attempts
	self.forceLongPolling();
};

self.watchDog = function() {
	var now;

	if(self.io.forcelongpolling) {
		return;
	}

	now = (new Date()).getTime();
	if((now - self.io.lastPong) > self.io.pongTimeout && self.shouldConnect()) {
		self.forceLongPolling();
	} else {
		setTimeout(self.watchDog, self.io.pongTimeout);
	}
};

self.sockInitOrConnect = function(opt) {
	var ioOpt, forcedLongPolling = false;
	if(!self.socket) {
		this.onConnect();
		ioOpt = {
			path : baseURL + '/socket/agent.io'
		};
		if(opt && opt.forcelongpolling || self.io.forcelongpolling) {
			forcedLongPolling = true;
			ioOpt.transports = ['polling'];
		}
		ioOpt.autoConnect = false;
		self.socket = SockWrap(io(window.location.origin,ioOpt),
			forcedLongPolling, redux);
		this.bindEvents(self.socket);
		self.socket.io.off('pong'); // clear all previously binded event
		self.socket.on('pong', function() {
			if(!self.io.forcelongpolling) {
				self.io.lastPong = (new Date()).getTime();
			}
		});
		self.socket.connect();

		// trigger event for socket connection for chat triggered.
		if(window.parent) {
			window.parent.$(window.parent).trigger('socket.connection', {
				chat: true,
				sockman: self
			});
		} else {
			$(window).trigger('socket.connection', {
				chat: true,
				sockman: self
			});
		}

		if(!self.io.forcelongpolling && !self.io.watchDogEnabled) {
			self.io.watchDogEnabled = true;
			setTimeout(self.watchDog, self.io.pongTimeout);
		}
	}

	this.onConnected();

	// socket connection may fail,  wait STATE_WAIT_DURATION seconds
	// before showing the warning (red button)
	clearTimeout(self.reconnectTimeoutId);
	self.reconnectTimeoutId = setTimeout(function() {
		this.checkReconnect();
	}.bind(this), self.STATE_WAIT_DURATION * 1000);
};

// Note forceLongPolling() is a no-op if we are already using long polling
self.forceLongPolling = function() {
	// Force reconnect only if we're not yet forcing long polling.
	// Otherwise let socket.io follow its natural course  -
	// auto reconnect etc.
	if(!self.io.forcelongpolling) {
		if(self.callsock) {
			// must disconnect non-empty namespace first as there is
			// limitation on go-socket.io
			self.callsock.disconnect();
			self.callsock = null;
			if(window.parent) {
				$(window.parent).trigger('socket.connection', {close: true});
			} else {
				$(window).trigger('socket.connection', {close: true});
			}
		}
		if(self.socket) {
			self.socket.disconnect();
			self.socket = null;
		}
		self.io.forcelongpolling = true;

		log('chat: attempting to reconnect');
		self.sockInitOrConnect();
	}
};

self.startUpdateTimeout = null;

self.startUpdate = function() {
	log('start main socket (chat)');
	$.post('/socket/agent.api/start', {"dummy":"data"})
	.then(function(start){
		self.sockInitOrConnect();
	})
	.fail(function(e){
		if(e && e.status === 401) {
			window.location = process.env.PATH_PREFIX + '/login?p=chat'
			return;
		}
		log('chat: POST to /socket/agent.api/start failed, retrying in 7 seconds ...');
		clearTimeout(self.startUpdateTimeout);
		self.startUpdateTimeout = setTimeout(self.startUpdate, 7000);
	});
};

// for call server
self.startCall = function() {
	if(!self.socket) {
		log("start call triggered but main socket not ready");
		self.sockInitOrConnect();
		return;
	}
	log('start namespace twilio socket');
	var forcedLongPolling = false,
		ioOpt = {path : baseURL + '/socket/agent.io'};
	if(this.io.forcelongpolling) {
		forcedLongPolling = true;
		ioOpt.transports = ['polling'];
	} else {
		ioOpt.transports = ['websocket'];
	}
	ioOpt.autoConnect = false;
	self.callsock = SockWrap(io(window.location.origin+"/twilio", ioOpt),
		forcedLongPolling, redux);
	// trigger event for socket connection on call socket.
	if(window.parent) {
		window.parent.$(window.parent).trigger('socket.connection', {
			call: true,
			sockman: self
		});
	} else {
		$(window).trigger('socket.connection', {call: true, sockman: self});
	}
};

var f = function() {
	return self;
}

export default f;
