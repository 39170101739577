import React from 'react';
import createReactClass from 'create-react-class';

export default createReactClass({
	displayName: "AttachmentsContainer",
	getDefaultProps: function(){
		return {
			attachments: [
				{id:"1",download:"fasdfaf",value:"asdfasf",estSize:342342},
				{id:"2",download:"aaaa",value:"dfs",estSize:342342},
				{id:"3",download:"dddd",value:"dddd",estSize:342342},
			]
		}
	},
	renderSizeAccordingly: function(showSize) {
		if(showSize < 1000000)
			showSize = (showSize/1000).toFixed(2)+" kb";
		else
			showSize = (showSize/1000000).toFixed(2)+" mb";
		return showSize;
	},
	onDeleteAttachment: function(item,ev){
		ev.stopPropagation();
		if(this.props.onRemove){ 
			this.props.onRemove(item);
		}
	},
	render: function() {
		var webRoot = "";
		if(typeof this.props.webRoot !== 'undefined'){
			webRoot = this.props.webRoot;
		}
		return (
			<div className="theAttachmentContainer" style={{marginTop:'10px',marginBottom:'10px',height: '30px',overflowY: 'auto'}}>
				<div>
					{
						this.props.attachments.map(function(item, i){
							var showSize = this.renderSizeAccordingly(item.estSize);		
							return (
								<div key={i}  style={{marginRight:'10px',float:'left'}}>
								<a href={(item.fromLibrary?"":webRoot) + item.download}>
									{item.value}
									<span className="badge" style={{marginLeft:'3px'}}>({showSize})</span>
								</a>
								<span className="remove-attach" id={item.id} style={{marginLeft:'3px'}}>
									<i onClick={this.onDeleteAttachment.bind(this,item)} className="glyphicon glyphicon-remove"></i>
								</span>
								</div>
							)
						}.bind(this))
					}
				</div>
				<div className="attachmentSizeContainer">
					
				</div>
			</div>
		);
	}
});