import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import DropDown from './DropDown';

var languages = [
	{ label: 'Arabic', name: 'ar' },
	{ label: 'Bulgarian', name: 'bg' },
	{ label: 'Catalan', name: 'ca' },
	{ label: 'Chinese Simplified', name: 'zh-CHS' },
	{ label: 'Chinese Traditional', name: 'zh-CHT' },
	{ label: 'Czech', name: 'cs' },
	{ label: 'Danish', name: 'da' },
	{ label: 'Dutch', name: 'nl' },
	{ label: 'English', name: 'en' },
	{ label: 'Estonian', name: 'et' },
	{ label: 'Finnish', name: 'fi' },
	{ label: 'French', name: 'fr' },
	{ label: 'German', name: 'de' },
	{ label: 'Greek', name: 'el' },
	{ label: 'Haitian Creole', name: 'ht' },
	{ label: 'Hebrew', name: 'he' },
	{ label: 'Hindi', name: 'hi' },
	{ label: 'Hmong Daw', name: 'mww' },
	{ label: 'Hungarian', name: 'hu' },
	{ label: 'Indonesian', name: 'id' },
	{ label: 'Italian', name: 'it' },
	{ label: 'Japanese', name: 'ja' },
	{ label: 'Klingon', name: 'tlh' },
	{ label: 'Klingon (pIqaD)', name: 'tlh-Qaak' },
	{ label: 'Korean', name: 'ko' },
	{ label: 'Latvian', name: 'lv' },
	{ label: 'Lithuanian', name: 'lt' },
	{ label: 'Malay', name: 'ms' },
	{ label: 'Maltese', name: 'mt' },
	{ label: 'Norwegian', name: 'no' },
	{ label: 'Persian', name: 'fa' },
	{ label: 'Polish', name: 'pl' },
	{ label: 'Portuguese', name: 'pt' },
	{ label: 'Romanian', name: 'ro' },
	{ label: 'Russian', name: 'ru' },
	{ label: 'Slovak', name: 'sk' },
	{ label: 'Slovenian', name: 'sl' },
	{ label: 'Spanish', name: 'es' },
	{ label: 'Swedish', name: 'sv' },
	{ label: 'Thai', name: 'th' },
	{ label: 'Turkish', name: 'tr' },
	{ label: 'Ukrainian', name: 'uk' },
	{ label: 'Urdu', name: 'ur' },
	{ label: 'Vietnamese', name: 'vi' },
	{ label: 'Welsh', name: 'cy' }
];
var ErrandTranslation = createReactClass({
	getInitialState: function(){
		return{
			shouldVisible: false,
			defaultFromLang: '',
			defaultFromLangText: '',
			defaultToLang: '',
			defaultToLangText: '',
			translationContext: "errand",
			msgUnavailable: I("We're sorry, translation service is not available at the moment."),
			msgNotResponding: I("We're sorry, the translation service is not responding."),
			msgSelectFromLang: I("We're sorry, the translation service will not work until a From language is selected."),
		}
	},
	componentDidMount: function(){
		this.setState({
			defaultFromLang: this.props.fromLang,
			defaultToLang: (this.state.translationContext == "chat" ? this.props.toLang : ""),
			translationContext: this.props.contextType,
		});
		if(this.props.contextType == "chat" && this.props.onFromLangChange){
			this.props.onFromLangChange(this.props.fromLang,this.getSelectedLangText(this.props.fromLang));
		}
	},
	getSelectedLangText: function( name ){
		var text = "English";
		languages.forEach(function(v, i){
			if( v["name"] == name ){
				text = v['label'];
			}	
		}.bind(this));
		return text;
	},
	handleChangeFromLang: function(fromLang){
		if(this.props.contextType == "chat" && this.props.onFromLangChange){
			this.props.onFromLangChange(fromLang,this.getSelectedLangText(fromLang));
		}
		this.setState({defaultFromLang: fromLang});
	},
	handleChangeToLang: function(toLang){
		if(this.props.contextType == "chat" && this.props.onToLangChange){
			this.props.onToLangChange(toLang,this.getSelectedLangText(toLang));
			return;
		}
		if(this.state.defaultFromLang === ''){
			alert(this.state.msgSelectFromLang);
			return
		}
		this.props.onSelectToLang( toLang );
		this.setState({defaultToLang: toLang});

		if (!window.Translation) {
			window.Translation = {};
		}
		window.Translation.toLangText = this.getSelectedLangText( toLang );
		window.Translation.fromLangText = this.getSelectedLangText( this.state.defaultFromLang );
	},
	render: function(){
		var fromLang = <DropDown
					id={this.props.id + "fromLanguage"}
					selectedItems={this.state.defaultFromLang}
					items={languages}
					fields={{id: "name", name: "label"}}
					singleSelect={true}
					type="select"
					class={this.state.translationContext == "chat" ? "dropup" : ""}
					name={I("From")}
					textNoItem={I("No From")}
					textChooseItem={I("Choose From")}
					textNoItemSelected={I("No From selected")}
					textSelectedItem={I("Selected From")}
					onChange={this.handleChangeFromLang} />;
		var toLang = <DropDown
					id={this.props.id + "toLanguage"}
					selectedItems={this.state.translationContext == "chat" ? this.props.toLang : this.state.defaultToLang}
					items={languages}
					fields={{id: "name", name: "label"}}
					singleSelect={true}
					type="select"
					name={I("To")}
					textNoItem={I("No To")}
					textChooseItem={I("Choose To")}
					textNoItemSelected={I("No To selected")}
					textSelectedItem={I("Selected To")}
					class={this.state.translationContext == "chat" ? "dropup" : ""}
					onChange={this.handleChangeToLang} />;
		if(this.props.shouldVisible){
			return(<span className="textTranslate">
					<span>{fromLang}</span>
					<span><label className="translateBetween">{I("to")}:</label>{toLang}</span>
				</span>
			);
		}else{
			return(null);
		}
	}
});
export default ErrandTranslation;