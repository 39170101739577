import { isV5page } from '../common/helpers';
import fluxActions from '../flux/actions/chatActions';
import fluxStore from '../flux/stores/chatStore';
import {
	acquireErrand,
	doSend,
	newSessionReceived,
	onAcceptChat,
	onActivate,
	onAgentPresence,
	onAssociateErrand,
	onClientPaths,
	onConnectStatus,
	onDeadSession,
	onMessageAcked,
	onPreview,
	onQueueSend,
	onTags,
	onUnsentMessages,
	onUpdateTags,
	showSystemErrorMessage,
	updateClientStatus,
	updateMessage
} from '../redux/actions/chat';
import {
	onFinishSession,
	onSessionInit
} from '../redux/actions/async/chat';
import store from '../redux/store/configureStore';
import {
	getChatSocket
} from '../redux/util';

let ChatActions, ChatStore, currentValue, subscribers = [];

if (isV5page()) {
	console.log('dbg: chat use v5');

	function handleChange() {
		let previousValue = currentValue;
		currentValue = getChatSocket(store.getState());
		if (previousValue !== currentValue) {
			$.each(subscribers, (i,v) => {
				if(typeof v === 'function') {
					v(currentValue);
				}
			});
		}
	}
	const unsubscribe = store.subscribe(handleChange);
	// unsubscribe();

	ChatActions = {
		showSystemErrorMessage: o => {store.dispatch(showSystemErrorMessage(o))},
		onMessageAcked: o => {store.dispatch(onMessageAcked(o))},
		onPreview: o => {store.dispatch(onPreview(o))},
		updateClientStatus: o => {store.dispatch(updateClientStatus(o))},
		onDeadSession: o => {store.dispatch(onDeadSession(o))},
		newSessionReceived: o => {store.dispatch(newSessionReceived(o))},
		onQueueSend: o => {store.dispatch(onQueueSend(o))},
		doSend: o => {store.dispatch(doSend(o))},
		updateMessage: o => {store.dispatch(updateMessage(o))},
		onActivate: o => {store.dispatch(onActivate(o))},
		onFinishSession: o => {store.dispatch(onFinishSession(o))},
		onUnsentMessages: o => {store.dispatch(onUnsentMessages(o))},
		onTags: o => {store.dispatch(onTags(o))},
		onClientPaths: o => {store.dispatch(onClientPaths(o))},
		onUpdateTags: o => {store.dispatch(onUpdateTags(o))},
		onAgentPresence: o => {store.dispatch(onAgentPresence(o))},
		onConnectStatus: o => {store.dispatch(onConnectStatus(o))},
		onAcceptChat: o => {store.dispatch(onAcceptChat(o))},
		acquireErrand: o => {store.dispatch(acquireErrand(o))},
		onAssociateErrand: o => {store.dispatch(onAssociateErrand(o))},
		onSessionInit: o => {store.dispatch(onSessionInit(o))}
	};

	ChatStore = {
		getState: () => getChatSocket(store.getState()),
		listen: listener => {
			let found;
			$.each(subscribers, (i,v) => {
				if(v === listener) {
					found = true;
					return false;
				}
			});
			if(!found) {
				subscribers.push(listener);
			}
		},
		unlisten: listener => {
			let found;
			$.each(subscribers, (i,v) => {
				if(v === listener) {
					found = {index: i};
					return false;
				}
			});
			if(found) {
				subscribers.splice(found.index, 1);
			}
		}
	};
} else {
	console.log('dbg: chat NOT using v5');
	ChatActions = fluxActions;
	ChatStore = fluxStore;
}

export { ChatActions, ChatStore };
