import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';

//vendor
import 'jquery-spellchecker';
import 'ckeditor-core';

var defaultToolbar = 'Empty';

var LightCkeditor = createReactClass({
	myState: "uninit",
	plainTxt: "",
	richTxt: "",
	value: "",
	setDataInProgress: false,
	requestRemoveCkeditor: false,
	config: {
		blockedKeystrokes: [13, CKEDITOR.SHIFT + 13],
		resize_enabled: false,
		disableNativeSpellChecker: false,
		forcePasteAsPlainText: true,
		toolbar_Empty: [],
		toolbar_Simple: [
			[ 'Cention_emoji' ], [ 'SpellCheckerLanguage' ], [ 'SpellChecker' ]
		],
		toolbar_Minimal: [[ 'Cention_emoji' ]],
		toolbar: defaultToolbar,
		removePlugins: 'pastefromword',
		plugins:
			'clipboard,' + // need to filter pasted text
			'pastetext,' + // need to paste only plain text
			'undo,' + // need to auto save Ckeditor as snapshot for undo
			'bbcode,' + // need to shrink the Ckeditor height
			'floatingspace,' + // need by Ckeditor.inline
			'font,' /* + // need by spellcheck plugin
			'toolbar' */, // need to display toolbar
		language: 'en',
		spellCheckLanguages: []
	},
	getInitialState: function() {
		return {};
	},
	setSpellLanguages: function(list) {
		if(!list) {
			list = [];
		}
		this.config.spellCheckLanguages = list;
	},
	addXtraPlugin: function(plugin) {
		if(!plugin) return;
		if(typeof this.config.extraPlugins !== 'undefined') {
			this.config.extraPlugins += ',' + plugin;
		} else {
			this.config.extraPlugins = plugin;
		}
	},
	setLanguage: function(language) {
		this.config.language = language;
	},
	setupConfig: function() {
		window.CKEDITOR_BASEPATH = location.origin + '/ng' +
			"/vendor/ckeditor/";
		if(typeof currentInterface !== 'undefined' && currentInterface != "") {
			this.setLanguage(currentInterface);
		} else {
			this.setLanguage('en');
		}
		this.setSpellLanguages(this.props.spellLanguages);
		this.addXtraPlugin('cention_emoji');
		this.addXtraPlugin('cention_spellcheck');
		if(this.props.toolbar) {
			this.config.toolbar = this.props.toolbar;
		}
		CKEDITOR.disableAutoInline = true;
		CKEDITOR.addCss('.spellchecker-word-highlight { color: red; cursor: pointer; border-bottom: 1px dotted red; }');
	},
	setupCkeditor: function() {
		if(this.props.updateFloatSpace) {
			this.config.enCmdUpdate = true;
		}
		this.editor = CKEDITOR.inline(this.props.id, this.config);
		CKEDITOR.instances[this.props.id].on("instanceReady", function(event) {
			if(this.props.defaultDebug) {
				console.debug("LightCkeditor: Ckeditor ready");
			}
			this.myState = "ready";
		}.bind(this));
		CKEDITOR.instances[this.props.id].on("key", function(event) {
			if(this.props.onKeydown) {
				this.props.onKeydown(event);
			}
		}.bind(this));
		CKEDITOR.instances[this.props.id].on("change", function(event) {
			this.plainTxt = event.editor.container.getText();
			this.richTxt = event.editor.getData(); // sync real DOM and VDOM
			if(this.props.onChange) {
				this.props.onChange(this.richTxt);
			}
		}.bind(this));
		CKEDITOR.instances[this.props.id].on("focus", function(event) {
		}.bind(this));
		CKEDITOR.instances[this.props.id].on('paste', function(evt) {
			evt.data.dataValue = evt.data.dataValue
				.replace(/\r\n?|\n|<br\s?\/?>/g, ' ');
		});
	},
	setData: function(txt) {
		this.setDataInProgress = true;
		this.editor.setData(txt, {
			callback: function() {
				this.plainTxt = txt;
				this.richTxt = txt;
				this.setDataInProgress = false;
				if(this.requestRemoveCkeditor) {
					this.removeCkeditor();
					this.requestRemoveCkeditor = false;
				}
			}.bind(this)
		});
	},
	removeCkeditor: function() {
		if(this.setDataInProgress) {
			this.requestRemoveCkeditor = true;
		} else {
			this.editor.destroy();
			this.editor = null;
		}
	},
	setPropsValue: function() {
		this.SetValue(this.props.value);
	},
	SetValue: function(t) {
		if(this.props.defaultDebug) {
			console.debug("LightCkeditor: SetValue:", t);
		}
		if(this.props.enCkeditor) {
			this.setData(t);
		} else {
			// TODO: getDOMNode deprecate in React >=0.14
			var domNode = this.getDOM(this.refs.myInput);
			domNode.value = t;
		}
	},
	getDOM: function(input){
		return ReactDOM.findDOMNode(input);
	},
	GetValue: function() {
		var txt;
		if(this.props.enCkeditor) {
			txt = this.richTxt;
		} else {
			// TODO: getDOMNode deprecate in React >=0.14
			var domNode = this.getDOM(this.refs.myInput);
			txt = domNode.value;
		}
		return txt;
	},
	UpdateFloatSpace: function() {
		if(this.props.defaultDebug) {
			console.debug("LightCkeditor: UpdateFloatSpace");
		}
		if(this.props.enCkeditor && this.props.updateFloatSpace) {
			this.editor.execCommand('updatefloatingspace');
		}
	},
	shouldComponentUpdate: function(nextProps) {
		if(!this.props.enCkeditor) {
			return true;
		} else if(typeof nextProps.value === 'string' &&
			nextProps.value != this.richTxt) {
			return true;
		}
		return false;
	},
	componentWillReceiveProps: function(nextProps) {
		if(this.props.defaultDebug) {
			console.debug("LightCkeditor: componentWillReceiveProps",
				this.value, nextProps.value);
		}
		if(!this.props.enCkeditor) {
			return;
		}
		if(typeof nextProps.value === 'string') {
			this.value = nextProps.value;
		}
	},
	componentDidUpdate: function() {
		if(this.props.defaultDebug) {
			console.debug("LightCkeditor: componentDidUpdate", this.value,
				this.props.value);
		}
		if(!this.props.enCkeditor) {
			return;
		}
		if(!this.editor) {
			this.setupConfig();
			this.setupCkeditor();
		} else {
			if(typeof this.props.value === 'string' &&
				this.props.value != this.richTxt) {
				this.setPropsValue();
			}
		}
	},
	componentWillMount: function() {
		if(!this.props.enCkeditor) {
			return;
		}
		if(typeof this.props.defaultValue === 'string') {
			this.value = this.props.defaultValue;
		} else if(typeof this.props.value === 'string') {
			this.value = this.props.value;
		}
		this.plainTxt = this.value;
		this.richTxt = this.value;
		this.setupConfig();
		if(this.props.defaultDebug) {
			this.tid = setInterval(function() {
				//console.debug("this.plainTxt:", this.plainTxt);
				console.debug("this.richTxt:", this.richTxt);
			}.bind(this), 7500);
		}
	},
	componentDidMount: function() {
		if(!this.props.enCkeditor) {
			return;
		}
		this.setupCkeditor();
	},
	componentWillUnmount: function() {
		this.removeCkeditor();
		if(this.tid) {
			clearInterval(this.tid);
			this.tid = null;
		}
	},
	render: function() {
		var dom = null;
		if(this.props.enCkeditor) {
			dom = (<div id={this.props.id} className={this.props.cssClass}
				contentEditable={true} style={this.props.style}
				dangerouslySetInnerHTML={{__html: this.value}}></div>);
		} else {
			if(this.editor) {
				this.removeCkeditor();
			}
			dom = (<input ref="myInput" className={this.props.cssClass}
				style={this.props.style} value={this.props.value}
				defaultValue={this.props.defaultValue}
				onChange={this.props.onChange} type="text" />);
		}
		return dom;
	}
});
export default LightCkeditor;
