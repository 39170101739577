import React from 'react';
import createReactClass from 'create-react-class';
var oldWebRoot = location.protocol + '//' + location.hostname;
export default createReactClass({
	displayName: "AgentAttachment",
	getDefaultProps: function () {
		return {
			items: {},
			onChangeArea: false
		}
	},
	getInitialState : function(){
		return{
			items : [],
			//totalAttachmentSize:0,
			//totalSavedAttachment:0
		}
	},
	componentWillMount: function () {
		this.setState({
			items: this.state.items.concat(this.props.items)
		});
	},
	componentWillReceiveProps: function(nextProps) {
		if(nextProps.onChangeArea){
			this.setState({
				items: nextProps.items
			});
		}else{
			this.setState({
				items: this.state.items.concat(nextProps.items)
			});
		}
	},
	renderSizeAccordingly: function(showSize) {
		if(showSize < 1000000)
			showSize = (showSize/1000).toFixed(2)+" kb";
		else
			showSize = (showSize/1000000).toFixed(2)+" mb";
		return showSize;
	},
	formatBytes: function(bytes,decimals) {
		if(bytes == 0) return '0 Byte';
		var k = 1000; // or 1024 for binary
		var dm = decimals + 1 || 3;
		var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		var i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	},
	formatSizeUnits: function(bytes) {
		var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes == 0) return '0 Byte';
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
	},
	displaySizeContainer: function(origin, totalAttachmentSize) {
		var maxEmailLimit = F('max-email-limit');
		if(maxEmailLimit < 0)
			maxEmailLimit = 0;
		maxEmailLimit =  this.formatSizeUnits(maxEmailLimit);
		var maxErrandSize = "";
		if( maxEmailLimit != 0 ){
			maxErrandSize = I('Max Errand Size: ') + maxEmailLimit
		}
		if(origin=="ErrandAttachment") {
			return(
				<div className="totalSizeContainer">
					{I('Total Attachment Size:')} {this.formatBytes(totalAttachmentSize, 1)}<br/>
					{maxErrandSize}
				</div>
			);
		}
	},
	render: function() {
		var showSize = "";
		var defaultOrigin = "ErrandAttachment";
		var totalAttachmentSize=0;
		var totalSavedAttachment=0;
		var origin = this.props.origin?this.props.origin:defaultOrigin;
		var maxItemLength = this.state.items.length;
		return (
			<div className="theAttachmentContainer">
				<div className="attachmentItem">
					<i className="fa fa-paperclip"></i>
					{this.state.items.map(function(item, i){
						showSize = this.renderSizeAccordingly(item.estSize);
						totalAttachmentSize += item.estSize;
						var totalSavedAttachment = 0;
						if(origin=="SavedAttachement") {
							totalSavedAttachment = totalAttachmentSize;
						} else {
							if((i+1) == maxItemLength) {
								totalAttachmentSize += totalSavedAttachment;
							}
						}
						return(
							<span className="file-attach" id={"fileAttach" + item.id} key={i}>
								<a className="underlink" href={ oldWebRoot + item.download}>{item.value}</a><span className="attachmentItemSize"> ({showSize})</span>
								<span className="remove-attach" id={item.id}><i onClick={this.onDeleteAttachment.bind(this,item)} className="glyphicon glyphicon-remove" ></i></span>
							</span>
							)
						}.bind(this))
					}
				</div>
				<div className="attachmentSizeContainer">
					{this.displaySizeContainer(origin, totalAttachmentSize)}
				</div>
			</div>
		);
	},
	onDeleteAttachment: function(attachment){
		this.state.items.splice(this.state.items.indexOf(attachment), 1);
		this.forceUpdate();
		this.props.onHandle(attachment);
	}
});