import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import 'datetimepicker';

var Calendar = createReactClass({
	displayName:"Calendar",
	getInitialState: function() {
		return {
			title: I('Chose'),
			value:"",
			humanValue:"",
			timepicker:false,
			format:'Y/m/d',
			closeOnDateSelect:true,
			valid:true,
			datepicker: false
		}
	},
	getDefaultProps: function () {
		return {
			allowPastDate: true,
			disabled: false
		}
	},
	componentDidUpdate: function() {
		this.checkDisabled();
	},
	checkDisabled: function() {
		var conf = {};
		conf.datepicker = true;
		conf.timepicker = false;
		if(typeof this.props.timepicker != 'undefined' && this.props.timepicker == true){
			conf.datepicker = false;
			conf.timepicker = true;
		}
		$("#"+this.props.id).datetimepicker(conf);
	},
	componentWillUnmount: function(){
		$("#"+this.props.id).datetimepicker('destroy');
	},
	componentDidMount: function(){
		var today = new Date(); 
		this.state.value=this.toTimeStamp(today);
		if(this.props.selectedDate && this.props.selectedDate != ""){
			this.state.humanValue = this.props.selectedDate;
		} else if (this.props.selectedTimestamp && this.props.selectedTimestamp!==""){
			this.state.humanValue = this.toHumanDateFromTimestamp(this.props.selectedTimestamp);
		} else {
			this.state.humanValue=this.toHumanDate(today);
			this.props.onChange(this.toHumanDate(today));
		}
		
		var elem = this.state.humanValue.split(' ');
		this.state.humanValue = elem[0];
		this.setState(this.state);

		setTimeout(function(){
			this.createDateTimePicker(this.state.humanValue);
			this.checkDisabled();
		}.bind(this), 1000);
	},
	componentWillReceiveProps: function(nextProps) {
		if (nextProps.selectedDate && nextProps.selectedDate != this.state.humanValue) {
			var value = nextProps.selectedDate.split(' ');
			if (value.length >= 1) {
				this.setState({
					humanValue: value[0]
				});
				var id = '#' + this.props.id;
				$(id).datetimepicker('destroy');
				this.createDateTimePicker(value[0]);
			}
		}
	},
	createDateTimePicker: function(value) {
		var id = '#' + this.props.id;
		let defaultConf = {
			value: value,
			closeOnDateSelect: true,
			lang: ( currentInterface != 'undefined' && currentInterface != "" ? currentInterface : 'en'),
			dayOfWeekStart: 1,//Swedish weekday start from Monday
			onChangeDateTime: this.handleDateTimeChange,
			step: 5,
		};
		defaultConf.format = "Y/m/d";
		defaultConf.datepicker = true;
		defaultConf.timepicker = false;
		if(typeof this.props.timepicker != 'undefined' && this.props.timepicker == true){
			defaultConf.datepicker = false;
			defaultConf.timepicker = true;
			defaultConf.format = "Hi"; //`Hi` means HourMinute, actual time format `H:i` => Hour:Minute
			if(typeof this.props.timeFormat !== 'undefined' && this.props.timeFormat === ":"){
				defaultConf.format = "H:i";
			}
		}
		$(id).datetimepicker(defaultConf);
	},
	handleDateTimeChange: function(dp, $input) {
		var today = this.toHumanDate(new Date());
		var hmdate = $input.val();
		var valid = true;
		if (!this.props.allowPastDate && hmdate) {
			if (hmdate < today) {
				valid = false;
			} else {
				valid = true;
			}
		}

		if (valid) {
			if(typeof this.props.timepicker != 'undefined' && this.props.timepicker == true){
				this.setState({
					value: hmdate,
					humanValue: hmdate
				});
				this.props.onChange(hmdate, this.props.id);
			}else{
				hmdate = hmdate + ' 00:00:00';
				var tmdate = (new Date(hmdate)).getTime() / 1000;

				var timezoneDate = new Date();
				var timezoneOffset = timezoneDate.getTimezoneOffset();
				var timezoneOffset = timezoneOffset * 60; /* This is given in minutes, so we need to multiply by 60 */

				var now = new Date();
				now.setTime((tmdate - timezoneOffset) * 1000);

				this.setState({
					value: this.toTimeStamp(now),
					humanValue: this.toHumanDate(now)
				});
				this.props.onChange(this.toHumanDate(now));
			}
		}
	},
	toHumanDateFromTimestamp: function(timestamp){
		var date = new Date(timestamp*1000);
		return this.toHumanDate(date);
	},
	toHumanDate: function(dt){
		var dd = dt.getDate();
		var mm = dt.getMonth()+1;
		var yyyy = dt.getFullYear();
		if(dd<10) { dd='0'+dd }
		if(mm<10) { mm='0'+mm }
		return yyyy+'/'+mm+'/'+dd;
	},
	toTimeStamp: function(dt){
		var newDt =  new Date(dt).getTime() / 1000;
		return (newDt | 0);
	},
	handleOnChange: function(e){
		var inputval = e.target.value;
		$("#"+this.props.id).datetimepicker({value: inputval});

		// Get the closest valid date
		var hmdate = $("#"+this.props.id).datetimepicker('getValue').val();

		hmdate = hmdate + ' 00:00:00';
		var tmdate = (new Date(hmdate)).getTime() / 1000;

		var timezoneDate = new Date();
		var timezoneOffset = timezoneDate.getTimezoneOffset();
		var timezoneOffset = timezoneOffset * 60; /* This is given in minutes, so we need to multiply by 60 */

		var now = new Date();
		now.setTime((tmdate - timezoneOffset) * 1000);

		this.setState({
			value: this.toTimeStamp(now),
			humanValue: inputval
		});

		this.props.onChange(this.toHumanDate(now));
	},
	handleOnDelete: function(e){
		this.props.onDelete();
		this.state.humanValue = "None";
		this.state.valid = true;
		this.setState(this.state);
	},
	renderDeleteDueDate: function(){
		if(this.state.humanValue != "None"){
			return(
				<span className="remove-duedate" id=""><i onClick={this.handleOnDelete} className="glyphicon glyphicon-remove" ></i></span>
			);
		}
	},
	render: function(){
		var pointerEvent = 'auto';
		if(this.props.type == "link"){
			return (<span><a id={this.props.id}>{this.state.humanValue==""?I("None"):this.state.humanValue}</a>{this.renderDeleteDueDate()}</span>);
		}
		if(this.props.type == "linkNoDel"){
			return (<span><a id={this.props.id}>{this.state.humanValue==""?I("None"):this.state.humanValue}</a></span>);
		}
		if(this.props.disable && this.props.disable == true){
			pointerEvent = 'none';
		}
		return (
		<span id={this.props.id} className="input-group input-group-sm" style={{display: 'inline-block', pointerEvents: pointerEvent}} >
			<input name={this.props.id} id={this.props.id} style={{float: 'left', width: 80}} className="form-control" placeholder="Start date" onChange={this.handleOnChange} value={this.state.humanValue} disabled={this.props.disable}/>
			<span className="input-group-addon" id="basic-addon1"><i className="fa fa-calendar"></i></span>
		</span>
		);
	}
});
export default Calendar;