import React from 'react';
import createReactClass from 'create-react-class';

export default createReactClass({
	displayName:"CentionHotKeys",
	getInitialState: function() {
		return{
			display: 'none',
			hotKeyActive: false,
			params : [],
			helper: false,
			hotkeys: []
		}
	},
	getDefaultProps: function() {
		return {
			hotCtrlKeys: '',
			activated: false
		}
	},
	setupCommonKeys: function(){
		var commonKeys = [
			{key : "0", name: I("Logout"), type : "link", action : "logout"},
			{key : "1", name: I("All Errands"), type : "link", action : "/ng/v5"},
			{key : "2", name: I("My Errands"), type : "link", action : "/ng/v5"},
			{key : "3", name: I("Go To Search"), type : "link", action : "#search"},
			{key : "4", name: I("Go To Overview"), type : "link", action : "/ng/workflow#errands/overview"},
			{key : "9", name: I("Go To Profile"), type : "link", action : "/ng/admin#agents/edit"},
			{key : "H", name: I("Press A Key Combination"), type : "handler", action : this.helperAction}
		];
		return commonKeys;
	},
	componentWillMount: function(){
		document.addEventListener("keydown", this.handleKeyDown, false);
	},
	componentWillUnmount: function() {
		document.removeEventListener("keydown", this.handleKeyDown, false);
	},
	handleKeyDown: function(e){
		var metaKeyPressed = e.metaKey;
		var ctrlKeyPressed = e.ctrlKey;
		var altKeyPressed = e.altKey;
		var shiftKeyPressed = e.shiftKey;
		//define key (for cross browser compatibility)
		var key = String.fromCharCode(e.which);
		if(e.which == 27){
			key = "Escape";
		}else if (e.which == 39){
			key = "Right";
		}else if (e.which == 37){
			key = "Left";
		}else if (e.which == 13){
			key = "Enter";
		}else{
			key = String.fromCharCode(e.which);
		}
		var commonKeys = this.setupCommonKeys();
		var pageKeys = this.props.keys;
		var allKeys = $.merge(commonKeys, pageKeys);
		var keyList = [];
		allKeys.map(function(hotkey,i){
				keyList.push(hotkey.key);
		}.bind(this));
		var listControlKey = [];
		if(this.props.hotCtrlKeys != null) {
			listControlKey = (this.props.hotCtrlKeys.toLowerCase()).split("+");
			listControlKey = listControlKey.slice(0,-1);
		}
		var keyExist = $.inArray(key, keyList);
		if(!this.state.hotkeyDialog){
			if(keyExist != -1){
				var updatedCKey = [{key: "meta", valid: false},{key: "ctrl", valid: false}, {key: "alt", valid: false}, {key: "shift", valid: false}];
				updatedCKey.map(function(ckey, i){
						var ck = ckey.key+"KeyPressed";
						if(eval(ck)){
							ckey.valid = true;
						}
				});
				function checkValid (key){
					return key.valid == true;
				}
				function checkInvalid (key){
					return key.valid == false;
				}
				var validKey = [];
				var invalidKey = [];
				updatedCKey.map(function(cK,i){
					var exist = $.inArray(cK.key, listControlKey);
					if(exist != -1){
						validKey.push(cK);
					}else{
						invalidKey.push(cK);
					}
				},this);
				var valid = validKey.every(checkValid);
				var invalid = invalidKey.every(checkInvalid);
				var keyObj = [];
				if(valid && invalid){
					allKeys.map(function(key, i){
						var charKey = key.key;
						if(charKey == key){
							keyObj.push(key);
						}
					});
					if(this.safetyCheck()){
						this.handleShortCut(key, allKeys, keyList, true);
					}
				}
			}
		}else{
			if(this.safetyCheck()){
				this.handleShortCut(key, allKeys, keyList, false);
			}
		}
	},
	handleShortCut: function(key, keyObj, keyList, main) {
		keyObj.map(function(keyO, i){
			if(keyO.key == key){
				this.state.params.currentKey = keyO.key;
				this.state.params.actionTxt = keyO.name;
				this.state.params.action = keyO.action;
				this.state.params.type = keyO.type;
				this.state.params.advice = I("(Press Enter to Confirm, Escape to Cancel)");
				this.state.hotkeys = keyList;
			}
		},this);
		if(main){
			this.openDialog();
			this.state.helper = false;
			if(key == "H"){
					this.state.helper = true;
			}
		}else{
			this.state.helper = true;
		}
		var hotkeyDialog = $(".wfHotkeyDialog").is(":visible");
		if(hotkeyDialog){
			this.state.hotkeyDialog = true;
				if(key == "Enter"){
					if(this.state.params.action){
							this.handleEnter();
					}
					this.closeDialog();
				}
				if(key == "Escape" || key == "Esc"){
					this.closeDialog();
				}
				keyObj.map(function(keyO, i){
					if(keyO.key.toLowerCase() == key){
						this.state.params.currentKey = keyO.key;
						this.state.params.actionTxt = keyO.name;
						this.state.params.action = keyO.action;
						this.state.params.type = keyO.type;
						this.state.params.advice = I("(Press Enter to Confirm, Escape to Cancel)");
					}
				},this);
		}else{
			this.state.hotkeyDialog = false;
		}
		this.setState(this.state);
	},
	handleEnter: function(){
		if(this.state.params.type == "link"){
			location.href=this.state.params.action;
		}else{
			this.props.onEnter(this.state.params.action());
			this.closeDialog();
		}
		this.state.hotKeyActive = false;
	},
	helperAction: function(){
		this.closeDialog();
	},
	openDialog: function() {
		$('.wfHotkeyDialog').show();
	},
	closeDialog: function() {
		$('.wfHotkeyDialog').hide();
	},
	safetyCheck: function() {
		/* This will check if cursor focused on any textfield to avoid conflict for example, SHIFT + Character */
		var whichFocused;
		try {
			whichFocused = document.activeElement.tagName;
		}catch(error) {
			whichFocused = "BODY";
		}
		if(whichFocused == "INPUT"){
			if(document.activeElement.type == "text" || document.activeElement.contentEditable == "true")
				return false;
		}else {
			return true;
		}
	},
	render: function(){
		var shortcut = "";
		var style = {
			display: this.state.display
		};
		if(this.state.params.currentKey == "H"){
			shortcut = "☛";
		}
		else{
			var key = this.state.params.currentKey;
			shortcut = this.props.hotCtrlKeys+''+key;
		}
		return(
			<div style={style} className="wfHotkeyDialog" id={this.props.id}>
				<div style={{ZIndex:'12px'}}>
					<span className="Hotkeys_Shortcut" style={{color:'#FFF', fontSize:'24px'}}>{shortcut}</span> <br /><br />
					<b><span className="Hotkeys_Action" style={{color:'#FFF', fontSize:'15px'}}>{this.state.params.actionTxt}</span></b><br /><br />
						<span className="Hotkeys_Advice" style={{color:'rgb(255, 255, 255)', fontSize:'10px'}}>{this.state.params.advice}</span> <br /><br />
					<span className="Hotkeys_Available" style={{color:'#888', fontSize:'12px', display: 'block'}} >{this.state.helper ? "[ " +this.state.hotkeys.join(" ")+ " ]" : " "}</span> <br />
				</div>
			</div>
		);
	}
});