import { Observable, Subject } from 'rxjs'

let websocketConnectionStateObserver = null
const websocketConnectionStateSubject = new Subject()

const listenWebsocketConnectionState = () => new Observable(observer => {
  websocketConnectionStateObserver = state => observer.next(state)
  return () => { websocketConnectionStateObserver = null }
}).subscribe(websocketConnectionStateSubject)

export default listenWebsocketConnectionState

export const websocketConnectionState = state => {
	if (websocketConnectionStateObserver){
		websocketConnectionStateObserver(state)
	}
}

export const subscribeWebsocketConnectionStateSubject = observer => {
  return websocketConnectionStateSubject.subscribe(observer)
}
