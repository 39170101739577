/* Note: A component for listing and be able to reposition each item to any position by drag */
import React from 'react';
import createReactClass from 'create-react-class';
var SortableList = createReactClass({
	getInitialState: function() {
		return {data: this.props.data};
	},
	componentWillReceiveProps: function(nextProps) {
		if(nextProps.data != this.state.data){
			this.setState({data:nextProps.data});
		}
	},
	dragStart: function(e) {
		var isMSEdge = false;
		if (/Edge\/\d./i.test(window.navigator.userAgent)){
			isMSEdge = true;
		}
		this.dragged = e.currentTarget;
		e.dataTransfer.effectAllowed = 'move';
		var userAgent = window.navigator.userAgent,
		msie = userAgent.indexOf('MSIE '),
		trident = userAgent.indexOf('Trident/');
		if (msie > 0 || trident > 0) {
			e.dataTransfer.setData('Text', e.currentTarget.id);
		}else {
			if(isMSEdge){
				var itemsList = e.dataTransfer.items;
				itemsList.add(e.currentTarget);
			}else{
				e.dataTransfer.setData('text/html', e.currentTarget);
			}
		}
	},
	//added for IE
	dragEnter: function(e){
		e.preventDefault();
	},
	dragEnd: function(e) {
		if (e.preventDefault) {
			e.preventDefault();
		}
		this.dragged.style.display = "block";
		if(this.dragged.parentNode){
			$(".sortable li.placeholder").remove();
		}
		var data = this.state.data;
		var from = Number(this.dragged.dataset.id);
		var to = Number(this.over.dataset.id);
		if(from < to) to--;
		if(this.nodePlacement == "after") to++;
		data.splice(to, 0, data.splice(from, 1)[0]);
		this.setState({data: data});
		var name = e.target.innerText;
		var example = name.substr(name.indexOf(":") + 1);
		example = example.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
		if(this.props.dragEnd){
			this.props.dragEnd(example, to, e.target.id);
		}
	},
	dragOver: function(e) {
		if (e.preventDefault) {
			e.preventDefault();
		}
		this.dragged.style.display = "none";
		if(e.target.className == "placeholder") return;
		this.over = e.target;
		if(e.target.dataset.dragTarget){
			this.nodePlacement = "after";
			e.target.parentNode.insertBefore(this.props.placeholder, e.target);
		}
		var relY = e.clientY - this.over.offsetTop;
		var height = this.over.offsetHeight / 2;
		var parent = e.target.parentNode;
		if(relY > height) {
			this.nodePlacement = "after";
			if(e.target.dataset.dragTarget){
				parent.insertBefore(this.props.placeholder, e.target.nextElementSibling);
			}
		}else if(relY < height) {
			this.nodePlacement = "before";
			parent.insertBefore(this.props.placeholder, e.target);
		}
	},
	handleRemove: function(e){
		var index = e.currentTarget.id;
		var latestData = this.state.data.slice();
		latestData.splice(index, 1);
		this.setState({data: latestData});
		if(this.props.onDelete){
			this.props.onDelete(index);
		}
	},
	render: function() {
		return <ul className="sortable" onDragOver={this.dragOver} style={{paddingLeft: '0px', paddingTop: '20px'}}>
		{this.state.data.map(function(item, i) {
		return (
			<li
				data-id={i}
				key={i}
				id={item.Id}
				draggable="true"
				onDragEnd={this.dragEnd}
				onDragStart={this.dragStart}
				onDragEnter={this.dragEnter}
				data-drag-target="true"
			>
			{item.Name} <span className="remove-item" onClick={this.handleRemove} data-id={item.Id} id={i} name={item.Id}>  <i className="fa fa-times"></i></span>
			</li>
		)
			}, this)}
		</ul>
	}
});
export default SortableList;
