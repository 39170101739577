import React from 'react';
import createReactClass from 'create-react-class';

import ErrandThreadCheckbox from './ErrandThreadCheckbox';
import ErrandHelper from './ErrandHelper';

var Attachments = createReactClass({
	render: function(){
		if (this.props.paths.length == 0) {
			return null;
		}
		var base, i, attachments = [];
		for (i=0; i<this.props.paths.length; i++) {
			var thePath = this.props.paths[i];
			var base = "";
			if(this.props.paths[i].match(/###/)){
				var moreThanOne = splitUrl(this.props.paths[i])
				thePath = moreThanOne[0];
				base = moreThanOne[1];
			} else {
				base = basename(this.props.paths[i])
			}
			attachments.push(<a key={i} id={this.props.id+"_"+this.props.errandId}
				className="attachmentLink"
				href={thePath}>
					{base}
				</a>
			);
		}
		return <span><b>{I('Attachments')}</b> {attachments}</span>;
	}
});

function splitUrl(path){
	if (!path) {
		return ["",""];
	}
	return path.split("###");
}

function basename(path) {
	if (!path) {
		return "";
	}
	return path.replace(/.*\//, '');
}

var ErrandThreadList = createReactClass({
	componentDidMount: function() {},
	handleChange: function(event) {},
	render: function() {
		var data = JSON.stringify(this.props.list);
		return (
			<input type="hidden" onChange={this.handleChange} value={data}
				className="errandThreadList" id={this.props.id} />
		);
	}
});

var ErrandThreadHistory = createReactClass({
	getInitialState: function(){
		var end = 0;
		if(this.props.dataSrc && this.props.dataSrc.length > 0) {
			if(this.props.dataSrc.length > 3) {
				end = 3
			} else {
				end = this.props.dataSrc.length;
			}
		}
		this.viewItems = 0;
		return {
			section: null,
			end: end
		}
	},
	getDefaultProps: function () {
			return {
				sortBy: 1
			}
		},
	componentWillReceiveProps: function(nextProps) {
		if(nextProps.sortBy == 1) {
			this.sortHistory(true);
		}else {
			this.sortHistory(false);
		}
	},
	componentDidMount: function(){},
	sortHistory: function(reverse) {
		this.props.dataSrc.sort(function(a, b) {
			if(reverse) {
				if ( a.created < b.created )
					return 1;
				if ( a.created > b.created )
					return -1;
				return 0;
			}else {
				if ( a.created > b.created )
					return 1;
				if ( a.created < b.created )
					return -1;
				return 0;
			}
		});
	},
	viewErrandAction: function(hs, hid){
		$('.thread-subheader#errandHistoryActions_'+ hs + hid).toggle('fade');
	},
	openErrandHistoryPopup: function( history ){
		var url = urlForApplicationAction('errands/popups/history/-/thread/' + history.threadId + '/errand/' + history.eid + (history.userId && history.authToken ? '/auth/' + history.userId + '/' + history.authToken: ''));
		var jqiFrame = $('<iframe name="jqiframe" height="500" width="730" border="0" scrolling="yes" id="jqiframe" src="'+ url +'"></iframe>');
		$( "#dialogErrandHistoryPopup" ).html("");
		$( "#dialogErrandHistoryPopup" ).attr('title', I('Errand History Preview'));
		$( "#dialogErrandHistoryPopup" ).append(jqiFrame).dialog({
				width: 750,
				height: document.getElementById('jqiframe').contentWindow.outerHeight,
				dialogClass: "jqiFrame",
				modal: true
		});
	},
	questionThread: function(){
		var qthread = null;

		for( var i = 0; i < this.props.dataSrc.length; i++){
			if( this.props.dataSrc[i].style == "thread-question"){
				var history = this.props.dataSrc[0];
				var historyActions = [];
				var historyTagsList = [];
				var historySection = this.props.section;
				this.renderedErrands.push(history.eid);
				history.actions.forEach(function(a){
						historyActions.push(<div key={a.id}>
								<div style={{marginLeft: '5px'}}>-
									<span style={{fontSize: '90%', color: a.color}}>
										<img src={a.icon} style={{verticalAlign: 'middle'}} />&nbsp; {a.notes}
									</span>
								</div>
							</div>);
				}.bind(this));
				history.tagsList.forEach(function(a){
						historyTagsList.push(<div key={a.id}>
								<span className="tag errandtags">
									{a.desc}
								</span>
							</div>);
				}.bind(this));
				var threadMessage = "";
				var excerpt_full = history.message.replace(/(?:\r\n|\r|\n)/g, '<br />');
				var excerpt_more = history.message.substring(0,200);

				$('#editableBody img').map(function(index){
					var img = $(this);
					var src = this.src;
					var newImg = new Image();
					var previewImgWidth;
					var previewImgHeight;
					var previewImageContentWidth;
					newImg.src = src;
					newImg.onload = function(){
						var imgHeight = newImg.height;
						var imgWidth = newImg.width;
						var winHeight = $(window).height();
						var winWidth = $(window).width();
						if(!img.closest(".imgPreview").length > 0){
							var a = $('<a/>').attr({class: "imgPreview", "data-img": src});
							img.wrap(a);
						}
						var imgModalDialog = $('#imgPreviewModalDialog');
						$('.imgPreview').click(function(){
							$('#previewImage').attr('src', $(this).data('img'));
							$('#imgPreviewModal').modal({show:true});
							if(imgWidth > winWidth ){
								previewImgWidth = (winWidth - 10)+"px";
								previewImgHeight = (winHeight - 100)+"px";
								previewImageContentWidth = "100%";
							}else{
								previewImgWidth = (imgWidth+20)+"px";
								previewImgHeight = (imgHeight+20)+"px";
								previewImageContentWidth = "115%";
							}
							$('#imgPreviewModalDialog').css({width:previewImgWidth,height:previewImgHeight});
							$('#imgPreviewModalDialog').css('max-height', winHeight * 0.7);
							$('#imagePreviewModalBody > img').attr({ height: previewImgHeight });
							$('#imgPreviewContent').css({width: previewImageContentWidth});
						});
					}
				});
				if( !history.socialmedia && excerpt_full.length > 200){
					threadMessage = this.threadBody(history.eid, excerpt_more, history.htmlmessage, history.message);
				}else if( history.socialmedia && history.htmlmessage != ""){
					excerpt_more = history.htmlmessage.replace(/<[^>]*>?/g, '').substring(0, 50);
					threadMessage = this.threadBody(history.eid, excerpt_more, history.htmlmessage, history.message);
				}else{
					threadMessage = <div className='thread-body'>
										<p dangerouslySetInnerHTML={{__html: ErrandHelper.sanitizeHtml(history.htmlmessage ? history.htmlmessage : history.message)}} />
									</div>;
				}
				var r = replacePM(history.subject.substring(0,150));
				var qthreadClass = "thread-question";
				if(this.props.forwardToExternalMode) {
					qthreadClass += " col-md-11";
				}
				qthread = <span key={history.displayId}>
							{this.hiddenColAnchor}
							<div className={qthreadClass}>
							<p className="thread-header clearfix">
								{this.historyHead(history)}
								<span className="align-right">
									<a className="underlink">
										<span id={"errandHistoryActionsToggleButton_" + historySection + history.eid} onClick={this.viewErrandAction.bind(null, historySection, history.eid)} >{I('Show actions')}</span>
									</a>&nbsp;{history.created}
								</span>
							</p>
							<div style={{ display:'none', textAlign: 'right', height: '100px', overflow:'auto'}} id={"errandHistoryActions_" + historySection + history.eid} className="thread-subheader" >{historyActions}</div>
							<div className='thread-attachments incoming-question'>
								<b>{I('Channel')}</b> {history.channel} <Attachments id="incoming" errandId={history.eid} paths={history.incoming}/>
							</div>
							{threadMessage}
							{this.threadAnswer(history.answer, history.htmlanswer, history.eid, historySection, history.outgoing, history.cc, history.bcc, history.forwardedTo)}
							<div className={history.tagsList.length > 0  ? "historyTagsList clearfix" : "hidden"}><b>{I('Tags')}</b> {historyTagsList}</div>
							</div>
						</span>
				this.viewItems++;
				break;
			}
		}

		return qthread;
	},
	historyHead: function(hstry) {
		return (
			<b>
				{I('Errand')} {hstry.displayId}:&nbsp;
				<span dangerouslySetInnerHTML={{__html:
					replacePM(hstry.subject.substring(0, 150)) + '...'}} />
			</b>
		);
	},
	haveHtmlTagAnswer: function(msg){
		var htmlRegx = /<\/?[\w\s.*\/\?]+>/gi;
		if( msg != "")
			return htmlRegx.test(msg);
		return false;
	},
	renderThreadEmailInfo: function(cc, bcc, forwardedTo) {
		var emailInfo = [];
		if(typeof cc !== "undefined" && cc != "") {
			emailInfo.push(<span key={"ccThread"}><b>CC:</b> {cc} <br /></span>);
		}
		if(typeof bcc !== "undefined" && bcc != "") {
			emailInfo.push(<span key={"bccThread"}><b>BCC:</b> {bcc} <br /></span>);
		}
		if(typeof forwardedTo !== "undefined" && forwardedTo != "") {
			emailInfo.push(<span key={"forwardedThread"}><b>Forwarded to external:</b> {forwardedTo} <br /></span>);
		}
		if(emailInfo.length != 0) {
			return (
					<div className="thread-emails">{emailInfo}</div>
				)
		}
		return "";
	},
	threadAnswer: function(av,htmlMsg, eid, section, attachment, cc, bcc, forwardedTo){
		if(av.length > 0 || htmlMsg != ""){
			var answer = av;
			var truncatedAnswer = "";
			if(htmlMsg != "" && this.haveHtmlTagAnswer(htmlMsg)){
				answer = htmlMsg;
			}
			var answerBoxClass = "";
			if(answer) {
				answerBoxClass = "thread-answer-box";
				if(this.props.forwardToExternalMode) {
					answerBoxClass += " col-md-12";
				}
			}
			var showMore = "";
			var showMoreSpan = 'block';
			var section = this.props.section;
			var moreLink = <a id={"errand_answer_text_"+ section + eid} style={{cursor: 'pointer', color: '#4C9DCC'}} onClick={this.revealedAnswer.bind(null, section, eid)}>
								{I("« more")}
							</a>;
			if( this.haveHtmlTagAnswer(answer)){
				showMoreSpan = 'none';
				truncatedAnswer = ErrandHelper.truncateHtml(answer,100);
				showMore = <span><span style={{display: 'inline-block'}} id={"header_errand_" + section + eid} dangerouslySetInnerHTML={{__html: ErrandHelper.sanitizeHtml(truncatedAnswer)}}></span>{moreLink}</span>;
			}else if( answer.length >= 100){
				showMoreSpan = 'none';
				truncatedAnswer = answer.substring(0, 100) + "...";
				showMore = <span><span style={{display: 'inline-block'}} id={"header_errand_" + section + eid}>{truncatedAnswer}</span>{moreLink}</span>;
			}else{
				showMoreSpan = 'block';
			}
			return(<div className={answerBoxClass}>
					{this.renderThreadEmailInfo(cc, bcc, forwardedTo)}
					<div className='thread-attachments'>
						<Attachments id="outgoing" errandId={eid} paths={attachment}/>
					</div>
					<div className='thread-answer-content'><span id={"errand_answer_"+ section + eid} style={{display: showMoreSpan}} dangerouslySetInnerHTML={{__html: ErrandHelper.sanitizeHtml(answer)}} />
					{showMore}</div>
				</div>);
		}else{
			return(<span></span>);
		}
	},
	revealedAnswer: function( section, eid ){
		if( document.getElementById("errand_answer_" + section + eid).style.display == 'none'){
			document.getElementById("header_errand_" + section + eid).style.display = 'none';
			document.getElementById("errand_answer_" + section + eid).style.display = 'block';
			document.getElementById("errand_answer_text_" + section + eid).innerHTML = I("» less");
		}
		else{
			document.getElementById("header_errand_" + section + eid).style.display = 'block';
			document.getElementById("errand_answer_" + section + eid).style.display = 'none';
			document.getElementById("errand_answer_text_" + section + eid).innerHTML = I("« more");
		}
	},
	readMore: function(v, eid){
		var parentDiv = "#ThreadBody_" + eid;
		if(v == 0) {
			$(parentDiv + ' .readexcerpt').hide();
			$(parentDiv + ' .readfull').show();
			$(parentDiv + ' .readmore').hide();
			$(parentDiv + ' .readless').show();
		} else {
			$(parentDiv + ' .readexcerpt').show();
			$(parentDiv + ' .readfull').hide();
			$(parentDiv + ' .readmore').show();
			$(parentDiv + ' .readless').hide();
		}
	},
	contains: function(a, obj) {
		for (var i = 0; i < a.length; i++) {
			if (a[i] === obj) {
				return true;
			}
		}
		return false;
	},
	threadBody: function(errandId, shortMsg, htmlMsg, message){
		var tbody = <div className='thread-body' id={"ThreadBody_" + errandId}>
						<div className="readfull" style={{ display: "none"}}><p dangerouslySetInnerHTML={{__html: ErrandHelper.sanitizeHtml(htmlMsg ? htmlMsg : message)}} /></div>
						<div className="readexcerpt"><p dangerouslySetInnerHTML={{__html: ErrandHelper.sanitizeHtml(shortMsg)}} /></div>
						<span className="readmore" onClick={this.readMore.bind(null, 0, errandId)}>{I('« more')}</span>
						<span className="readless" onClick={this.readMore.bind(null, 1, errandId)} style={{display:"none"}}>{I('» less')}</span>
					</div>;
		return tbody;
	},
	replyThread: function(start, end){
		var rthread = [];
		var hasStyle=["thread-reply","thread-question"];
		for( var i = start; i < end; i++){
			if(!this.props.dataSrc[i])
				continue;
			if(hasStyle.indexOf(this.props.dataSrc[i].style) >= 0){
				var history = this.props.dataSrc[i];
				var historySection = this.props.section;
				var historyActions = [];
				var historyTagsList = [];
				if( this.contains(this.renderedErrands, history.eid) )
					continue;
				this.renderedErrands.push(history.eid);
				history.actions.forEach(function(a){
						historyActions.push(<div key={a.id}>
								<div style={{marginLeft: '5px'}}>-
									<span style={{fontSize: '90%', color: a.color}}>
										<img src={a.icon} style={{verticalAlign: 'middle'}} />&nbsp; {a.notes}
									</span>
								</div>
							</div>);
				}.bind(this));
				history.tagsList.forEach(function(a){
						historyTagsList.push(<div key={a.id}>
								<span className="tag errandtags">
									{a.desc}
								</span>
							</div>);
				}.bind(this));
				var threadMessage = "";
				var excerpt_full = history.message.replace(/(?:\r\n|\r|\n)/g, '<br />');
				var excerpt_more = history.message.substring(0,200);
				if( !history.socialmedia && excerpt_full.length > 200){
					threadMessage = this.threadBody(history.eid, excerpt_more, history.htmlmessage, history.message);
				}else if( history.socialmedia && history.htmlmessage != ""){
					excerpt_more = history.htmlmessage.replace(/<[^>]*>?/g, '').substring(0, 50);
					threadMessage = this.threadBody(history.eid, excerpt_more, history.htmlmessage, history.message);
				}else{
					threadMessage = <div className='thread-body'>
										<p dangerouslySetInnerHTML={{__html: ErrandHelper.sanitizeHtml(history.htmlmessage ? history.htmlmessage : history.message)}} />
									</div>;
				}
				if(this.props.forwardToExternalMode){
					var check = this.props.qhistoryCheckboxes[history.eid];
					var dis = false;
					var threadClass = "thread-reply col-md-11";
					var subClass = "errandHistoryCheckSub col-md-1";
					if(this.props.qhistoryCheckboxes.all) {
						check = true;
						dis = true;
					}
					if(check) {
						threadClass += " threadSelected";
						subClass += " threadSelected";
					}
					rthread.push(<span key={history.displayId+"-active"}>
						<div className="forwardToExternalSet">
							<div className={subClass}>
								<ErrandThreadCheckbox name='threadname'
									class="errandThreadCheckbox" checked={check}
									value={history.eid} disabled={dis} id={history.eid}
									onChckBoxChange={this.props.onChckBoxChange} />
							</div>
							<div className={subClass}></div>
							<div id={"errandHistoryActions_"+ historySection + history.displayId} className={threadClass}>
								<p className="thread-header clearfix">
									{this.historyHead(history)}
									<span className="align-right">
										<a className="underlink">
											<span id={"errandHistoryActionsToggleButton_" + historySection + history.eid} onClick={this.viewErrandAction.bind(null, historySection, history.eid)} >{I('Show actions')}</span>
										</a>&nbsp;{history.created}
									</span>
								</p>
								<div style={{ display:'none', textAlign: 'right', height: '100px', overflow:'auto'}} id={"errandHistoryActions_" + history.eid} className="thread-subheader" >{historyActions}</div>
								{threadMessage}
								{this.threadAnswer(history.answer, history.htmlanswer, history.eid, historySection, history.outgoing, history.cc, history.bcc, history.forwardedTo)}
								<div className={history.tagsList.length > 0  ? "historyTagsList clearfix" : "hidden"}><b>{I('Tags')}</b> {historyTagsList}</div>
							</div>
						</div>
						</span>);
				}else{
					rthread.push(<span key={history.displayId}>
							<div className="thread-reply">
							<p className="thread-header clearfix">
								{this.historyHead(history)}
								<span className="align-right">
									<a className="underlink">
										<span id={"errandHistoryActionsToggleButton_" + historySection + history.eid} onClick={this.viewErrandAction.bind(null, historySection, history.eid)} >{I('Show actions')}</span>
									</a>&nbsp;{history.created}
								</span>
							</p>
							<div style={{ display:'none', textAlign: 'right', height: '100px', overflow:'auto'}} id={"errandHistoryActions_" + historySection + history.eid} className="thread-subheader" >{historyActions}</div>
							<div className='thread-attachments incoming-question'>
								<b>{I('Channel')}</b> {history.channel} <Attachments id="incoming" errandId={history.eid} paths={history.incoming}/>
							</div>
							{threadMessage}
							{this.threadAnswer(history.answer, history.htmlanswer, history.eid, historySection, history.outgoing, history.cc, history.bcc, history.forwardedTo)}
							<div className={history.tagsList.length > 0  ? "historyTagsList clearfix" : "hidden"}><b>{I('Tags')}</b> {historyTagsList}</div>
							</div>
						</span>);
				}
				this.viewItems++;
			}
		}
		return rthread;
	},
	render: function(){
		var history = "";
		var moreHistoryClass = "buttonShowMoreHistory";
		this.hiddenColAnchor = null;
		if(this.props.forwardToExternalMode) {
			this.hiddenColAnchor = <div className='col-md-1 hiddenColumnAnchor'></div>;
			moreHistoryClass = moreHistoryClass + " col-md-11";
		}
		this.viewItems = 0;
		if(this.props.dataSrc && this.props.dataSrc.length > 0) {
			// track to avoid history thread contains current errand
			this.renderedErrands = [];
			var qthread = this.questionThread();
			var showMoreStyle = {};
			var repliesThread = [];
			var hasMore = false;

			repliesThread.push(this.replyThread(0, this.state.end));
			if(this.viewItems < this.props.dataSrc.length &&
				this.state.end < this.props.dataSrc.length) {
				hasMore = true;
			}
			if(!hasMore) {
				showMoreStyle.display = 'none';
			}
			var showMore = (
				<div style={showMoreStyle} onClick={this.onAction}
					className={moreHistoryClass}>
					<span className="fa fa-plus"></span>
				</div>
			);
			return (
				<div>
					{qthread}
					{repliesThread}
					{this.hiddenColAnchor}
					{showMore}
					<div id="dialogErrandHistoryPopup"></div>
					<ErrandThreadList id="errandThreadList"
						list={this.props.dataSrc} />
				</div>
			);
		} else {
			return <div></div>;
		}
	},
	onAction: function(e){
		if(this.props.dataSrc.length > 0){
			if(this.props.dataSrc.length - this.viewItems >= 3 &&
				this.state.end+3 < this.props.dataSrc.length) {
				this.setState({end: this.state.end+3});
			} else {
				this.setState({end: this.props.dataSrc.length});
			}
		}
	}
});
export default ErrandThreadHistory;
