import React from 'react';
import createReactClass from 'create-react-class';
var LibraryCkeditor = createReactClass({
	editor: null,
	getConfig: function() {
		return {
			skin: 'cention',
			enterMode: CKEDITOR.ENTER_DIV,
			shiftEnterMode: CKEDITOR.ENTER_DIV,
			forcePasteAsPlainText: false,
			allowedContent: true,
			ignoreEmptyParagraph: false,
			pasteFromWordPromptCleanup: false,
			pasteFromWordRemoveFontStyles: false,
			pasteFromWordRemoveStyles: false,
			removeFormatAttributes: false,
			basicEntities: true,
			entities: true,
			entities_latin: false,
			entities_greek: false,
			entities_processNumerical: false,
			fillEmptyBlocks: function (element) {
				return true;
			},
			disableNativeSpellChecker: false,
			browserContextMenuOnCtrl: true,
			colorButton_enableMore: false,
			resize_dir: 'both',
			fontSize_sizes:
				'8/8pt;' +
				'9/9pt;' +
				'10/10pt;' +
				'11/11pt;' +
				'12/12pt;' +
				'14/14pt;' +
				'16/16pt;' +
				'18/18pt;' +
				'20/20pt;' +
				'22/22pt;' +
				'24/24pt;' +
				'26/26pt;' +
				'28/28pt;' +
				'36/36pt;' +
				'48/48pt',
			font_names:
				'Arial/Arial, Helvetica, sans-serif;' +
				'Arial black;' +
				'Calibri/Calibri, sans-serif;' +
				'Comic Sans MS/Comic Sans MS, cursive;' +
				'Courier New/Courier New, Courier, monospace;' +
				'Georgia/Georgia, serif;' +
				'Lucida Console/Lucida Console, monospace;' +
				'Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;' +
				'Tahoma/Tahoma, Geneva, sans-serif;' +
				'Times New Roman/Times New Roman, Times, serif;' +
				'Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;' +
				'Verdana/Verdana, Geneva, sans-serif',
			toolbar_OneRow: [
				[ 'Bold', 'Italic', 'Underline', 'Strike' ],
				[ 'NumberedList', 'BulletedList' ],
				[ 'Indent', 'Outdent', 'Blockquote' ],
				[ 'JustifyLeft', 'JustifyCenter', 'JustifyRight' ],
				[ 'Link', 'Image', 'Youtube', 'HorizontalRule' ], [ 'PasteFromWord' ],
				[ 'Font' ], [ 'FontSize' ],
				[ 'TextColor', 'BGColor' ],
				[ 'SpellCheckerLanguage' ], [ 'SpellChecker' ], ['Library'], [ 'Resize' ]
			],
			toolbar: 'OneRow',
			plugins:
				'basicstyles,' +
				'blockquote,' +
				'clipboard,' +
				'colorbutton,' +
				'contextmenu,' +
				'enterkey,' +
				'font,' +
				'horizontalrule,' +
				'htmlwriter,' +
				'indent,' +
				'justify,' +
				'link,' +
				'list,' +
				'magicline,' +
				'pastetext,' +
				'showborders,' +
				'tab,' +
				'toolbar,' +
				'undo,' +
				'wysiwygarea,' +
				'cention_dragimage,' +
				'cention_spellcheck,' +
				'cention_image,' +
				'pastefromword,' +
				'resize',
			'extraPlugins': '',
			language: 'en',
			height: '250',
			spellCheckLanguages: []
		};
	},
	getDefaultProps: function() {
		return {
			showYoutube: false,
			fontFamily: 'Verdana',
			fontSize: '12pt',
			spellCheckLanguages: [],
			fileArchiveImages: [],
			data: '',
			dataId: 0
		};
	},
	componentDidMount: function() {
		this.createEditor(this.props);
	},
	componentWillReceiveProps: function(nextProps) {
		if (nextProps.showYoutube != this.props.showYoutube) {
			this.editor.destroy();
			this.createEditor(nextProps);
		} else if (nextProps.fontFamily != this.props.fontFamily) {
			this.editor.destroy();
			this.createEditor(nextProps);
		} else if (nextProps.fontSize != this.props.fontSize) {
			this.editor.destroy();
			this.createEditor(nextProps);
		} else if (nextProps.spellCheckLanguages.length != this.props.spellCheckLanguages.length) {
			this.editor.destroy();
			this.createEditor(nextProps);
		} else if (nextProps.dataId != this.props.dataId) {
			this.editor.setData(nextProps.data);
		}
	},
	shouldComponentUpdate: function(nextProps, nextState) {
		return false;
	},
	render: function() {
		return (<div><textarea id={this.props.name} /></div>);
	},
	createEditor: function(props) {
		window.CKEDITOR_BASEPATH = location.origin + '/ng/vendor/ckeditor/';

		CKEDITOR.addCss('body { margin-left: 5px; margin-right: 5px; margin-top: 3px; margin-bottom: 3px; }');
		CKEDITOR.addCss('p { margin-top: 0; }');
		CKEDITOR.addCss('.spellchecker-word-highlight { color: red; cursor: pointer; border-bottom: 1px dotted red; }');

		var config = this.getConfig();

		if (currentInterface) {
			config.language = currentInterface;
		}
		if (props.showYoutube) {
			config.extraPlugins = 'youtube';
		}
		if (props.fontFamily) {
			CKEDITOR.addCss('.cke_editable { font-family: ' + props.fontFamily + '; }');
			config.font_defaultLabel = props.fontFamily;
		}
		if (props.fontSize) {
			CKEDITOR.addCss('.cke_editable { font-size: ' + props.fontSize + '; }');
			config.fontSize_defaultLabel = props.fontSize;
		}
		if (props.spellCheckLanguages) {
			config.spellCheckLanguages = props.spellCheckLanguages;
		}

		this.editor = CKEDITOR.replace(props.name, config);
		this.editor.___fileArchiveImages = (Array.isArray(props.fileArchiveImages) && props.fileArchiveImages.length > 0
			? props.fileArchiveImages
			: []);

		if (props.onChange) {
			this.editor.on('change', function(event) {
				props.onChange(event);
			}.bind(this));
		}
		this.editor.on('instanceReady', function() {
			setTimeout(function() {
				this.editor.setData(props.data);
			}.bind(this), 0);
		}.bind(this));
	}
});
export default LibraryCkeditor;
