import React from 'react';
import createReactClass from 'create-react-class';
import ImageButton from './ImageButton';
import DropDown from './DropDown';
import ErrandHelper from './ErrandHelper';

export default createReactClass({
	displayName: "ErrandActionTools",
	getInitialState: function(){
		return{
			eeActivateClass : 'dd-icon',
			eeLightOnStatus : false,
			forwardToExternalFeature: false,
			turnOffForwardToAreaFromInboxFeature: false,
			agentOnlyForwardToAreaInOrganisationFeature: false,
			externalExpertsFeature: false,
			notesErrandFeature: false,
			openExternalSystemFeature: false,
			activeUserIdFromFeature: false,
			highPriorityFeature: false,
			agentCanPutBackErrands: false,
			agentDeleteErrandsFeature: false,
			closeErrandWithoutAnswerFeature: false,
			foldersMoveFromNewErrandsFeature: false,
			highPriority: false
		}
	},
	handleEESwitch: function(status){
		this.state.eeActivateClass = 'dd-icon '+status;
		this.setState(this.state);
	},
	componentWillMount: function(){
		this.state.forwardToExternalFeature = F("forward-external.any");
		this.state.turnOffForwardToAreaFromInboxFeature = F("agent.turn-off-forward-to-area-from-inbox");
		this.state.agentOnlyForwardToAreaInOrganisationFeature = F("agent.only-forward-to-area-in-organisation");
		this.state.externalExpertsFeature = F("external-experts");
		this.state.notesErrandFeature = F("notes.errand");
		this.state.openExternalSystemFeature = F('open-external-system');
		this.state.activeUserIdFromFeature = F('activeUserId');
		this.state.highPriorityFeature = F('highPriority');
		this.state.agentCanPutBackErrands = ErrandHelper.getAgentCanPutBackErrands();
		this.state.agentDeleteErrandsFeature = F('agent-delete-errands.admin-edit');
		this.state.closeErrandWithoutAnswerFeature = F('close-errand-without-answering');
		this.state.foldersMoveFromNewErrandsFeature = F('folders.move-from-new-errands');
	},
	componentDidMount: function(){
		if(this.props.hasEELightOn == "activated"){
			this.setState({
				eeActivateClass : 'dd-icon activated',
				eeLightOnStatus : true,
				highPriority: this.props.errandPriority
			});
		}else if(this.props.hasEELightOn == "active"){
			this.setState({
				eeActivateClass : 'dd-icon active',
				eeLightOnStatus : false,
				highPriority: this.props.errandPriority
			});
		}else if(this.props.hasEELightOn == "deactivate"){
			this.setState({
				eeActivateClass : 'dd-icon',
				eeLightOnStatus : false,
				highPriority: this.props.errandPriority
			});
		}
	},
	onImportantHandle: function(){
		this.state.highPriority = !this.state.highPriority;
		this.setState(this.state);
		this.props.onImportant(this.state.highPriority);
	},
	onActivateForwardToExternal: function(){
		this.state.forwardToExternalActive = !this.state.forwardToExternalActive;
		this.setState(this.state);
		this.props.onForwardToExternal(this.state.forwardToExternalActive);
	},
	isInAnswerPage: function(){
		return this.props.errandSrc == "Errand";
	},
	isErrandOpen: function(){
		return !this.props.isClosed;
	},
	isErrandAcquired: function(){
		return this.props.isAcquired;
	},
	renderActionToolbar: function(){
		var errandActionTools = [];
		var externalAddressesList = this.props.externalAddresses;
		var externalAddressesExist = false;
		var fieldsForArea = {id:"Id",name:"Name"};
		var fields = {id:"id",name:"value"};
		var divClass = "btn-group";
		var ddClass = "";

		if(this.props.forwardExternalActived) {
			divClass += " unclickable";
			ddClass += " unclickable";
		}
		if(externalAddressesList != undefined && externalAddressesList.length > 0){
			externalAddressesExist = true;
		}else{
			externalAddressesExist = false;
		}
		var needDivider = false;
		if(this.props.errandSrc == ErrandHelper.ERRAND_SRC_CONTEXT_NEW || this.props.errandSrc == ErrandHelper.ERRAND_SRC_CONTEXT_MY){
			var errandRefreshIcon = "dd-icon";
			errandActionTools.push(<div key="ErrAct_Refresh" id="ErrAct_Refresh" className={divClass} data-toggle="tooltip" title={I("Refresh")}><div type="button" id="iconRefresh" className={"errand-sprite "+errandRefreshIcon} onClick={this.props.onRefresh}></div><div id="refreshMessage"><span id="RefreshMessage" style={{display: 'none'}}>{I("Updating Errand List...")}</span></div></div>);
			needDivider = true;
		}
		if(this.isInAnswerPage() && this.isErrandOpen()){
			var priorityActive = "dd-icon";
			if(this.state.highPriority) priorityActive = "dd-icon active";
			if(this.state.highPriorityFeature) {
				errandActionTools.push(<div key="ErrAct_Important" id="ErrAct_Important" className={divClass} data-toggle="tooltip" title={I("High Priority")}><div type="button" className={"errand-sprite "+priorityActive} onClick={this.onImportantHandle}></div></div>);
				needDivider = true;
			}
		}
		if(needDivider){
			errandActionTools.push(<div key="divider1" className={divClass+" divider"}></div>);
			needDivider = false;
		}

		if((!this.isInAnswerPage() && this.props.hasErrandsSelected)  || (this.isInAnswerPage() && this.isErrandOpen() && this.isErrandAcquired())){
			if(this.isInAnswerPage() || this.props.errandSrc == ErrandHelper.ERRAND_SRC_CONTEXT_MY) {
				if(ErrandHelper.isCustomFolder(this.props.folders)) {
					errandActionTools.push(<DropDown class={ddClass} key="ErrAct_MoveToFolder" id="ErrAct_MoveToFolder" tooltip={I("Move to folder")} type="icon" showInlineTitle={I("Move to folder")} selectedItems={""} items={this.props.folders} fields={fields} onChange={this.props.onMoveToFolder}/>);
				}
			} else {
				if(F('folders.move-from-new-errands'))
					errandActionTools.push(<DropDown key="folders.move-from-new-errands" class={ddClass} id="ErrAct_MoveToFolder" tooltip={I("Move to folder")} type="icon" showInlineTitle={I("Move to folder")} selectedItems={""} items={this.props.folders} fields={fields} onChange={this.props.onMoveToFolder}/>);
			}
			if( !this.isInAnswerPage() && this.state.turnOffForwardToAreaFromInboxFeature == true ) {
				errandActionTools.push(<DropDown class={ddClass} key="ErrAct_ForwardToArea" id="ErrAct_ForwardToArea" tooltip={I("Forward to area")} type="icon" showInlineTitle={I("Forward to area")} selectedItems={""} items={this.props.areas} fields={fieldsForArea} nestedGroup="Areas" onChange={this.props.onMoveToArea}/>);
			}else{
				if(this.isInAnswerPage() && this.state.agentOnlyForwardToAreaInOrganisationFeature != false) {
					errandActionTools.push(<DropDown class={ddClass} key="ErrAct_ForwardToArea" id="ErrAct_ForwardToArea" tooltip={I("Forward to area")} type="icon" showInlineTitle={I("Forward to area")} selectedItems={""} items={this.props.areas} fields={fieldsForArea} onChange={this.props.onMoveToArea}/>);
				} else {
					errandActionTools.push(<DropDown class={ddClass} key="ErrAct_ForwardToArea" id="ErrAct_ForwardToArea" tooltip={I("Forward to area")} type="icon" showInlineTitle={I("Forward to area")} selectedItems={""} items={this.props.areas} fields={fieldsForArea} nestedGroup="Areas" onChange={this.props.onMoveToArea}/>);
				}
			}
			needDivider = true;
		}
		if(this.isInAnswerPage()){
			if((this.isErrandOpen() && this.isErrandAcquired()) || this.props.errandSrc == ErrandHelper.ERRAND_SRC_CONTEXT_NEW){
				errandActionTools.push(<DropDown class={ddClass} key="ErrAct_ForwardToAgent" id="ErrAct_ForwardToAgent" tooltip={ErrandHelper.getCacheWorkflowSettings() != undefined && ErrandHelper.getCacheWorkflowSettings().agentForwardToAgent == true ? I("Forward To Agent"):I("Forward To Team Leader")} type="icon" showInlineTitle={ErrandHelper.getCacheWorkflowSettings() != undefined && ErrandHelper.getCacheWorkflowSettings().agentForwardToAgent == true ? I("Forward To Agent"):I("Forward To Team Leader")} selectedItems={""} items={this.props.agents} fields={fields} onChange={this.props.onMoveToAgent}/>);
			}
			if((this.state.forwardToExternalFeature || externalAddressesExist) && !this.props.errandIsSecureMessage){
				var forwardExtActive = "dd-icon";
				var pClass = "btn-group";
				if(this.props.forwardExternalActived) {
					forwardExtActive = "dd-icon active";
					pClass += " activeOnOverlay";
				}
				errandActionTools.push(<div key="ErrAct_ForwardToExternal"
					id="ErrAct_ForwardToExternal" data-toggle="tooltip"
					title={I("Forward to External")} className={pClass}>
					<div type="button" className={"errand-sprite "+forwardExtActive}
						onClick={this.props.onForwardToExternal}></div></div>);
			}
			if(this.state.externalExpertsFeature && !this.props.errandIsSecureMessage){
				errandActionTools.push(<div key="ErrAct_ErrandEE" id="ErrAct_ErrandEE" data-toggle="tooltip" title={I("External experts")} className={divClass}><div type="button" className={"errand-sprite "+this.state.eeActivateClass} onClick={this.props.onExternalExpert}></div></div>);
			}
			needDivider = true;
		}
		if(needDivider){
			errandActionTools.push(<div key="divider2" className={divClass+" divider"}></div>);
			needDivider = false;
		}
		if(this.isInAnswerPage()){
			var showDivider = false;
			var errandNotesIcon = "dd-icon";
			if(this.props.hasErrandNotes > 0) errandNotesIcon = "dd-icon active";
			if(this.state.notesErrandFeature) {
				errandActionTools.push(<div key="ErrAct_ErrandNotes" id="ErrAct_ErrandNotes" data-toggle="tooltip" title={I("Errand notes")} className={divClass}><div type="button" className={"errand-sprite "+errandNotesIcon} onClick={this.props.onErrandNotes}></div><span className="badge-notify">{this.props.hasErrandNotes}</span></div>);
				showDivider = true;
			}
			if(this.state.openExternalSystemFeature){
				var externalSystem = this.props.externalSystemSettings;
				if( externalSystem.hasExternalSystem && (externalSystem.howOpenExSysByAgent == 0 || externalSystem.howOpenExSysByAgent == 1) ){
					errandActionTools.push(<div key="ErrAct_ErrandOpenExternal" id="ErrAct_ErrandOpenExternal" data-toggle="tooltip" title={I("Open External System")} className={divClass}><div type="button" className="errand-sprite dd-icon" onClick={this.props.onOpenExternal}></div></div>);
					showDivider = true;
				}
			}
			if(showDivider) errandActionTools.push(<div key="divider3" className={divClass+" divider"}></div>);
		}
		if(this.props.hasErrandsSelected){
			if( !this.isInAnswerPage() ){
				errandActionTools.push(<div key="ErrAct_Print" id="ErrAct_Print" data-toggle="tooltip" title={I("Print Preview")} className={divClass}><div type="button" className="errand-sprite dd-icon" onClick={this.props.onPrint.bind(null, 'single')}></div></div>);
			}else{
				errandActionTools.push(
					<div key="ErrAct_Print" id="ErrAct_Print" data-toggle="tooltip" title={I("Print Preview")} className={divClass}>
						<div type="button" className="btn btn-secondary dropdown-toggle errand-sprite dd-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
						<ul className="dropdown-menu">
							<li className="print" onClick={this.props.onPrint.bind(null, 'single')}><a>{I('Print this errand')}</a></li>
							<li className="print-all" onClick={this.props.onPrint.bind(null, 'complete')}><a>{I('Print complete history')}</a></li>
						</ul>
					</div>
				);
			}

			if(((this.isInAnswerPage() && this.isErrandOpen()) && this.isErrandAcquired()) || !this.isInAnswerPage()){
				if(F('agent-delete-errands.admin-edit'))
					errandActionTools.push(<div id="ErrAct_Bin" key="agent-delete-errands.admin-edit" data-toggle="tooltip" title={I("Delete")} className={divClass}><div type="button" className="errand-sprite dd-icon" onClick={this.props.onDelete}></div></div>);
				if(F('close-errand-without-answering'))
					errandActionTools.push(<div id="ErrAct_ErrandClose" key="close-errand-without-answering" data-toggle="tooltip" title={I("Close Errand")} className={divClass}><div type="button" className="errand-sprite dd-icon" onClick={this.props.onClose}></div></div>);
			}
			if((this.isInAnswerPage()) &&
				(features.solidusEnabled == true) &&
				this.props.openedByExternal == false &&
				this.props.isClosed == false ){
				errandActionTools.push(<div key="ErrAct_QueueToMe" id="ErrAct_QueueToMe" data-toggle="tooltip" title={I("Queue to me")} className={divClass}><div type="button" className="errand-sprite dd-icon" onClick={this.props.onQueueToMe}></div></div>);
			}
		}

		return errandActionTools;
	},
	renderBackToolbar: function(){
		var errandBackTools = [];
		var disabledPutback = false;
		var backInboxClass = "errandBackToInbox";
		var backSearchClass = "errandBackToSearch";
		if( !this.props.isErrandAcquired )
			if( this.props.errandSrcContext == ErrandHelper.ERRAND_SRC_CONTEXT_NEW && !this.props.onlyShowErrandIfAcquired )
				disabledPutback = true;
		if(this.props.forwardExternalActived) {
			backInboxClass += " unclickable";
			backSearchClass += " unclickable";
		}
		if(this.props.agentCanPutBackErrands){
			if(this.isInAnswerPage()){
				if( this.props.errandSrcContext != ErrandHelper.ERRAND_SRC_CONTEXT_SEARCH ){
					if( !disabledPutback )
						errandBackTools.push(<p key="putback" className={backInboxClass} data-toggle="tooltip" title={I("Put back to Inbox")} onClick={this.props.onReturnToInbox}>{I('PUT BACK TO INBOX')}</p>);
				}
				else{
					if( this.props.errandOwner != undefined && this.props.errandOwner != 0) {
						if(this.props.errandOwner == this.state.activeUserIdFromFeature && this.props.isClosed != true) {
							errandBackTools.push(<p key="putback" className={backInboxClass} data-toggle="tooltip" title={I("Put back to Inbox")} onClick={this.props.onReturnToInbox}>{I('PUT BACK TO INBOX')}</p>);
						}
						errandBackTools.push(<p key="backtosearch" className={backSearchClass} data-toggle="tooltip" title={I("Back to Search")} onClick={this.props.onReturnToSearch}>{I('BACK TO SEARCH')}</p>);
					} else {
						if( !disabledPutback )
							errandBackTools.push(<p key="putback" className={backInboxClass} data-toggle="tooltip" title={I("Put back to Inbox")} onClick={this.props.onReturnToInbox}>{I('PUT BACK TO INBOX')}</p>);
						errandBackTools.push(<p key="backtosearch" className={backSearchClass} data-toggle="tooltip" title={I("Back to Search")} onClick={this.props.onReturnToSearch}>{I('BACK TO SEARCH')}</p>);
					}
				}
			}
			if(this.props.errandSrc == ErrandHelper.ERRAND_SRC_CONTEXT_MY && this.props.hasErrandsSelected){
				errandBackTools.push(<p key="putback" className={backInboxClass} data-toggle="tooltip" title={I("Put back to Inbox")} onClick={this.props.onReturnToInbox}>{I('PUT BACK TO INBOX')}</p>);
			}
		}else{
			if(this.isInAnswerPage()){
				if( this.props.errandSrcContext == ErrandHelper.ERRAND_SRC_CONTEXT_SEARCH ){
					if( this.props.errandOwner != undefined && this.props.errandOwner != 0){
						errandBackTools.push(<p key="backtosearch" className={backSearchClass} data-toggle="tooltip" title={I("Back to Search")} onClick={this.props.onReturnToSearch}>{I('BACK TO SEARCH')}</p>);
					} else {
						errandBackTools.push(<p key="putback" className={backInboxClass} data-toggle="tooltip" title={I("Put back to Inbox")} onClick={this.props.onReturnToInbox}>{I('PUT BACK TO INBOX')}</p>);
						errandBackTools.push(<p key="backtosearch" className={backSearchClass} data-toggle="tooltip" title={I("Back to Search")} onClick={this.props.onReturnToSearch}>{I('BACK TO SEARCH')}</p>);
					}
				}
			}
		}

		return errandBackTools;
	},
	render: function(){
		if( this.props.errandSrcContext == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL )
			return(<span></span>);
		return (
			<div className="reactHolder" style={{'width':'100%'}}>
				<div className="align-left errand-options">
					<div className="align-left filter-options">
						{this.renderActionToolbar()}
						{this.renderBackToolbar()}
					</div>
				</div>
				<div className="clear"></div>
			</div>
		);
	}
});
